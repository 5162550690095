import * as Types from '../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ExportRetailerDeliveryDestinationsQueryVariables = Types.Exact<{
  sort?: Types.InputMaybe<Types.RddSort>;
  filter?: Types.InputMaybe<Types.RddFilters>;
  searchLike?: Types.InputMaybe<Types.Scalars['String']['input']>;
  advancedFilter?: Types.InputMaybe<Types.RddAdvancedFilters>;
}>;


export type ExportRetailerDeliveryDestinationsQuery = { __typename?: 'RootQueryType', exportRetailerDeliveryDestinations?: string | null };


export const ExportRetailerDeliveryDestinationsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"exportRetailerDeliveryDestinations"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"sort"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"RddSort"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"filter"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"RddFilters"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"searchLike"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"advancedFilter"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"RddAdvancedFilters"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"exportRetailerDeliveryDestinations"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"sort"},"value":{"kind":"Variable","name":{"kind":"Name","value":"sort"}}},{"kind":"Argument","name":{"kind":"Name","value":"filter"},"value":{"kind":"Variable","name":{"kind":"Name","value":"filter"}}},{"kind":"Argument","name":{"kind":"Name","value":"searchLike"},"value":{"kind":"Variable","name":{"kind":"Name","value":"searchLike"}}},{"kind":"Argument","name":{"kind":"Name","value":"advancedFilter"},"value":{"kind":"Variable","name":{"kind":"Name","value":"advancedFilter"}}},{"kind":"Argument","name":{"kind":"Name","value":"ignoreAllowedTradingPartners"},"value":{"kind":"BooleanValue","value":true}},{"kind":"Argument","name":{"kind":"Name","value":"type"},"value":{"kind":"EnumValue","value":"CSV"}}]}]}}]} as unknown as DocumentNode<ExportRetailerDeliveryDestinationsQuery, ExportRetailerDeliveryDestinationsQueryVariables>;