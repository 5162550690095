export const formatStatus = (str: string): string => {
  const words = str.split('_');
  return words
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(' ');
};

const MATERIAL_ID_LENGTH = 18;
const ORDER_NUMBER_LENGTH = 10;

export const validateWOPSearchTerms = (terms: string[]): string | null => {
  if (terms.length === 0) return null;

  const firstTermLength = terms[0].length;
  for (const term of terms) {
    if (term.length !== MATERIAL_ID_LENGTH && term.length !== ORDER_NUMBER_LENGTH) {
      return 'One or more search terms do not match the expected length, the search is not applied';
    }
    if (term.length !== firstTermLength) {
      return 'All search terms must be either Material IDs or Order Numbers, the search is not applied';
    }
  }
  return null;
};
