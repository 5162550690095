import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import s from './AssortmentPage.module.scss';
import { SearchOutlined } from '@ant-design/icons';
import { uniqBy, sortBy, remove } from 'lodash-es';
import { RETAILER_CHANNEL_COM, ModalAction, DEFAULT_PAGE_SIZE } from 'common/constants';
import { UserContext } from 'context/userContext';
import {
  AssortmentConfigFieldSlug,
  SortOrderDirection,
  TradingPartnerActiveAssortmentSortColumn,
  UpsertTradingPartnerActiveAssortmentInput
} from 'graphql/__generated__/types';
import LoaderSpinner from 'components/LoaderSpinner';
import { ExportActiveAssortmentByConfig } from './components/ExportActiveAssortment/ExportActiveAssortmentByConfig';
import { BulkAddActiveAssortmentWithConfigModal } from './components/BulkAddActiveAssortment/BulkAddActiveAssortmentWithConfigModal';
import ActiveAssortmentTable from './components/ActiveAssortmentTable/ActiveAssortmentTable';
import { EditTradingPartnerAssortmentByConfig } from './components/EditTradingPartnerAssortment/EditTradingPartnerAssortment';
import { ReserveBulkUpdateModal } from 'pages/AssortmentPage/components/ReserveBulkUpdateModal/ReserveBulkUpdateModal';
import {
  ProductDetailsDrawer,
  TradingPartnerAssortmentFullData
} from './components/ProductDetailsModal/ProductDetailsDrawer';
import { getNodesFromEdges, InferNodeType } from 'common/helpers/mappingHelper';
import {
  TradingPartnersForActiveAssortmentPageDocument,
  TradingPartnersForActiveAssortmentPageQuery
} from './gql/__generated__/tradingPartnersForActiveAssortmentPage.query';
import { ChangeHistory } from 'components/ChangeHistory/ChangeHistory';
import { UpsertTradingPartnerActiveAssortmentDocument } from './gql/__generated__/upsertTradingPartnerActiveAssortment.mutation';
import { LazyLoadingRddsDocument } from './gql/__generated__/lazyLoadingRdds.query';
import { UpdateTradingPartnerActiveAssortmentStatusDocument } from './gql/__generated__/updateTradingPartnerActiveAssortmentStatus.mutation';
import { ConfigNotfication } from './components/ConfigNotfication/ConfigNotfication';
import { Paginator } from 'components/Paginator/Paginator';
import {
  ArrayParam,
  BooleanParam,
  NumberParam,
  StringParam,
  useQueryParam,
  withDefault
} from 'use-query-params';
import {
  TradingPartnerAssortmentsDocument,
  TradingPartnerAssortmentsQuery
} from './gql/__generated__/tradingPartnerAssortments.query';
import {
  TradingPartnerWithAssortmentConfigDocument,
  TradingPartnerWithAssortmentConfigQuery
} from './gql/__generated__/tradingPartnerWithAssortmentConfig.query';
import { AlloySelect } from 'components/ui/AlloySelect/AlloySelect';
import { PageHeader } from 'components/ui/PageHeader/PageHeader';
import { App } from 'ant5';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { AlloySegmented } from 'components/ui/AlloySegmented/AlloySegmented';
import { AlloyDropdown } from 'components/ui/AlloyDropdown/AlloyDropdown';
import { QuantityDisplay } from 'components/ui/QuantityDisplay/QuantityDisplay';
import { DownOutlined } from '@ant-design/icons';
import { MultipleValuesInput } from 'components/MultipleValuesInput/MultipleValuesInput';
import { notEmpty } from 'common/helpers/notEmpty';
import { safeLocaleCompare } from 'common/helpers/comparators';
import { useFeatureFlag } from 'common/useFeatureFlags/useFeatureFlags';
import { CATALOG_PRODUCT_ASSORTMENT } from 'common/useFeatureFlags/featureFlags';
import ErrorState from '@/src/components/ErrorState/ErrorState';
import { ExtendedAssortmentConfigFieldSlug } from './fieldsHelper';
import { EditAssortmentModal } from './components/EditAssortmentModal/EditAssortmentModal';

export const getVendorMarketExternalId = (
  vendorMarketId: string | null | undefined,
  availableRetailerChannelExternalIds?: string[]
) =>
  vendorMarketId ||
  (availableRetailerChannelExternalIds &&
  !availableRetailerChannelExternalIds.includes(RETAILER_CHANNEL_COM)
    ? availableRetailerChannelExternalIds[0]
    : RETAILER_CHANNEL_COM);

const getTradingPartnerId = (
  retailersList: ActiveAssortmentRetailerChannelsListProps[],
  tradingPartnerIds: string | null | undefined,
  vendorMarketId: string | null | undefined,
  availableRetailerChannelExternalIds?: string[]
) =>
  tradingPartnerIds ||
  getVendorMarket(retailersList, vendorMarketId, undefined, availableRetailerChannelExternalIds)
    ?.tradingPartners[0].id ||
  null;

const getVendorMarket = (
  retailersList: ActiveAssortmentRetailerChannelsListProps[],
  vendorMarketId: string | null | undefined,
  filteredId?: string,
  availableRetailerChannelExternalIds?: string[]
) =>
  retailersList.find(
    ({ externalId }) =>
      externalId ===
      (filteredId
        ? filteredId
        : getVendorMarketExternalId(vendorMarketId, availableRetailerChannelExternalIds))
  );

const getTradingPartner = (tradingPartners: TradingPartner[], tradingPartnerId: string) => {
  const tp = (tradingPartners || []).find((tp) => tp.id === tradingPartnerId);
  return {
    id: tp?.id || '',
    name: tp?.name || '',
    deliveryType: tp?.deliveryType || 'WHD',
    retailerDeliveryDestinations:
      tp?.retailerDeliveryDestinations?.filter(
        (rdd): rdd is RetailerDeliveryDestination => rdd !== null
      ) || [],
    externalId: tp?.externalId
  };
};

interface ActiveAssortmentItemsList {
  itemsList: TradingPartnerAssortment[];
  pageInfo: PageInfo | undefined;
  totalCount: number;
}

interface ActiveAssortmentRetailerChannelsListProps extends RetailerChannel {
  tradingPartners: TradingPartner[];
  deliveryDestinations: RetailerDeliveryDestination[];
  distributionCenters: DistributionCenter[];
}

export interface SubstitutionDropdownConfig {
  [key: string]: number;
}

export type AssortmentConfig = NonNullable<
  NonNullable<TradingPartnerWithAssortmentConfigQuery['tradingPartner']>['assortmentConfig']
>;
export type FormField = NonNullable<AssortmentConfig['fields'][number]>;
export type ExtractedFormField = Omit<FormField, 'slug'> & {
  slug: ExtendedAssortmentConfigFieldSlug;
};
export type TradingPartnerAssortment = InferNodeType<
  TradingPartnerAssortmentsQuery,
  'tradingPartnerAssortments'
>;

export type SubstitutionProduct = TradingPartnerAssortment['substitutions'][number];
type PageInfo = NonNullable<
  NonNullable<TradingPartnerAssortmentsQuery['tradingPartnerAssortments']>['pageInfo']
>;
export type TradingPartner = InferNodeType<
  TradingPartnersForActiveAssortmentPageQuery,
  'tradingPartners'
>;
export type RetailerChannel = NonNullable<TradingPartner['vendorMarket']>;
export type RetailerDeliveryDestination = NonNullable<
  NonNullable<NonNullable<TradingPartner['retailerDeliveryDestinations']>[number]>
>;
export type DistributionCenter = NonNullable<
  NonNullable<TradingPartner['distributionCenters']>[number]
>;

export interface DistributionCenterForAA {
  __typename?: 'DistributionCenter' | undefined;
  id: string;
  code: string;
  name: string;
}

export const ActiveAssortmentPage = () => {
  const { message } = App.useApp();

  const [showReserveBulkUpdateModal, setShowReserveBulkUpdateModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState<TradingPartnerAssortment[]>([]);
  const [editedItem, setEditedItem] = useState<TradingPartnerAssortmentFullData>();
  const [showBulkAddAssortmentModal, setShowBulkAddAssortmentModal] = useState(false);
  const [substitutionProductView, setSubstitutionProductView] =
    useState<SubstitutionDropdownConfig>({});
  const [viewChangeHistoryAssortment, setViewChangeHistoryAssortment] = useState<
    TradingPartnerAssortment | undefined
  >();

  const { isAdmin } = useContext(UserContext);

  const {
    error,
    data,
    called,
    loading: loadingInitData
  } = useQuery(TradingPartnersForActiveAssortmentPageDocument, {
    variables: { first: 1000000 }
  });

  const retailersList = useMemo<ActiveAssortmentRetailerChannelsListProps[]>(() => {
    if (!data) return [];
    const tradingPartnersList = getNodesFromEdges(data.tradingPartners);
    return uniqBy(
      getNodesFromEdges(data.tradingPartners).reduce(
        (result, tp) => (tp.vendorMarket ? [...result, tp.vendorMarket] : result),
        [] as RetailerChannel[]
      ),
      'id'
    ).map((vendorMarketItem) => {
      const vendorMarketTradingPartnersList = tradingPartnersList.filter(
        ({ vendorMarket }) => vendorMarket?.externalId === vendorMarketItem.externalId
      );

      const deliveryDestinations = uniqBy(
        vendorMarketTradingPartnersList.reduce((result, tradingPartner) => {
          tradingPartner.retailerDeliveryDestinations?.forEach((rdd) => {
            if (rdd) {
              result.push(rdd);
            }
          });
          return result;
        }, [] as RetailerDeliveryDestination[]),
        'id'
      );

      const distributionCenters = uniqBy(
        vendorMarketTradingPartnersList.reduce((result, tradingPartner) => {
          tradingPartner.distributionCenters?.forEach((dc) => {
            if (dc) {
              result.push(dc);
            }
          });
          return result;
        }, [] as DistributionCenter[]),
        'id'
      );

      return {
        ...vendorMarketItem,
        tradingPartners: vendorMarketTradingPartnersList,
        deliveryDestinations,
        distributionCenters
      };
    });
  }, [data]);

  const [updateActiveAssortment, { loading: updatingActiveAssortment }] = useMutation(
    UpdateTradingPartnerActiveAssortmentStatusDocument,
    {
      refetchQueries: [TradingPartnerAssortmentsDocument]
    }
  );

  const [tpId] = useQueryParam<string>('tradingPartnerIds', withDefault(StringParam, ''));
  const [sortColumnKey, setSortColumnKey] = useQueryParam<string>(
    'columnKey',
    withDefault(StringParam, 'UPDATED_AT')
  );
  const [sortOrder, setSortOrder] = useQueryParam<string>(
    'order',
    withDefault(StringParam, 'DESC')
  );
  const [pageSize, setPageSize] = useQueryParam(
    'limit',
    withDefault(NumberParam, DEFAULT_PAGE_SIZE)
  );
  const [after, setAfter] = useQueryParam('after', StringParam);
  const [before, setBefore] = useQueryParam('before', StringParam);
  const [productId, setProductId] = useQueryParam('product', StringParam);
  const [retailerDeliveryDestinationIds, setRetailerDeliveryDestinationIds] = useQueryParam(
    'retailerDeliveryDestinationIds',
    ArrayParam
  );
  const [active, setActive] = useQueryParam('active', BooleanParam);
  const [vendorMarketId, setVendorMarketId] = useQueryParam('vendorMarketId', StringParam);
  const [tradingPartnerIds, setTradingPartnerIds] = useQueryParam('tradingPartnerIds', StringParam);
  const [searchTerms, setSearchTerms] = useQueryParam('search', withDefault(ArrayParam, []));
  const nonEmptySearch = searchTerms.filter(notEmpty) as string[];

  const retailerDeliveryDestinations = useMemo(
    () =>
      getVendorMarket(retailersList, vendorMarketId)?.deliveryDestinations.sort(
        (rdd1: RetailerDeliveryDestination, rdd2: RetailerDeliveryDestination) =>
          safeLocaleCompare(rdd1.name, rdd2.name)
      ) || [],
    [retailersList, vendorMarketId]
  );

  useEffect(() => {
    if (productId) {
      setShowBulkAddAssortmentModal(false);
      setShowReserveBulkUpdateModal(false);
      setViewChangeHistoryAssortment(undefined);
    }
  }, [productId]);

  const [upsertTradingPartnerActiveAssortment, { loading: upsert }] = useMutation(
    UpsertTradingPartnerActiveAssortmentDocument,
    {
      onError: ({ graphQLErrors, networkError }) => {
        graphQLErrors?.map((errorMsg) => message.error(`[GraphQL Error] ${errorMsg.message}`));
        if (networkError) message.error(`[Network Error] ${networkError.message}`);
      },
      refetchQueries: editedItem?.retailerProductId
        ? [
            {
              query: LazyLoadingRddsDocument,
              variables: {
                retailerProductIds: [editedItem?.retailerProductId],
                active: true,
                tradingPartnerId: tpId,
                retailerDeliveryDestinationIds: !retailerDeliveryDestinationIds?.includes('all')
                  ? retailerDeliveryDestinationIds
                  : null
              }
            },
            TradingPartnerAssortmentsDocument
          ]
        : [TradingPartnerAssortmentsDocument],
      onCompleted: () => {
        message.success(
          <span data-testid="message_active_assortment_item_saved">
            Active Assortment item saved
          </span>
        );
      }
    }
  );

  const getVendorMarketId = () =>
    retailersList.find((item) => item.externalId === getVendorMarketExternalId(vendorMarketId))
      ?.id || 'VmVuZG9yTWFya2V0OjI=';

  const toggleBulkAddAssortmentModalVisibility = () =>
    setShowBulkAddAssortmentModal(!showBulkAddAssortmentModal);

  const upsertTradingPartnerAssortment = async (
    action: ModalAction,
    params: UpsertTradingPartnerActiveAssortmentInput
  ) => {
    await upsertTradingPartnerActiveAssortment({
      variables: {
        input: params
      }
    });
  };

  const handleActiveAssortmentItemActivation = async (active: boolean) => {
    try {
      await updateActiveAssortment({
        variables: {
          input: {
            active: active,
            tradingPartnerIds: [tpId],
            vendorMarketId:
              retailersList.find(
                (item) => item.externalId === getVendorMarketExternalId(vendorMarketId)
              )?.id || '',
            vendorProductIds: selectedRows.map((row) => row.retailerProductId),
            retailerDeliveryDestinationIds: !retailerDeliveryDestinationIds?.includes('all')
              ? retailerDeliveryDestinationIds?.filter((rddId): rddId is string => !!rddId)
              : null
          }
        }
      });
    } catch (error) {
      console.error(error.message);
      message.error(`Something went wrong. Please try again. ${error.message}`);
    } finally {
      message.success(active ? 'Items activated' : 'Items deactivated');
      setSelectedRows([]);
    }
  };

  const { loading: loadingTpConfig, data: dataTpConfig } = useQuery(
    TradingPartnerWithAssortmentConfigDocument,
    {
      skip: !tradingPartnerIds,
      variables: {
        tradingPartnerId: tradingPartnerIds ?? ''
      }
    }
  );

  const initParametersNotProvided = useMemo(
    () =>
      !retailersList ||
      retailersList.length === 0 ||
      !vendorMarketId ||
      !pageSize ||
      !sortColumnKey ||
      !sortOrder ||
      active === undefined ||
      active === null ||
      !tradingPartnerIds ||
      !retailerDeliveryDestinationIds ||
      retailerDeliveryDestinationIds.length === 0,
    [
      active,
      pageSize,
      retailerDeliveryDestinationIds,
      retailersList,
      sortColumnKey,
      sortOrder,
      tradingPartnerIds,
      vendorMarketId
    ]
  );

  const { loading: loadingAssortment, data: dataAssortment } = useQuery(
    TradingPartnerAssortmentsDocument,
    {
      skip: initParametersNotProvided,
      variables: {
        first: after || !before ? pageSize : null,
        last: !after && before ? pageSize : null,
        after: after ? (typeof after === 'string' ? after : after[0]) : null,
        before: before && !after ? (typeof before === 'string' ? before : before[0]) : null,
        sort: {
          column: sortColumnKey as TradingPartnerActiveAssortmentSortColumn,
          direction: sortOrder as SortOrderDirection
        },
        filters: {
          active: active === undefined || active === null ? true : active,
          retailerDeliveryDestinationIds: !retailerDeliveryDestinationIds?.includes('all')
            ? retailerDeliveryDestinationIds?.filter((rddId): rddId is string => !!rddId)
            : null,
          vendorMarketId:
            retailersList.find(
              (item) => item.externalId === getVendorMarketExternalId(vendorMarketId)
            )?.id || '',
          searchTerms: nonEmptySearch
        },
        tradingPartnerId: tradingPartnerIds || ''
      }
    }
  );

  const activeAssortmentItems = useMemo<ActiveAssortmentItemsList>(
    () => ({
      itemsList: getNodesFromEdges(dataAssortment?.tradingPartnerAssortments),
      pageInfo: dataAssortment?.tradingPartnerAssortments?.pageInfo,
      totalCount: dataAssortment?.tradingPartnerAssortments?.totalCount || 0
    }),
    [dataAssortment]
  );

  const assortmentConfig = useMemo<AssortmentConfig | null | undefined>(
    () => dataTpConfig?.tradingPartner?.assortmentConfig,
    [dataTpConfig?.tradingPartner?.assortmentConfig]
  );

  const updating = useMemo(
    () => loadingAssortment || upsert || updatingActiveAssortment || loadingTpConfig,
    [loadingAssortment, upsert, updatingActiveAssortment, loadingTpConfig]
  );

  useEffect(() => {
    if (retailersList && retailersList.length > 0 && initParametersNotProvided) {
      setPageSize(DEFAULT_PAGE_SIZE);
      setSortColumnKey('UPDATED_AT', 'replaceIn');
      setSortOrder('DESC', 'replaceIn');
      setActive(true, 'replaceIn');
      setVendorMarketId(
        getVendorMarketExternalId(
          vendorMarketId,
          retailersList.map((retailer) => retailer.externalId)
        ),
        'replaceIn'
      );
      setTradingPartnerIds(
        getTradingPartnerId(retailersList, tradingPartnerIds, vendorMarketId),
        'replaceIn'
      );
      setRetailerDeliveryDestinationIds(retailerDeliveryDestinationIds ?? ['all'], 'replaceIn');
    }
  }, [
    active,
    initParametersNotProvided,
    pageSize,
    retailerDeliveryDestinationIds,
    retailersList,
    setActive,
    setPageSize,
    setRetailerDeliveryDestinationIds,
    setSortColumnKey,
    setSortOrder,
    setTradingPartnerIds,
    setVendorMarketId,
    sortColumnKey,
    sortOrder,
    tradingPartnerIds,
    vendorMarketId
  ]);

  //FEATURE FLAG FOR CATALOG PRODUCT NEW ASSORTMENT
  const { enabled: isNewAssortmentEnabled } = useFeatureFlag({
    name: CATALOG_PRODUCT_ASSORTMENT,
    tradingPartnerExternalId: getTradingPartner(
      getVendorMarket(retailersList, vendorMarketId)?.tradingPartners || [],
      getTradingPartnerId(retailersList, tradingPartnerIds, vendorMarketId) || ''
    ).externalId
  });

  //function to reset pagination after active/inactive toggle - so user can start their view on first page of list
  const resetPagination = () => {
    setAfter(undefined);
    setBefore(undefined);
  };

  const tradingPartner = useMemo(
    () =>
      getVendorMarket(retailersList, vendorMarketId)?.tradingPartners?.find(
        (tradingPartner) => tradingPartner.id === tradingPartnerIds
      ),
    [retailersList, tradingPartnerIds, vendorMarketId]
  );

  const isCalledUnauthorizedPage = useMemo(
    () => called && !tradingPartner && !initParametersNotProvided && !loadingInitData,
    [called, initParametersNotProvided, loadingInitData, tradingPartner]
  );

  const isShowConfigNotification = useMemo(
    () => !loadingTpConfig && dataTpConfig && !assortmentConfig,
    [assortmentConfig, dataTpConfig, loadingTpConfig]
  );

  if (loadingInitData) return <LoaderSpinner />;
  if (error) console.log('error', error);

  if (isCalledUnauthorizedPage) {
    return (
      <ErrorState
        type="unauthorized"
        title="401 - Unauthorized Access"
        explanation="Access to the requested resource is denied because valid authentication credentials were not provided."
        instruction={
          'To get or confirm your access:\n' +
          '1. Send a slack message to #schip-all-pods on slack\n' +
          '2. Include which Trading Partner(s) you need access to (eg. PEPSV, FRJJX, HEAXT)'
        }
      />
    );
  }

  const handleActiveAssortmentFilter = (filterName: string, filterValue: string | string[]) => {
    setSelectedRows([]);
    switch (filterName) {
      case 'vendorMarketId':
        setVendorMarketId(filterValue as string);
        setTradingPartnerIds(
          getVendorMarket(retailersList, vendorMarketId, filterValue as string)?.tradingPartners[0]
            .id,
          'replaceIn'
        );
        setRetailerDeliveryDestinationIds(['all'], 'replaceIn');
        setSortColumnKey('UPDATED_AT', 'replaceIn');
        setSortOrder('DESC', 'replaceIn');
        break;
      case 'active':
        setActive(filterValue === 'active');
        setSortColumnKey('UPDATED_AT', 'replaceIn');
        setSortOrder('DESC', 'replaceIn');
        break;
      case 'tradingPartnerIds':
        setTradingPartnerIds(filterValue as string);
        setSortColumnKey('UPDATED_AT', 'replaceIn');
        setSortOrder('DESC', 'replaceIn');
        break;
      case 'retailerDeliveryDestinationIds': {
        let shipToFilterValue = filterValue;
        if (filterValue.length < 1) {
          shipToFilterValue = ['all'];
        } else if (retailerDeliveryDestinationIds?.includes('all')) {
          remove(shipToFilterValue, (value) => value === 'all');
        } else if (filterValue.includes('all')) {
          shipToFilterValue = ['all'];
        }
        setRetailerDeliveryDestinationIds(shipToFilterValue as string[]);
        break;
      }
    }
  };

  const addAssortmentAction = (action: string | undefined) => {
    switch (action) {
      case 'ADD_UNCATALOGUED':
        setEditedItem({} as TradingPartnerAssortmentFullData);
        break;
      case 'BULK_ADD':
        toggleBulkAddAssortmentModalVisibility();
        break;
      default:
        return;
    }
  };

  const handlePageSizeChange = (value: number) => {
    setPageSize(value);
    setAfter(undefined);
    setBefore(undefined);
  };

  const nextPage = async () => {
    setAfter(activeAssortmentItems?.pageInfo?.endCursor);
    setBefore(undefined);
  };

  const prevPage = async () => {
    setAfter(undefined);
    setBefore(activeAssortmentItems?.pageInfo?.startCursor);
  };

  const distributionCenters = (dataTpConfig?.tradingPartner?.distributionCenters || []).filter(
    (dc) => dc !== null
  ) as DistributionCenterForAA[];

  const addMenu = [
    {
      label: 'Add uncatalogued item',
      key: 'ADD_UNCATALOGUED',
      'data-testid': 'add-uncat-item-btn',
      onClick: () => addAssortmentAction('ADD_UNCATALOGUED')
    },
    {
      label: 'Bulk add/update products from CSV',
      key: 'BULK_ADD',
      'data-testid': 'bulk_add-btn',
      onClick: () => addAssortmentAction('BULK_ADD')
    }
  ];

  return (
    <>
      <div className={s.assortment_page_toolbar_row}>
        <div className={s.assortment_filters}>
          <PageHeader className={s.retailer_channel_filter_title}>Active assortment for</PageHeader>
          <AlloySelect
            className={s.retailer_channel_filter}
            data-testid="aa-page-retailer-filter"
            value={getVendorMarketExternalId(vendorMarketId).toString()}
            onChange={(value) => {
              if (value) handleActiveAssortmentFilter('vendorMarketId', value);
            }}
            options={sortBy(retailersList, (vendor: RetailerChannel) =>
              vendor.name.toLowerCase()
            ).map((vendor: RetailerChannel) => ({
              value: vendor.externalId,
              label: vendor.name,
              key: vendor.id
            }))}
            popupMatchSelectWidth={false}
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              safeLocaleCompare(optionA?.label?.toLowerCase(), optionB?.label?.toLowerCase())
            }
            showSearch
          />
          <AlloySelect
            className={s.trading_partner_filter}
            defaultValue={
              getTradingPartnerId(retailersList, tradingPartnerIds, vendorMarketId) ?? undefined
            }
            data-testid="aa-page-tp-filter"
            value={
              getTradingPartnerId(retailersList, tradingPartnerIds, vendorMarketId) ?? undefined
            }
            onChange={(value) => {
              if (value) handleActiveAssortmentFilter('tradingPartnerIds', value);
            }}
            options={
              getVendorMarket(retailersList, vendorMarketId)?.tradingPartners?.map(
                ({ id, name }) => ({
                  label: name || '',
                  value: id
                })
              ) || []
            }
            popupMatchSelectWidth={false}
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              safeLocaleCompare(optionA?.label?.toLowerCase(), optionB?.label?.toLowerCase())
            }
            showSearch
          />
          <AlloySelect
            data-testid="aa-page-ship-filter"
            mode="multiple"
            className={s.ship_to_filter}
            value={
              retailerDeliveryDestinationIds
                ? retailerDeliveryDestinationIds.filter((rddId): rddId is string => !!rddId)
                : ['all']
            }
            onChange={(value) => {
              if (value)
                handleActiveAssortmentFilter(
                  'retailerDeliveryDestinationIds',
                  value.sort((a, b) =>
                    safeLocaleCompare(
                      retailerDeliveryDestinations.find((rdd) => rdd.id === a)?.name,
                      retailerDeliveryDestinations.find((rdd) => rdd.id === b)?.name
                    )
                  )
                );
            }}
            options={[
              {
                label: 'All Ship-To',
                value: 'all'
              },
              ...retailerDeliveryDestinations
                .filter((shipTo) => shipTo.active)
                .map(({ id, name }) => ({
                  label: name || '',
                  value: id
                }))
            ]}
            popupMatchSelectWidth={false}
            maxTagCount="responsive"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            showSearch
            filterSort={(optionA, optionB) => {
              if (optionA.value === 'all') return -1;
              if (optionB.value === 'all') return 1;
              return safeLocaleCompare(
                optionA?.label?.toLowerCase(),
                optionB?.label?.toLowerCase()
              );
            }}
          />
        </div>
        <div className={s.assortment_page_buttons}>
          <AlloyDropdown
            menu={{ items: addMenu }}
            trigger={['click']}
            placement="bottomRight"
            overlayStyle={{ width: '190px' }}
            disabled={loadingAssortment || !assortmentConfig}
          >
            <AlloyButton
              data-testid="drop-add-btn"
              className={s.assortment_page_buttons__add_btn}
              disabled={loadingAssortment || !assortmentConfig}
              type="primary"
            >
              Add to assortment
              <DownOutlined />
            </AlloyButton>
          </AlloyDropdown>
          {tradingPartner && (
            <ExportActiveAssortmentByConfig
              selectedRows={selectedRows}
              retailersList={retailersList}
              assortmentConfig={assortmentConfig}
              tradingPartner={{ ...tradingPartner }}
              disabled={loadingAssortment || !assortmentConfig}
              retailerDeliveryDestinations={retailerDeliveryDestinations}
              distributionCenters={distributionCenters}
            />
          )}
        </div>
      </div>
      {isShowConfigNotification && (
        <ConfigNotfication
          forAdmin={isAdmin()}
          tradingPartnerId={
            getTradingPartnerId(retailersList, tradingPartnerIds, vendorMarketId) || ''
          }
        />
      )}
      {assortmentConfig && getVendorMarket(retailersList, vendorMarketId)?.tradingPartners && (
        <>
          <div className={s.assortment_table_toolbar_row}>
            <AlloySegmented
              data-testid="aa-page-active-inactive-tabs"
              value={active ? 'active' : 'inactive'}
              options={[
                {
                  label: 'Active',
                  value: 'active'
                },
                {
                  label: 'Inactive',
                  value: 'inactive'
                }
              ]}
              onChange={(value) => {
                handleActiveAssortmentFilter(
                  'active',
                  typeof value === 'number' ? String(value) : value
                );
                resetPagination();
              }}
            />
          </div>

          <div className={s.search_and_buttons_container}>
            <div className={s.search}>
              <MultipleValuesInput
                data-testid={'assortment-items-search'}
                placeholder={'Search by ASIN, UPC, SAP Material ID or Name'}
                value={nonEmptySearch}
                onChange={setSearchTerms}
                allowClear={true}
                prefix={<SearchOutlined width="14px" height="14px" />}
                splitInputValue={/[^0-9a-zA-Z-,.()&]+/g}
              />
            </div>

            <div className={s.assortment_page_second_row_buttons}>
              {assortmentConfig?.fields.find(
                (field) => (field.slug as AssortmentConfigFieldSlug) === 'INVENTORY_RESERVE'
              ) && (
                <AlloyButton
                  data-testid="reserve-update-btn"
                  type="primary"
                  disabled={selectedRows.length === 0 || updatingActiveAssortment}
                  onClick={() => {
                    setShowReserveBulkUpdateModal(true);
                  }}
                >
                  Reserve Bulk Update
                </AlloyButton>
              )}
              {!active ? (
                <AlloyButton
                  data-testid="activate-aa-item"
                  disabled={selectedRows.length === 0 || updatingActiveAssortment}
                  onClick={() => handleActiveAssortmentItemActivation(true)}
                >
                  Activate Items
                </AlloyButton>
              ) : (
                <AlloyButton
                  data-testid="remove-aa-item"
                  disabled={selectedRows.length === 0 || updatingActiveAssortment}
                  danger
                  type="primary"
                  onClick={() => handleActiveAssortmentItemActivation(false)}
                >
                  Remove Items
                </AlloyButton>
              )}
            </div>
          </div>

          <div className={s.counter_and_paginator_container}>
            <QuantityDisplay
              count={
                selectedRows.length > 0 ? selectedRows.length : activeAssortmentItems.totalCount
              }
              titleSingular={`${selectedRows.length > 0 ? 'Selected ' : ''} Product`}
              titleMultiple={`${selectedRows.length > 0 ? 'Selected ' : ''} Products`}
            />

            <div className={s.assortment_table_toolbar_paginator}>
              <Paginator
                nextPage={nextPage}
                prevPage={prevPage}
                handlePageSizeChange={handlePageSizeChange}
                hasNextPage={!!activeAssortmentItems.pageInfo?.hasNextPage}
                hasPreviousPage={!!activeAssortmentItems.pageInfo?.hasPreviousPage}
                pageSize={pageSize}
                onlyButtons={false}
              />
            </div>
          </div>
          <ActiveAssortmentTable
            tradingPartnerId={tpId}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            activeAssortmentItems={activeAssortmentItems.itemsList}
            retailerDeliveryDestinationsList={retailerDeliveryDestinations}
            substitutionProductView={substitutionProductView}
            setSubstitutionProductView={setSubstitutionProductView}
            setViewChangeHistoryAssortment={setViewChangeHistoryAssortment}
            updating={updating}
            config={assortmentConfig}
            distributionCenters={
              getVendorMarket(retailersList, vendorMarketId)?.distributionCenters || []
            }
          />
          <ProductDetailsDrawer
            onClose={() => {
              setProductId(undefined);
            }}
            productId={editedItem || !productId ? undefined : productId}
            substitutionProductView={substitutionProductView}
            distributionCenters={distributionCenters}
            tradingPartnerId={tpId}
            startEditing={(retailerProduct) => {
              setEditedItem(retailerProduct);
            }}
            active={!!active}
          />
          <div className={s.bottom_pagination}>
            <Paginator
              nextPage={nextPage}
              prevPage={prevPage}
              handlePageSizeChange={handlePageSizeChange}
              hasNextPage={!!activeAssortmentItems.pageInfo?.hasNextPage}
              hasPreviousPage={!!activeAssortmentItems.pageInfo?.hasPreviousPage}
              pageSize={pageSize}
              onlyButtons={true}
            />
          </div>
        </>
      )}
      {assortmentConfig &&
        (isNewAssortmentEnabled ? (
          <EditAssortmentModal
            tradingPartner={getTradingPartner(
              getVendorMarket(retailersList, vendorMarketId)?.tradingPartners || [],
              getTradingPartnerId(retailersList, tradingPartnerIds, vendorMarketId) || ''
            )}
            visible={!!editedItem}
            onCancel={() => {
              setEditedItem(undefined);
              setProductId(undefined);
            }}
            upsertTradingPartnerAssortment={upsertTradingPartnerAssortment}
            retailerProduct={editedItem}
            vendorId={
              retailersList.find(
                (vm) => vm.externalId === getVendorMarketExternalId(vendorMarketId)
              )?.vendor?.id
            }
            tradingPartnerDistributionCenters={distributionCenters}
            assortmentConfig={assortmentConfig}
            vendorMarketId={
              retailersList.find(
                (vm) => vm.externalId === getVendorMarketExternalId(vendorMarketId)
              )?.id || ''
            }
            retailerProductExternalIdType={
              dataTpConfig?.tradingPartner?.vendorMarket.vendor?.retailerProductExternalIdType ??
              undefined
            }
            changeAddingFlowToUpdate={(retailerProduct) => setEditedItem(retailerProduct)}
          />
        ) : (
          <EditTradingPartnerAssortmentByConfig
            tradingPartner={getTradingPartner(
              getVendorMarket(retailersList, vendorMarketId)?.tradingPartners || [],
              getTradingPartnerId(retailersList, tradingPartnerIds, vendorMarketId) || ''
            )}
            visible={!!editedItem}
            onCancel={() => {
              setEditedItem(undefined);
              setProductId(undefined);
            }}
            upsertTradingPartnerAssortment={upsertTradingPartnerAssortment}
            retailerProduct={editedItem}
            vendorId={
              retailersList.find(
                (vm) => vm.externalId === getVendorMarketExternalId(vendorMarketId)
              )?.vendor?.id
            }
            tradingPartnerDistributionCenters={distributionCenters}
            assortmentConfig={assortmentConfig}
            vendorMarketId={
              retailersList.find(
                (vm) => vm.externalId === getVendorMarketExternalId(vendorMarketId)
              )?.id || ''
            }
            retailerProductExternalIdType={
              dataTpConfig?.tradingPartner?.vendorMarket.vendor?.retailerProductExternalIdType ??
              undefined
            }
            changeAddingFlowToUpdate={(retailerProduct) => setEditedItem(retailerProduct)}
          />
        ))}
      <ReserveBulkUpdateModal
        visible={showReserveBulkUpdateModal}
        selectedRows={selectedRows}
        close={(clearSelection: boolean) => {
          setShowReserveBulkUpdateModal(false);
          if (clearSelection) {
            setSelectedRows([]);
          }
        }}
        tradingPartnerId={tradingPartnerIds || ''}
        vendorMarketId={getVendorMarketId()}
      />
      {showBulkAddAssortmentModal && tradingPartner && (
        <BulkAddActiveAssortmentWithConfigModal
          toggleModal={toggleBulkAddAssortmentModalVisibility}
          tradingPartner={{ ...tradingPartner }}
          assortmentConfig={assortmentConfig}
        />
      )}
      {viewChangeHistoryAssortment && (
        <ChangeHistory
          entity={
            viewChangeHistoryAssortment
              ? {
                  id: viewChangeHistoryAssortment.retailerProductId || '',
                  name: viewChangeHistoryAssortment.substitutions[0].name || '',
                  externalId:
                    viewChangeHistoryAssortment.retailerProductExternalId ||
                    viewChangeHistoryAssortment.substitutions[0].upc ||
                    '',
                  tradingPartnerId:
                    getTradingPartnerId(retailersList, tradingPartnerIds, vendorMarketId) || '',
                  type: isNewAssortmentEnabled ? 'New Assortment' : 'Assortment'
                }
              : undefined
          }
          onClose={() => setViewChangeHistoryAssortment(undefined)}
        />
      )}
    </>
  );
};
