import React, { useMemo, useState } from 'react';
import {
  FiscalCalendarWeekInputWithWeekOnly,
  stringifyCalendarValue
} from 'components/PeriodCalendar/helpers';
import { useQuery } from '@apollo/client';
import {
  ChargebacksReportDetailsByPeriodForTableDocument,
  ChargebacksReportDetailsByPeriodForTableQuery
} from './gql/__generated__/chargebacksReportDetailsByPeriodForTable.query';
import { AlloyTable, ColumnsType, ColumnType } from 'components/ui/AlloyTable/AlloyTable';
import { groupBy, sumBy } from 'lodash-es';
import s from './Table.module.scss';
import currency from 'currency.js';
import { convertWeekToPeriodWeekString } from 'common/helpers/fiscalCalendar';
import clsx from 'clsx';
import { EMPTY } from 'common/constants';
import { __EMPTY__DIMENION__, stringifyBackendValue } from 'pages/Chargebacks/helpers';
import { CalendarValue } from 'components/PeriodCalendar/types';
import { safeLocaleCompare } from 'common/helpers/comparators';
import { notEmpty } from 'common/helpers/notEmpty';
import { useDeepCompareEffect } from 'use-deep-compare';
import { Dimension, fieldToDimension, filterChargebacksData } from '../Filters/helpers';
import { ALL } from '../Filters/helpers';
import { useChargebacksFiltersFromQueryParam } from '../Filters/hooks';

const COLORS: Record<string, string> = {
  pbc: s.pbc,
  flna: s.flna,
  quaker: s.quaker,
  gatorade: s.gatorade
};

type ChargebackReportByPeriod =
  ChargebacksReportDetailsByPeriodForTableQuery['chargebackDetailsReportsByPeriod'][number];

type TableEntry = {
  name: string;
  key: string;
  dimension: Dimension;
  total: number;
  children?: TableEntry[];
  level: number;
  isLast?: boolean;
} & {
  [K in `year_${number}_week_${number}`]: number;
};

const filters: { label: string; value: Dimension }[] = [
  { label: 'BU', value: 'businessUnit' },
  { label: 'VC', value: 'tradingPartner' },
  { label: 'Issue', value: 'issueType' },
  { label: 'Sub-Issue', value: 'subTypeOfTheNonCompliance' },
  { label: 'Status', value: 'status' }
];

const initialfilterObject = {} as Record<Dimension, string[]>;
filters.forEach((filter) => {
  initialfilterObject[filter.value] = [ALL];
});

const chargebackReportsToTableEntry = (
  items: ChargebackReportByPeriod[],
  name: string,
  dimension: Dimension,
  key: string,
  level: number
): TableEntry => {
  const result: TableEntry = {
    name,
    key,
    dimension,
    total: 0,
    level
  };
  items.forEach((item) => {
    const field: `year_${number}_week_${number}` = `year_${item.fiscalCalendarWeek.year}_week_${item.fiscalCalendarWeek.week}`;
    if (field) {
      result[field] = currency(result[field] || 0).add(item.financialCharge).value;
      result.total = currency(result.total || 0).add(item.financialCharge).value;
    }
  });

  return result;
};

const getTableDataByDimensions = (
  data: ChargebackReportByPeriod[],
  dimensions: Dimension[],
  parentKey = '',
  level = 0
): TableEntry[] => {
  if (dimensions.length === 0) {
    return [];
  }

  const dimension = dimensions[0];
  const dataWithoutTotal = data
    .filter((x) => !x.totalValue)
    .map((x) => ({ ...x, [dimension]: x[dimension] || __EMPTY__DIMENION__ }));
  const groupedData = groupBy(dataWithoutTotal, dimension);

  const resultArray: TableEntry[] = [];

  for (const key in groupedData) {
    const currentGroup = groupedData[key];

    const combinedKey = parentKey ? `${parentKey}_${key}` : key;

    const entry = chargebackReportsToTableEntry(currentGroup, key, dimension, combinedKey, level);

    // Recursively group the rest of the dimensions
    if (dimensions.length > 1) {
      entry.children = getTableDataByDimensions(
        currentGroup,
        dimensions.slice(1),
        combinedKey,
        level + 1
      );
    }

    resultArray.push(entry);
  }

  const sortedResultArray = [...resultArray].sort((a, b) => (a.total > b.total ? -1 : 1));

  // Mark the last entry with isLast: true
  if (sortedResultArray.length > 0) {
    sortedResultArray[sortedResultArray.length - 1].isLast = true;
  }

  return sortedResultArray;
};

const INDENT_SIZE = 18;
const EXPAND_SIZE = 24;
const MIDDLE = EXPAND_SIZE / 2 + 4;

// TODO: add multiple years?
const getColumns = (
  fiscalCalendarWeeks: FiscalCalendarWeekInputWithWeekOnly[],
  levels: number,
  expandedRowKeys: string[]
) => {
  const columns: ColumnsType<TableEntry> = [
    {
      title: '',
      render: (_, { name, level, dimension }) => {
        const dividers = Array(level)
          .fill(0)
          .map((_x, idx) => idx);

        return (
          <div>
            {dividers.map((level) => (
              <div
                key={level}
                className={s.divider}
                style={{ left: `${level * INDENT_SIZE + MIDDLE}px` }}
              />
            ))}
            {/* We need this paddingLeft, despite indentSize on the table, because otherwise second row of text doesn't have padding. */}
            <div
              style={{ paddingLeft: `${level * INDENT_SIZE + EXPAND_SIZE}px` }}
              data-testid={`table_${dimension}_${name}`}
            >
              {stringifyBackendValue(name)}
            </div>
          </div>
        );
      },
      width: 140 + INDENT_SIZE * levels,
      fixed: 'left',
      key: 'name',
      onCell: (record) => {
        return {
          style: {
            borderBottom:
              record.level === 0 || (record.isLast && !expandedRowKeys.includes(record.key))
                ? undefined
                : 0
          }
        };
      }
    },
    ...fiscalCalendarWeeks.map(({ week, year }): ColumnType<TableEntry> => {
      return {
        title: <div className={s.column_title}>{convertWeekToPeriodWeekString(week)}</div>,
        render: (_, record) => (
          <div className={clsx(s.value)}>
            {isNaN(record[`year_${year}_week_${week}`])
              ? EMPTY
              : currency(record[`year_${year}_week_${week}`]).format()}
          </div>
        ),
        width: 120,
        align: 'right' as const,
        key: `year_${year}_week_${week}`
      };
    }),
    {
      title: 'Total',
      render: (_, record) => <div className={clsx(s.value)}>{currency(record.total).format()}</div>,
      width: 160,
      align: 'right' as const,
      fixed: 'right',
      key: 'total'
    }
  ];

  return {
    columns,
    width: sumBy(columns, 'width')
  };
};

export const Table = ({
  fiscalCalendarWeeks,
  period
}: {
  fiscalCalendarWeeks: FiscalCalendarWeekInputWithWeekOnly[];
  period: CalendarValue;
}) => {
  const [selectedFieldDimensions] = useChargebacksFiltersFromQueryParam();

  const selectedDimensions = useMemo(
    () => selectedFieldDimensions.enabled.map((x) => fieldToDimension[x]).filter(notEmpty),
    [selectedFieldDimensions]
  );

  const chargebackData = useQuery(ChargebacksReportDetailsByPeriodForTableDocument, {
    variables: {
      dimensions: selectedDimensions,
      filters: {
        fiscalCalendarWeeks,
        countryCode: 'US'
      }
    }
  });

  const filteredData = useMemo(
    () =>
      filterChargebacksData(
        chargebackData.data?.chargebackDetailsReportsByPeriod || [],
        selectedFieldDimensions.filters
      ),
    [chargebackData.data?.chargebackDetailsReportsByPeriod, selectedFieldDimensions.filters]
  );

  const tableData = useMemo(
    () => getTableDataByDimensions(filteredData, selectedFieldDimensions.enabled),
    [filteredData, selectedFieldDimensions.enabled]
  );

  const areMultipleYearsSelected = period.years.length > 1;

  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  // Used to reset values if filter changes
  useDeepCompareEffect(() => {
    setExpandedRowKeys([]);
  }, [period, selectedFieldDimensions]);

  const { columns, width } = useMemo(
    () => getColumns(fiscalCalendarWeeks, selectedDimensions.length, expandedRowKeys),
    [expandedRowKeys, fiscalCalendarWeeks, selectedDimensions.length]
  );

  if (chargebackData.error) return <div>Could not load data due to an error</div>;

  return (
    <div>
      <h2 className={s.title}>
        <span>
          Report period breakdown{' '}
          {`(${areMultipleYearsSelected ? period.years : ''}${stringifyCalendarValue(period)})`}
        </span>
      </h2>
      <AlloyTable
        loading={chargebackData.loading}
        columns={columns}
        dataSource={tableData}
        rowKey="key"
        pagination={false}
        size="small"
        scroll={{ x: width }}
        indentSize={INDENT_SIZE}
        className={s.table}
        sticky={{
          offsetScroll: 6
        }}
        rowClassName={(row) => {
          const isLastRow = selectedDimensions.length === row.level + 1;
          const isSecondToLastRow = selectedDimensions.length - 1 === row.level + 1;
          const isExpanded = expandedRowKeys.includes(row.key);

          const baseClassName = clsx(s.colored_background, { [s.bold]: isExpanded });

          if (COLORS[row.name.toLowerCase()])
            return clsx(baseClassName, COLORS[row.name.toLowerCase()]);
          if (isLastRow) return clsx(baseClassName, s.last_expanded);
          if (isSecondToLastRow && isExpanded) return clsx(baseClassName, s.last_expandable);

          return clsx(baseClassName, s.neutral);
        }}
        expandable={{
          expandedRowKeys: expandedRowKeys,
          onExpandedRowsChange: (rows) => setExpandedRowKeys(rows as string[])
        }}
      />
    </div>
  );
};
