import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import {
  convertWeekToPeriodWeek,
  convertWeekToPeriodWeekString
} from 'common/helpers/fiscalCalendar';
import { getPreviousWeekAndYear } from 'common/helpers/periodHelper';
import { ExecutiveReportingFilters } from 'graphql/__generated__/types';
import { ExecutiveIntelligenceSummaryDocument } from './gql/__generated__/executiveIntelligenceSummary.query';
import { NumberAndText, StatTile } from '../StatTile/StatTile';
import { EMPTY } from 'common/constants';
import s from './Summary.module.scss';
import { calculateChange, prepareChargebacksData } from 'pages/ExecutiveIntelligence/helpers';
import {
  FiscalCalendarWeekInputWithWeekOnly,
  getCurrentPeriod
} from 'components/PeriodCalendar/helpers';
import { useDeepCompareMemo } from 'use-deep-compare';
import { ExecutiveIntelligenceChargebacksTotalDocument } from './gql/__generated__/executiveIntelligenceChargebacksTotal.query';
import { convertToMM } from 'common/helpers/convertToMM';

const formatPercentage = (value: any) => {
  const parsedValue = parseFloat(value);
  if (Number.isNaN(parsedValue)) return EMPTY;
  // TODO: WHY it's 1 HERE??
  return `${(parsedValue * 100).toFixed(1)}`;
};

const currentPeriod = getCurrentPeriod(true);

// We want to compare PREVIOUS week to PREVIOUS PREVIOUS week, since CURRENT week has no data
const actualPeriod = getPreviousWeekAndYear(currentPeriod.week, currentPeriod.year);
const comparisonPeriod = getPreviousWeekAndYear(actualPeriod.week, actualPeriod.year);
const areWeeksNotEqual = actualPeriod.week !== comparisonPeriod.week;

export const Summary = ({
  executiveReportingFilters
}: {
  executiveReportingFilters: ExecutiveReportingFilters;
}) => {
  const shouldShowComparison = useDeepCompareMemo(
    () =>
      !!executiveReportingFilters.fiscalCalendarWeeks.find(
        (x) => x.week === actualPeriod.week && x.year === actualPeriod.year
      ) &&
      !!executiveReportingFilters.fiscalCalendarWeeks.find(
        (x) => x.week === comparisonPeriod.week && x.year === comparisonPeriod.year
      ),
    [executiveReportingFilters.fiscalCalendarWeeks]
  );

  const allVariables = useDeepCompareMemo(
    () => ({
      filters: executiveReportingFilters,
      actualFilters: {
        ...executiveReportingFilters,
        fiscalCalendarWeeks: [
          {
            ...actualPeriod,
            period: convertWeekToPeriodWeek(actualPeriod.week).period
          }
        ]
      },
      comparisonFilters: {
        ...executiveReportingFilters,
        fiscalCalendarWeeks: [
          {
            ...comparisonPeriod,
            period: convertWeekToPeriodWeek(comparisonPeriod.week).period
          }
        ]
      }
    }),
    [executiveReportingFilters]
  );

  const totalsQuery = useQuery(ExecutiveIntelligenceSummaryDocument, {
    variables: allVariables
  });

  const chargebackTotalsQuery = useQuery(ExecutiveIntelligenceChargebacksTotalDocument, {
    variables: {
      filters: executiveReportingFilters
    }
  });

  const chargebacksData = useMemo(
    () =>
      prepareChargebacksData({
        data: chargebackTotalsQuery.data?.chargebackDetailsReportsByPeriod || [],
        actualPeriod,
        comparisonPeriod,
        shouldShowChange: areWeeksNotEqual && shouldShowComparison,
        mode: 'net',
        fiscalCalendarWeeks:
          executiveReportingFilters.fiscalCalendarWeeks as FiscalCalendarWeekInputWithWeekOnly[]
      }),
    [
      chargebackTotalsQuery.data?.chargebackDetailsReportsByPeriod,
      executiveReportingFilters.fiscalCalendarWeeks
    ]
  );

  const otifChange = calculateChange(
    totalsQuery.data?.actualPurchaseOrdersTotalsReport?.otif,
    totalsQuery.data?.comparisonPeriodPurchaseOrdersTotalsReport?.otif,
    areWeeksNotEqual && shouldShowComparison,
    true,
    (value) => `${formatPercentage(value)}%`
  );
  const sfrChange = calculateChange(
    totalsQuery.data?.actualPurchaseOrdersTotalsReport?.submittedFillRate,
    totalsQuery.data?.comparisonPeriodPurchaseOrdersTotalsReport?.submittedFillRate,
    areWeeksNotEqual && shouldShowComparison,
    true,
    (value) => `${formatPercentage(value)}%`
  );

  return (
    <>
      <div className={s.stats}>
        <StatTile
          title="OTIF"
          value={
            <NumberAndText
              number={formatPercentage(totalsQuery.data?.otifPurchaseOrdersTotalsReport?.otif)}
              text="%"
            />
          }
          tooltip="On Time In Full"
          loading={totalsQuery.loading}
          change={otifChange}
          changeText="than prev. period"
          changeTooltip={`In ${convertWeekToPeriodWeekString(
            actualPeriod.week
          )} compared to ${convertWeekToPeriodWeekString(comparisonPeriod.week)}`}
        />
        <StatTile
          title="SFR"
          value={
            <NumberAndText
              number={formatPercentage(
                totalsQuery.data?.otifPurchaseOrdersTotalsReport?.submittedFillRate
              )}
              text="%"
            />
          }
          tooltip="Submitted Fill Rate"
          loading={totalsQuery.loading}
          change={sfrChange}
          changeText="than prev. period"
          changeTooltip={`In ${convertWeekToPeriodWeekString(
            actualPeriod.week
          )} compared to ${convertWeekToPeriodWeekString(comparisonPeriod.week)}`}
        />
        <StatTile
          title="Net Chargebacks"
          value={
            <NumberAndText
              number={convertToMM(chargebacksData.total)}
              tooltip={`$${chargebacksData.total.toLocaleString('en-US')}`}
            />
          }
          loading={chargebackTotalsQuery.loading}
          change={chargebacksData.change}
          changeText="than prev. period"
          changeTooltip={`In ${convertWeekToPeriodWeekString(
            actualPeriod.week
          )} compared to ${convertWeekToPeriodWeekString(comparisonPeriod.week)}`}
        />
      </div>
    </>
  );
};
