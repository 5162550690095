import { App, Empty } from 'ant5';
import { DEFAULT_PAGE_SIZE_OPTIONS } from 'common/constants';
import { dateFormat } from 'common/helpers/date';
import { formatNumber } from 'common/helpers/formatNumber';
import { notEmpty } from 'common/helpers/notEmpty';
import { getColumnSortOrder } from 'common/helpers/sorting';
import { Paginator } from 'components/Paginator/Paginator';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import AlloyProgressBar from 'components/ui/AlloyProgressBar/AlloyProgressBar';
import { AlloyTable, ColumnsType, SorterResult } from 'components/ui/AlloyTable/AlloyTable';
import { ExpandButton } from 'components/ui/ExpandButton/ExpandButton';
import { QuantityDisplay } from 'components/ui/QuantityDisplay/QuantityDisplay';
import React, { useEffect, useMemo, useState } from 'react';
import s from './TruckBuilderOrdersView.module.scss';
import { WhseTruckBuilderOrdersDocument } from './gql/__generated__/whseTruckBuilderOrders.query';
import { useMutation, useQuery } from '@apollo/client';
import { OrderSortColumn, SortOrderDirection } from 'graphql/__generated__/types';
import { useQueryParam, withDefault, StringParam, NumberParam } from 'use-query-params';
import { getNodesFromEdges } from 'common/helpers/mappingHelper';
import { AlloyDropdown } from 'components/ui/AlloyDropdown/AlloyDropdown';
import { MoreOutlined, SearchOutlined } from '@ant-design/icons';
import { Materials, WhseTruckBuilderOrder } from './types';
import ErrorDisplay from 'components/Common/ErrorDisplay';
import clsx from 'clsx';
import { calculatePercentageOfCapacity } from '../helper';
import { EditWarehouseOpenOrdersDocument } from 'pages/WarehouseOrderProcessingPage/gql/__generated__/editWarehouseOpenOrders.mutation';
import { AlloyInput } from 'components/ui/AlloyInput/AlloyInput';
import { AlloySelect } from 'components/ui/AlloySelect/AlloySelect';
import { WhseTruckBuilderBuildTruckDocument } from './gql/__generated__/whseTruckBuilderBuildTruck.mutation';

const TABLE_WIDTH = 1377;

const TRUCK_ORDERS_DEFAULT_PAGE_SIZE = DEFAULT_PAGE_SIZE_OPTIONS[3];

const WhsTruckBuilderOrders: React.FC = () => {
  const { message } = App.useApp();
  const [sortColumn, setSortColumn] = useQueryParam(
    'truck_builder_order_column',
    withDefault(StringParam, 'SHIP_TO')
  );
  const [sortOrder, setSortOrder] = useQueryParam(
    'truck_builder_order',
    withDefault(StringParam, 'DESC')
  );
  const [selectedRows, setSelectedRows] = useState<WhseTruckBuilderOrder[]>([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  const [after, setAfter] = useQueryParam('truck_builder_order_after', StringParam);
  const [before, setBefore] = useQueryParam('truck_builder_order_before', StringParam);
  const [pageSize, setPageSize] = useQueryParam(
    'limit',
    withDefault(NumberParam, TRUCK_ORDERS_DEFAULT_PAGE_SIZE)
  );

  const getRowKey = (record: WhseTruckBuilderOrder) => record.orderNumber;

  const { data, loading, error } = useQuery(WhseTruckBuilderOrdersDocument, {
    variables: {
      first: after || !before ? pageSize : null,
      last: before ? pageSize : null,
      sort: {
        column: sortColumn as OrderSortColumn,
        direction: sortOrder as SortOrderDirection
      },
      filter: { statuses: ['TO_TRUCK_BUILD'] },
      after,
      before
    }
  });

  const ordersTableColumns: ColumnsType<WhseTruckBuilderOrder> = [
    AlloyTable.SELECTION_COLUMN,
    {
      title: 'Orders',
      key: 'ORDER_NUMBER',
      width: 198,
      render: (_, item) => (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {item.orderNumber}
          <AlloyDropdown
            menu={{
              items: [
                {
                  key: 'move',
                  label: 'Move order',
                  onClick: (e) => {
                    e.domEvent.stopPropagation();
                    console.log('not implemented');
                  }
                },
                {
                  key: 'remove',
                  label: 'Remove',
                  onClick: (e) => {
                    e.domEvent.stopPropagation();
                    console.log('not implemented');
                  }
                }
              ]
            }}
            placement="bottomRight"
            trigger={['click']}
          >
            <MoreOutlined
              style={{ height: '10px' }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
          </AlloyDropdown>
        </div>
      ),
      sortOrder: getColumnSortOrder(sortColumn, sortOrder, 'ORDER_NUMBER'),
      sorter: true
    },
    {
      title: 'Ship To',
      key: 'SHIP_TO',
      width: 198,
      align: 'left',
      render: (_, item) => item.shipTo,
      sortOrder: getColumnSortOrder(sortColumn, sortOrder, 'SHIP_TO'),
      sorter: true
    },
    {
      title: 'Total Weights',
      key: 'TOTAL_WEIGHTS',
      width: 198,
      align: 'right',
      render: (_, item) => (
        <>
          <span className={clsx(s.total_weights, s.number_value)}>
            {formatNumber(item.totalWeights)}
          </span>
          <span>{`(${calculatePercentageOfCapacity(item.totalWeights || 0)}%)`}</span>
        </>
      ),
      sortOrder: getColumnSortOrder(sortColumn, sortOrder, 'TOTAL_WEIGHTS'),
      sorter: true
    },
    {
      title: '',
      key: 'LOAD_PROGRESS',
      width: 140,
      render: (_, item) => (
        <AlloyProgressBar
          style={{ width: 95 }}
          progress={calculatePercentageOfCapacity(item.totalWeights || 0)}
        />
      )
    },
    {
      title: 'Pallets',
      key: 'TOTAL_PALLETS',
      align: 'right',
      width: 198,
      render: (_, record) => (
        <span className={s.number_value}>{parseFloat(record.totalPallets || '0').toFixed(2)}</span>
      ),
      sortOrder: getColumnSortOrder(sortColumn, sortOrder, 'TOTAL_PALLETS'),
      sorter: true
    },
    {
      title: 'Ship Date',
      key: 'SHIP_DATE',
      minWidth: 82,
      render: (_, item) => dateFormat(item.shipDate),
      sortOrder: getColumnSortOrder(sortColumn, sortOrder, 'SHIP_DATE'),
      sorter: true
    },
    {
      title: 'Suggested RDD',
      key: 'RDD',
      minWidth: 82,
      render: (_, item) => dateFormat(item.rdd),
      sortOrder: getColumnSortOrder(sortColumn, sortOrder, 'RDD'),
      sorter: true
    }
  ];

  const openOrderMaterialColumns: ColumnsType<Materials> = [
    {
      width: '32px'
    },
    {
      title: 'Material ID',
      key: 'materialId',
      width: 198,
      render: (_, item) => item.materialId
    },
    {
      title: 'Description',
      key: 'description',
      width: 305,
      render: (_, item) => item.description
    },
    {
      title: 'Weight',
      key: 'weight',
      width: 145,
      render: (_, item) => item.weight
    },
    {
      title: 'Pallets',
      key: 'pallets',
      width: 283,
      align: 'right',
      render: (_, record) => (
        <span className={s.number_value}>{parseFloat(record.pallets || '0').toFixed(2)}</span>
      )
    },
    {}
  ];

  const ordersList = useMemo(() => getNodesFromEdges(data?.whseOpenOrdersProcessed), [data]);

  useEffect(() => {
    if (!pageSize || !sortColumn || !sortOrder) {
      setPageSize(TRUCK_ORDERS_DEFAULT_PAGE_SIZE);
      setSortColumn('SHIP_TO');
      setSortOrder('DESC');
    }
  }, [pageSize, setPageSize, setSortColumn, setSortOrder, sortColumn, sortOrder]);

  const nextPage = async () => {
    setAfter(data?.whseOpenOrdersProcessed?.pageInfo?.endCursor);
    setBefore(undefined);
  };

  const prevPage = async () => {
    setAfter(undefined);
    setBefore(data?.whseOpenOrdersProcessed?.pageInfo?.startCursor);
  };

  const handlePageSizeChange = async (value: number) => {
    setPageSize(value);
    setAfter(undefined);
    setBefore(undefined);
  };

  const handleTableSorting = (column: string, order: string) => {
    setSortColumn(column);
    setSortOrder(order);
    setAfter(undefined);
    setBefore(undefined);
  };

  const currentPageOrdersExpandableKeys = useMemo(() => {
    return ordersList.map(getRowKey);
  }, [ordersList]);

  const areAllKeysExpandedOnThePage = useMemo(
    () => currentPageOrdersExpandableKeys.every((order) => expandedRowKeys.includes(order)),
    [expandedRowKeys, currentPageOrdersExpandableKeys]
  );

  const getExpandedRowKey = (material: Materials) => material.materialId;

  const ordersTotalCount = data?.whseOpenOrdersProcessed?.totalCount || 0;

  const [removeWhseTruckBuilderOrdersMutation, { loading: removeWhseTruckBuilderOrdersLoading }] =
    useMutation(EditWarehouseOpenOrdersDocument, {
      onCompleted: () => {
        setSelectedRows([]);
      },
      onError: (error) => {
        message.error(error.message);
      },
      refetchQueries: [WhseTruckBuilderOrdersDocument]
    });

  const handleWhseTruckBuilderOrdersRemove = async () => {
    const orderNumbers = selectedRows.map((row) => row.orderNumber);
    await removeWhseTruckBuilderOrdersMutation({
      variables: {
        input: {
          orderNumbers,
          editOption: 'ready_to_ship'
        }
      }
    });

    message.success(`${orderNumbers.length} order${orderNumbers.length === 1 ? '' : 's'} removed`);
  };

  const [whseTruckBuilderBuildTruckMutation, { loading: whseTruckBuilderBuildTruckLoading }] =
    useMutation(WhseTruckBuilderBuildTruckDocument, {
      onCompleted: (data) => {
        setSelectedRows([]);
        message.success(data?.createWarehouseTruckload?.message);
      },
      onError: (error) => {
        message.error(error.message);
      },
      refetchQueries: [WhseTruckBuilderOrdersDocument]
    });

  const handleBuildTruck = async () => {
    const orderNumbers = selectedRows.map((row) => row.orderNumber);
    await whseTruckBuilderBuildTruckMutation({
      variables: {
        input: {
          orderNumbers
        }
      }
    });
  };

  if (error) return <ErrorDisplay error={error} />;

  return (
    <>
      <div className={s.table_tools_container}>
        <div data-testid="search-and-filters-container" className={s.search_and_filters_container}>
          <div className={s.search_bar}>
            <AlloyInput
              placeholder="Search"
              allowClear={true}
              prefix={<SearchOutlined width="14px" height="14px" />}
              disabled={true}
            />
          </div>
          <AlloySelect
            className={s.select}
            placeholder="Filter"
            allowClear
            options={[
              {
                key: 'all',
                value: 'all',
                label: 'All'
              }
            ]}
            disabled={true}
          />
        </div>

        <div className={s.table_actions_container}>
          <AlloyButton
            disabled={selectedRows.length === 0}
            loading={removeWhseTruckBuilderOrdersLoading}
            onClick={handleWhseTruckBuilderOrdersRemove}
            type="secondary"
          >
            Remove
          </AlloyButton>
          <AlloyButton
            loading={whseTruckBuilderBuildTruckLoading}
            disabled={selectedRows.length === 0}
            onClick={handleBuildTruck}
            type="primary"
          >
            Build truck
          </AlloyButton>
        </div>
      </div>

      <div className={s.quantity_display_and_paginator_container}>
        <div>
          {selectedRows.length > 0 ? (
            <QuantityDisplay
              count={selectedRows.length}
              titleSingular={'order selected'}
              titleMultiple={'orders selected'}
            />
          ) : (
            `${ordersTotalCount} order${ordersTotalCount === 1 ? '' : 's'}`
          )}
        </div>

        <Paginator
          pageSize={pageSize || TRUCK_ORDERS_DEFAULT_PAGE_SIZE}
          hasNextPage={!!data?.whseOpenOrdersProcessed?.pageInfo.hasNextPage}
          hasPreviousPage={!!data?.whseOpenOrdersProcessed?.pageInfo.hasPreviousPage}
          handlePageSizeChange={handlePageSizeChange}
          prevPage={prevPage}
          nextPage={nextPage}
          onlyButtons={false}
        />
      </div>

      <AlloyTable
        columns={ordersTableColumns}
        sticky
        pagination={false}
        loading={loading}
        scroll={{ x: TABLE_WIDTH }}
        dataSource={ordersList}
        rowKey={getRowKey}
        rowSelection={{
          preserveSelectedRowKeys: true,
          onChange: (_keys, rows) => {
            setSelectedRows(rows);
          },
          type: 'checkbox',
          selectedRowKeys: selectedRows?.map((row) => row.orderNumber),
          // @ts-expect-error add testid prop
          getCheckboxProps(record) {
            return { 'data-testid': record.orderNumber };
          }
        }}
        onChange={(_pagination, _filters, sorter) => {
          const column = (sorter as SorterResult<WhseTruckBuilderOrder>).columnKey || 'SHIP_TO';
          const order =
            (sorter as SorterResult<WhseTruckBuilderOrder>).order === 'ascend' ||
            !(sorter as SorterResult<WhseTruckBuilderOrder>).order
              ? 'ASC'
              : 'DESC';
          handleTableSorting(column.toString(), order);
        }}
        locale={{
          emptyText: (
            <Empty
              className={s.table_empty_state}
              style={{
                height: 260,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                fontWeight: 'bold'
              }}
              description="No items found"
            />
          )
        }}
        expandable={{
          columnTitle: currentPageOrdersExpandableKeys.length > 0 && (
            <ExpandButton
              expanded={areAllKeysExpandedOnThePage}
              onClick={(expanded) => {
                return expanded
                  ? setExpandedRowKeys(
                      expandedRowKeys.filter((x) => !currentPageOrdersExpandableKeys.includes(x))
                    )
                  : setExpandedRowKeys([
                      ...new Set([...currentPageOrdersExpandableKeys, ...expandedRowKeys])
                    ]);
              }}
              collapseMessage="Collapse all"
              expandMessage="Expand all"
            />
          ),
          expandedRowKeys: expandedRowKeys,
          expandedRowRender: ({ materials }) => (
            <AlloyTable
              loading={loading}
              data-testid="open-order-materials-table"
              className={s.open_order_materials_table}
              columns={openOrderMaterialColumns.filter(notEmpty)}
              dataSource={materials?.map((material) => material).filter(notEmpty)}
              pagination={false}
              rowKey={getExpandedRowKey}
            />
          ),
          onExpandedRowsChange: (rows) => setExpandedRowKeys(rows as string[]),
          rowExpandable: ({ materials }) => !!materials
        }}
      />

      <div className={s.bottom_paginator}>
        <Paginator
          pageSize={pageSize || TRUCK_ORDERS_DEFAULT_PAGE_SIZE}
          hasNextPage={!!data?.whseOpenOrdersProcessed?.pageInfo.hasNextPage}
          hasPreviousPage={!!data?.whseOpenOrdersProcessed?.pageInfo.hasPreviousPage}
          handlePageSizeChange={handlePageSizeChange}
          prevPage={prevPage}
          nextPage={nextPage}
          onlyButtons={false}
        />
      </div>
    </>
  );
};

export default WhsTruckBuilderOrders;
