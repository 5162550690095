import { useEnumValue } from 'common/useEnumValue';
import { validateRequired } from 'common/helpers/validationHelper';
import { FormApi } from 'final-form';
import {
  EditStoreInput,
  getDistributionCenterTitle,
  SpecialForTradingPartnersFields
} from 'pages/ShipToPage/helper';
import { RetailerChannelsForShipTo } from 'pages/ShipToPage/ShipToPage';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { DistributionCenter, TradingPartner } from '../../AddShipToModal/AddShipToModal';
import {
  getDeliveryScheduleArrayEditing,
  getShipToGeneralFields
} from '../../AddShipToModal/components/AddShipToStepper/AddShipToStepper';
import {
  DistributionCenterItem,
  AllowedDistributionCentersList
} from '../../AllowedDistributionCentersList/AllowedDistributionCentersList';
import { FullScreenEditingModal } from 'components/Common/FullScreenEditingModal/FullScreenEditingModal';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { AlloyCollapse } from 'components/ui/AlloyCollapse/AlloyCollapse';
import { AlloyTabs, TabsProps } from 'components/ui/AlloyTabs/AlloyTabs';
import { FieldInfo } from 'components/ui/formFields/FieldInfo/FieldInfo';

interface EditShipToTabsProps {
  form: FormApi<EditStoreInput, Partial<EditStoreInput>>;
  onClose: () => void;
  tradingPartners: TradingPartner[];
  vendorMarkets: RetailerChannelsForShipTo[];
  distributionCenters: DistributionCenter[];
  requiredFields: string[];
  visible: boolean;
}

export const EditShipToTabs = ({
  form,
  onClose,
  tradingPartners,
  vendorMarkets,
  distributionCenters,
  requiredFields,
  visible
}: EditShipToTabsProps) => {
  const [activeTab, setActiveTab] = useState('general');

  const { enumValues: storeTypes, loading: storeTypesLoading } = useEnumValue('StoreType');
  const { enumValues: daysOfWeek, loading: daysOfWeekLoading } = useEnumValue('DayOfWeek');

  useEffect(() => {
    if (!visible) setActiveTab('general');
  }, [visible]);

  const buttons = (
    <>
      <AlloyButton onClick={onClose} data-testid="cancel-shipto-changes">
        Cancel
      </AlloyButton>
      <AlloyButton
        type="primary"
        data-testid="save-shipto-changes"
        onClick={() => {
          document
            ?.getElementById('edit-ship-to-modal-form')
            ?.dispatchEvent(new Event('submit', { cancelable: true }));
        }}
      >
        Save
      </AlloyButton>
    </>
  );

  const tabs: TabsProps['items'] = [
    {
      key: 'general',
      label: 'General Settings',
      children: getShipToGeneralFields(
        storeTypesLoading,
        storeTypes,
        tradingPartners,
        vendorMarkets,
        false,
        requiredFields,
        form,
        undefined,
        activeTab === 'general',
        false
      )
    },
    {
      key: 'tpSettings',
      label: 'TP-Specific settings',
      children: (
        <FieldArray name="tradingPartnerConfiguration">
          {({ fields }) => (
            <AlloyCollapse
              items={fields.map((name, index) => ({
                key: fields.value[index].tradingPartnerId,
                showArrow: true,
                label: tradingPartners.find((tp) => tp.id === fields.value[index].tradingPartnerId)
                  ?.name,
                children: (
                  <div style={{ padding: '16px' }}>
                    <SpecialForTradingPartnersFields
                      distributionCenters={distributionCenters}
                      name={name}
                    />
                    {getDeliveryScheduleArrayEditing(daysOfWeek || [], daysOfWeekLoading, name)}
                  </div>
                )
              }))}
            />
          )}
        </FieldArray>
      )
    },
    {
      key: 'allowedDcs',
      label: <span data-testid="edit-ship-to-dc-tab-title">DC</span>,
      forceRender: true,
      children: (
        <FieldArray name="allowedDcs" validate={validateRequired}>
          {({ fields, meta }) => (
            <>
              <AllowedDistributionCentersList
                data-testid="edit-ship-to-dc-tab-ordering-dc-list"
                allDistributionCenters={distributionCenters.map((dc) => ({
                  id: dc.id,
                  text: `${dc.name} (${dc.code})`,
                  externalId:
                    (fields.value || []).find(
                      (selectedDc) => selectedDc.distributionCenterId === dc.id
                    )?.externalId || ''
                }))}
                selected={(fields.value || [])
                  .sort((dc1, dc2) => dc1.priority - dc2.priority)
                  .map((dc) => ({
                    id: dc.distributionCenterId,
                    text: getDistributionCenterTitle(dc, distributionCenters),
                    externalId: dc.externalId
                  }))}
                onChange={(newAllowedDcs: DistributionCenterItem[]) => {
                  form.mutators.setAllowedDcs(
                    newAllowedDcs.map((dc, index) => ({
                      distributionCenterId: dc.id,
                      externalId: dc.externalId,
                      priority: index + 1
                    }))
                  );
                }}
              />
              <FieldInfo
                error={meta.error && meta.touched ? 'Please select at least one DC' : undefined}
                withoutMargin
              />
            </>
          )}
        </FieldArray>
      )
    }
  ];

  return (
    <FullScreenEditingModal
      title={'Edit Ship-To'}
      open={visible}
      onClose={onClose}
      buttons={buttons}
    >
      <AlloyTabs
        style={{ flex: 1, height: 'calc(100% - 64px)' }}
        activeKey={activeTab}
        onChange={(activeKey: string) => setActiveTab(activeKey)}
        items={tabs}
      />
    </FullScreenEditingModal>
  );
};
