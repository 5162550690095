import { useLazyQuery } from '@apollo/client';
import { RddSortColumn, SortOrderDirection } from 'graphql/__generated__/types';
import { parse } from 'query-string';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ExportRetailerDeliveryDestinationsDocument } from 'pages/ShipToPage/gql/__generated__/exportShipTos.query';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { searchStringToQueryFilter } from '../AdvancedFilters/AdvancedFilters';
import { download } from 'components/Common/utils';
import moment from 'moment';

export const ExportShipTos = () => {
  const history = useHistory();

  const [exportShipTos, { loading }] = useLazyQuery(ExportRetailerDeliveryDestinationsDocument, {});

  return (
    <div>
      <AlloyButton
        loading={loading}
        size="large"
        onClick={async () => {
          const location = parse(history.location.search);
          const search = typeof location.search === 'string' ? location.search : '';
          const { filterData, advancedFilterData } = searchStringToQueryFilter(
            history.location.search
          );
          const { data } = await exportShipTos({
            variables: {
              sort: {
                column: (location.column as RddSortColumn) || 'VENDOR_MARKET_NAME',
                direction: (location.order as SortOrderDirection) || 'ASC'
              },
              searchLike: search,
              filter: filterData,
              advancedFilter: advancedFilterData
            }
          });

          try {
            if (data && data.exportRetailerDeliveryDestinations) {
              download(
                `${import.meta.env.REACT_APP_API_URL}${data.exportRetailerDeliveryDestinations}`,
                `export_ship_tos_${moment().format('MM-DD-YYYY_hh:mm:ssA')}.csv`
              );
            }
          } catch (error) {
            console.error('Error downloading file:', error);
          }
        }}
      >
        Export
      </AlloyButton>
    </div>
  );
};
