import React, { useCallback, useMemo } from 'react';
import { useMutation } from '@apollo/client';
import s from './PurchaseOrderItemsTableDSD.module.scss';
import { App } from 'ant5';
import { AlloyTable, ColumnsType } from 'components/ui/AlloyTable/AlloyTable';
import { AlignType } from 'common/types';
import { AlloyTooltip } from 'components/ui/AlloyTooltip/AlloyTooltip';
import { ExclamationCircleFilled } from '@ant-design/icons';
import errorIcon from '../../../../assets/icons/common/error_icon.svg';
import {
  PO_STATUS_NEW,
  PO_STATUS_EXPORTED,
  PO_STATUS_SCRUBBED,
  PO_STATUS_ACKNOWLEDGED,
  POI_WARNING_MAP
} from 'common/constants';
import { sum } from 'lodash-es';
import PurchaseOrderQuantityUpdate from '../PurchaseOrderQuantityUpdate';
import {
  UpdateQuantityDsdDocument,
  UpdateQuantityDsdMutationVariables
} from './gql/__generated__/updateQuantityDSD.mutation';
import { safeLocaleCompare } from 'common/helpers/comparators';
import { PurchaseOrderItem, PurchaseOrderWithOverdue } from 'pages/OrderDetailsPage/types';

interface PurchaseOrderItemsTableDSDProps {
  loading: boolean;
  purchaseOrder: PurchaseOrderWithOverdue;
  validItems: PurchaseOrderItem[];
  isConditionalColumnsEnabled: boolean | undefined;
}

type ToUpdate = NonNullable<UpdateQuantityDsdMutationVariables['input']['toUpdate']>;

export const PurchaseOrderItemsTableDSD = ({
  loading,
  purchaseOrder,
  validItems,
  isConditionalColumnsEnabled
}: PurchaseOrderItemsTableDSDProps) => {
  const { message } = App.useApp();
  const { Summary } = AlloyTable;

  const { statuses, businessUnit, capacityCubicFeetBoh } = purchaseOrder;

  const [updateQuantity] = useMutation(UpdateQuantityDsdDocument, {
    refetchQueries: ['purchaseOrderItems'],
    onCompleted: () => {
      message.success('Order Qty was successfully updated');
    },
    onError: (error) => {
      console.error(error.message);
      message.error(`An error occurred when updating Order Qty: ${error.message}`);
    }
  });

  const updatePurchaseOrderSingleQuantity = useCallback(
    (itemToUpdate: ToUpdate) => {
      if (itemToUpdate) {
        return async (itemToUpdate: ToUpdate) => {
          await updateQuantity({ variables: { input: { toUpdate: itemToUpdate } } });
        };
      }
    },
    [updateQuantity]
  );

  const totalItemsCapacity = sum(
    validItems.map(
      (orderItem) =>
        orderItem.products[0] &&
        Number(orderItem.quantityOrdered) * Number(orderItem.products[0]?.cubicFeet)
    )
  );

  const quantityOrdered = validItems.reduce(
    (total, item) => total + (item.quantityOrdered || 0),
    0
  );

  const columns: ColumnsType<PurchaseOrderItem> = useMemo(
    () => [
      {
        dataIndex: 'productWarning',
        width: '44px',
        render: (_, item) => (
          <>
            {!!item.purchaseOrderItemWarnings.length && (
              <div data-testid="warning-display">
                <AlloyTooltip
                  data-testid="warning-display-tooltip"
                  placement="right"
                  title={item.purchaseOrderItemWarnings
                    .map((warning) => POI_WARNING_MAP[warning] || warning)
                    .join(', ')}
                >
                  <img data-testid="warning-icon" src={errorIcon} alt="" />
                </AlloyTooltip>
              </div>
            )}
          </>
        )
      },
      {
        title: 'GTIN',
        key: 'gtin',
        width: '160px',
        render: (_, item) => item.catalogProduct?.gtin12 ?? item.product?.upc,
        sorter: (a, b) => {
          if (a?.catalogProduct) {
            return safeLocaleCompare(a?.catalogProduct?.gtin12, b?.catalogProduct?.gtin12);
          } else {
            return safeLocaleCompare(a?.product?.upc, b?.product?.upc);
          }
        }
      },
      {
        title: 'EXTERNAL ID',
        dataIndex: 'externalId',
        width: '130px',
        render: (_, item) => {
          const hasErrors =
            item?.purchaseOrderItemErrors && item.purchaseOrderItemErrors.length > 0;

          return (
            <div className={!hasErrors ? s.externalId : s.externalId_with_error}>
              {item.externalId}
            </div>
          );
        },
        sorter: (a, b) => safeLocaleCompare(a?.externalId, b?.externalId)
      },
      {
        title: 'ITEM DESCRIPTION',
        key: 'productName',
        render: (_, item) => item.catalogProduct?.name ?? item.product?.name,
        sorter: (a, b) => {
          if (a?.catalogProduct) {
            return safeLocaleCompare(a?.catalogProduct?.name, b?.catalogProduct?.name);
          } else {
            return safeLocaleCompare(a?.product?.name, b?.product?.name);
          }
        }
      },
      {
        title: 'BU',
        dataIndex: 'businessUnit',
        width: '100px',
        render: () => businessUnit?.code
      },
      {
        title: 'ORDER QTY',
        key: 'quantityOrdered',
        width: '103px',
        align: 'left' as AlignType,
        render: (_, item) => {
          if (isConditionalColumnsEnabled) {
            const hasWarning =
              item.purchaseOrderItemWarnings && item.purchaseOrderItemWarnings.length > 0;
            return [
              PO_STATUS_NEW,
              PO_STATUS_EXPORTED,
              PO_STATUS_SCRUBBED,
              PO_STATUS_ACKNOWLEDGED
            ].includes((statuses?.primary || '').toUpperCase()) ? (
              <div className={item?.vendorProduct && !hasWarning ? '' : s.item_error}>
                <PurchaseOrderQuantityUpdate
                  updatePurchaseOrderSingleQuantity={updatePurchaseOrderSingleQuantity}
                  itemId={item.id}
                  productId={item.product?.id || ''}
                  quantity={
                    item.quantityOrdered !== null ? (item.quantityOrdered || 0).toString() : '0'
                  }
                  unit={item?.submittedUnitOfMeasure || ''}
                />
              </div>
            ) : item.quantityOrdered != null ? (
              `${item.quantityOrdered} ${item?.submittedUnitOfMeasure}`
            ) : (
              0
            );
          } else if (item.quantityOrdered != null) {
            return `${item.quantityOrdered} ${item?.submittedUnitOfMeasure}`;
          } else {
            return 0;
          }
        }
      },
      {
        width: '46px',
        render: () => <div>{''}</div>
      }
    ],
    [
      businessUnit?.code,
      isConditionalColumnsEnabled,
      statuses?.primary,
      updatePurchaseOrderSingleQuantity
    ]
  );

  const updatedColumns = useMemo(() => {
    if (isConditionalColumnsEnabled) {
      columns.splice(5, 0, {
        title: (
          <>
            <div>VOLUME</div>
            <span className={s.subtitle}>
              MAX: {Number(capacityCubicFeetBoh).toFixed(2) || '-'}
            </span>
          </>
        ),
        key: 'capacity',
        width: '110px',
        render: (_, { products, quantityOrdered }) => {
          const volume = (Number(products[0]?.cubicFeet) * (quantityOrdered || 0)).toFixed(2);
          return `${products[0]?.cubicFeet === undefined ? '-' : volume} CFT`;
        }
      });

      columns.splice(7, 0, {
        title: 'NO. OF PALLETS',
        key: 'numberOfPallets',
        width: '100px',
        render: (_, item) => {
          const casesPerPallet =
            item.catalogProduct?.casesPerPallet ?? item?.product?.quantityPerPallet;
          return (item?.quantityOrdered || 0) > 0 && casesPerPallet
            ? ((item?.quantityOrdered || 0) / casesPerPallet).toFixed(1)
            : 0;
        }
      });
    }
    return columns;
  }, [capacityCubicFeetBoh, columns, isConditionalColumnsEnabled]);

  return (
    <>
      <div data-testid="po-items-count-dsd" className={s.quantity_display}>
        PO ITEMS ({validItems.length})
      </div>
      <AlloyTable
        data-testid="po_items_table-dsd"
        className={s.table}
        loading={loading}
        dataSource={validItems}
        columns={updatedColumns}
        rowKey={(item) => item.id}
        sticky={true}
        tableLayout="auto"
        pagination={false}
        summary={() => {
          return (
            <Summary.Row>
              <Summary.Cell className={s.bold} index={0} colSpan={5} align="right">
                Total
              </Summary.Cell>
              {!isConditionalColumnsEnabled ? null : (
                <Summary.Cell className={s.bold} index={1}>
                  <div>
                    {totalItemsCapacity?.toFixed(2)} CFT
                    {capacityCubicFeetBoh && totalItemsCapacity > Number(capacityCubicFeetBoh) && (
                      <span>
                        <AlloyTooltip title="Order volume exceeds location capacity">
                          <ExclamationCircleFilled />
                        </AlloyTooltip>
                      </span>
                    )}
                  </div>
                </Summary.Cell>
              )}
              <Summary.Cell className={s.bold} index={2}>
                {quantityOrdered} {validItems[0]?.submittedUnitOfMeasure}
              </Summary.Cell>
              {!isConditionalColumnsEnabled ? null : <Summary.Cell className={s.bold} index={3} />}
              <Summary.Cell className={s.bold} index={6} colSpan={2} />
            </Summary.Row>
          );
        }}
      />
    </>
  );
};
