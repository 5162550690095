import React, { useState } from 'react';
import { CustomizationForm } from 'pages/DistributionCentersPage/components/forms/CustomizationForm/CustomizationForm';
import { GeneralSettingsForm } from 'pages/DistributionCentersPage/components/forms/GeneralSettingsForm/GeneralSettingsForm';
import { IntegrationsForm } from 'pages/DistributionCentersPage/components/forms/IntegrationsForm/IntegrationsForm';
import { useForm } from 'react-final-form';
import { FullScreenEditingModal } from 'components/Common/FullScreenEditingModal/FullScreenEditingModal';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { AlloyTabs } from 'components/ui/AlloyTabs/AlloyTabs';
import { AlloySpin } from 'components/ui/AlloySpin/AlloySpin';

interface EditingDistributionCenterTabsProps {
  onClose: () => void;
  upserting: boolean;
  visible: boolean;
  isDCFacilityManagementEnabled: boolean | undefined;
}

export const EditingDistributionCenterTabs = ({
  onClose,
  upserting,
  visible,
  isDCFacilityManagementEnabled
}: EditingDistributionCenterTabsProps) => {
  const [activeTab, setActiveTab] = useState('general');

  const form = useForm();

  const handleClose = () => {
    setActiveTab('general');
    onClose();
  };

  const tabs = [
    {
      key: 'general',
      label: <span data-testid="editing-dc-general-tab">General Settings</span>,
      children: (
        <GeneralSettingsForm
          upserting={upserting}
          isDCFacilityManagementEnabled={isDCFacilityManagementEnabled}
        />
      )
    },
    {
      key: 'integrations',
      label: <span data-testid="editing-dc-integrations-tab">Integrations</span>,
      children: (
        <IntegrationsForm
          upserting={upserting}
          setUseConfig={(integrationName, value) => {
            switch (integrationName) {
              case 'sftpConfig':
                form.mutators.useSftpConfig(value);
                break;
              case 's3Config':
                form.mutators.useS3Config(value);
                break;
            }
          }}
        />
      )
    },
    {
      key: 'customization',
      label: <span data-testid="editing-dc-customization-tab">Customization</span>,
      children: <CustomizationForm upserting={upserting} />
    }
  ];

  return (
    <AlloySpin spinning={upserting}>
      <FullScreenEditingModal
        title={`${form.getState().values['id'] ? 'Edit' : 'Add'} Distribution Center`}
        open={visible}
        onClose={handleClose}
        buttons={
          <>
            <AlloyButton onClick={() => handleClose()} data-testid="editing-dc-cancel-button">
              Cancel
            </AlloyButton>
            <AlloyButton
              type="primary"
              data-testid="editing-dc-save-button"
              onClick={() => {
                document
                  ?.getElementById('edit-distribution-center-modal-form')
                  ?.dispatchEvent(new Event('submit', { cancelable: true }));
              }}
            >
              Save
            </AlloyButton>
          </>
        }
      >
        <AlloyTabs
          activeKey={activeTab}
          onChange={(activeKey) => setActiveTab(activeKey)}
          items={tabs}
        />
      </FullScreenEditingModal>
    </AlloySpin>
  );
};
