import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/browser';
import { App } from 'ant5';
import { AlloySelect } from 'components/ui/AlloySelect/AlloySelect';
import { SALES_ORDER_ITEM_CUT_REASONS, PO_STATUS_SUBMITTED } from 'common/constants';
import { PurchaseOrderWithOverdue, SalesOrderItem } from 'pages/OrderDetailsPage/types';
import { UpdateSalesOrderItemCutReasonDocument } from './gql/__generated__/updateSalesOrderItemCutReason.mutation';
import { notEmpty } from 'common/helpers/notEmpty';

interface CutReasonDropdownProps {
  item: SalesOrderItem;
  includesAllMatchingItems?: boolean;
  purchaseOrder: PurchaseOrderWithOverdue;
}

//TODO: WHEN CUT SUMMARY QUERY IS COMPLETE, WE WILL REPLACE salesOrderItem.comment WITH cutSummaries.map((cs) => cs.reason);

const CutReasonDropdown = ({
  item,
  purchaseOrder,
  includesAllMatchingItems
}: CutReasonDropdownProps) => {
  const { notification } = App.useApp();

  const [saving, setSaving] = useState(false); //do we need this with new storybook component?
  const [updateSalesOrderItem] = useMutation(UpdateSalesOrderItemCutReasonDocument, {});

  const updateItemComment = async (comment: string) => {
    const originalValue = item.comment;

    const salesOrderItems = includesAllMatchingItems
      ? purchaseOrder.salesOrders
          .map((salesOrder) => {
            return salesOrder.salesOrderItems.find(
              (soItem) => soItem.externalId === item.externalId
            );
          })
          .filter((soItem) => !!soItem)
      : [item];

    setSaving(true); //do we need this with new storybook component? this is for loading when updating cut reason

    try {
      await Promise.all(
        salesOrderItems.filter(notEmpty).map((salesOrderItem) => {
          salesOrderItem.comment = comment;
          item.comment = comment;
          return updateSalesOrderItem({
            variables: {
              input: {
                id: salesOrderItem.id,
                comment: comment,
                productId: salesOrderItem?.product?.id,
                catalogItemId: salesOrderItem?.catalogProduct?.id
              }
            }
          }).catch((e) => {
            salesOrderItem.comment = originalValue;
            throw e;
          });
        })
      );
    } catch (error) {
      notification.error({
        message: 'Failed to update the cut reason',
        description: 'Unable to update the sales order item cut reason'
      });
      Sentry.captureException(error);
    }
    setSaving(false);
  };

  return (
    <AlloySelect
      data-testid="item-cut-reason-dropdown"
      style={{ width: 200 }}
      dropdownStyle={{ width: 200 }}
      placeholder="Select classification"
      loading={saving}
      allowClear={!!item.comment}
      onClear={() => {
        if (item.comment) updateItemComment('');
      }}
      value={item.comment}
      disabled={(purchaseOrder.statuses?.primary || '').toUpperCase() === PO_STATUS_SUBMITTED} //TODO: confirm if this is the only status in which we want to disable this feature
      onChange={(comment) => updateItemComment(comment)}
      options={SALES_ORDER_ITEM_CUT_REASONS.map((option) => ({
        key: option,
        value: option,
        title: option,
        'data-testid': `item-cut-reason-option-${option}`
      }))}
    />
  );
};

export default CutReasonDropdown;
