import React, { useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  FiscalCalendarWeekInputWithWeekOnly,
  getCurrentPeriod
} from 'components/PeriodCalendar/helpers';
import { convertToMM } from 'common/helpers/convertToMM';
import { TotalWithGraph } from '../TotalWithGraph/TotalWithGraph';
import { ChargebackReportsByPeriodDocument } from './gql/__generated__/chargebackReportsByPeriod.query';
import { convertWeekToPeriodWeekString } from 'common/helpers/fiscalCalendar';
import { getPreviousWeekAndYear } from 'common/helpers/periodHelper';
import { AlloySpin } from 'components/ui/AlloySpin/AlloySpin';
import { ChargebackGroupType, prepareChargebacksData } from 'pages/ExecutiveIntelligence/helpers';
import s from './Chargebacks.module.scss';
import { AlloySelect } from 'components/ui/AlloySelect/AlloySelect';
import { notEmpty } from 'common/helpers/notEmpty';
import { ExecutiveReportingFilters } from 'graphql/__generated__/types';

// Backend name
const TOTAL = '__TOTAL__';

const currentPeriod = getCurrentPeriod(true);

// We want to compare PREVIOUS week to PREVIOUS PREVIOUS week, since CURRENT week has no data
const actualPeriod = getPreviousWeekAndYear(currentPeriod.week, currentPeriod.year);
const comparisonPeriod = getPreviousWeekAndYear(actualPeriod.week, actualPeriod.year);
const shouldShowChange = actualPeriod.week !== comparisonPeriod.week;

const chargebackSelectOptions: {
  value: ChargebackGroupType;
  label: string;
}[] = [
  {
    label: 'Net',
    value: 'net'
  },
  {
    label: 'Gross',
    value: 'gross'
  },
  {
    label: 'Waived / Approved',
    value: 'waived/approved'
  },
  {
    label: 'Pending',
    value: 'pending'
  }
];

export const Chargebacks = ({
  fiscalCalendarWeeks,
  executiveReportingFilters
}: {
  fiscalCalendarWeeks: FiscalCalendarWeekInputWithWeekOnly[];
  executiveReportingFilters: ExecutiveReportingFilters;
}) => {
  const [mode, setMode] = useState<ChargebackGroupType>('net');

  const period = useQuery(ChargebackReportsByPeriodDocument, {
    variables: {
      filters: executiveReportingFilters,
      dimensions: ['BUSINESS_UNIT', 'STATUS']
    }
  });

  const businessUnits = useMemo(() => {
    return [
      ...new Set([
        TOTAL,
        ...(period.data?.chargebackDetailsReportsByPeriod || [])
          .map((x) => x.businessUnit)
          .filter(notEmpty)
      ])
    ];
  }, [period.data?.chargebackDetailsReportsByPeriod]);

  if (period.error)
    return (
      <div style={{ gridColumn: 'span 12' }}>
        Could not load chargebacks data due to a server error
      </div>
    );
  if (period.loading && !period.data)
    return (
      <div
        style={{
          height: '360px',
          gridColumn: 'span 12',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <AlloySpin />
      </div>
    );

  return (
    <>
      {businessUnits.map((bu) => {
        const filteredData = (period.data?.chargebackDetailsReportsByPeriod || []).filter(
          (x) => bu === TOTAL || x.businessUnit === bu
        );
        const { total, graphData, isContinious, change } = prepareChargebacksData({
          data: filteredData,
          actualPeriod,
          comparisonPeriod,
          shouldShowChange,
          mode,
          fiscalCalendarWeeks
        });

        return (
          <TotalWithGraph
            key={bu}
            change={change}
            changeText="than prev. period"
            changeTooltip={`In ${convertWeekToPeriodWeekString(
              actualPeriod.week
            )} compared to ${convertWeekToPeriodWeekString(comparisonPeriod.week)}`}
            title={
              bu === TOTAL ? (
                <span>
                  <AlloySelect
                    value={mode}
                    onChange={(value) => {
                      setMode(value);
                    }}
                    variant="borderless"
                    dropdownStyle={{ minWidth: '155px', fontWeight: 'bold' }}
                    options={chargebackSelectOptions}
                    className={s.borderlessSelect}
                  />
                  Chargebacks
                </span>
              ) : (
                bu || ''
              )
            }
            loading={period.loading}
            total={convertToMM(total)}
            totalTooltip={`$${total.toLocaleString('en-US')}`}
            totalPosition={bu === TOTAL ? 'left' : 'top'}
            width={bu === TOTAL ? 12 : 3}
            graph={{
              loading: period.loading,
              data: graphData.map((x) => ({
                value: isNaN(x.value) ? undefined : x.value,
                label: convertWeekToPeriodWeekString(x.fiscalCalendarWeek.week)
              })),
              isContinious,
              valueTickFormatter: (value) => convertToMM(value),
              valueTooltipFormatter: (value) => `$${value.toLocaleString('en-US')}`
            }}
          />
        );
      })}
    </>
  );
};
