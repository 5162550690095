import { PageHeader } from 'components/ui/PageHeader/PageHeader';
import { AlloySpin } from 'components/ui/AlloySpin/AlloySpin';
import React from 'react';
import s from './WarehouseTruckBuilderPage.module.scss';
import { AlloySegmented } from 'components/ui/AlloySegmented/AlloySegmented';
import { JsonParam, useQueryParam, withDefault } from 'use-query-params';
import WhsTruckBuilderOrders from './OrdersView/TruckBuilderOrdersView';
import { TruckloadsView } from './TruckloadsView/TruckloadsView';
import { CompletedView } from './CompletedView/CompletedView';

export type DisplayMode = 'orders' | 'truckloads' | 'completed';
const DEFAULT_TAB: DisplayMode = 'orders';

type WarehouseTruckBuilderTab = {
  value: DisplayMode;
  label: React.ReactNode;
  disabled?: boolean;
};
const ORDERS_TAB: WarehouseTruckBuilderTab = {
  value: 'orders',
  label: 'Orders',
  disabled: false
};
const TRUCKLOADS_TAB: WarehouseTruckBuilderTab = {
  value: 'truckloads',
  label: 'Truckloads',
  disabled: false
};
const COMPLETED_TAB: WarehouseTruckBuilderTab = {
  value: 'completed',
  label: 'Completed',
  disabled: false
};
const tabs = [ORDERS_TAB, TRUCKLOADS_TAB, COMPLETED_TAB];

const WarehouseTruckBuilderPage: React.FC = () => {
  const [mode, setMode] = useQueryParam<DisplayMode>('mode', withDefault(JsonParam, DEFAULT_TAB));

  return (
    <AlloySpin spinning={false}>
      <div className={s.header}>
        <div data-testid="page-title" className={s.page_title}>
          <PageHeader className={s.page_title}>Warehouse Truck Builder</PageHeader>
        </div>
      </div>

      <div className={s.page_toggle}>
        <AlloySegmented
          options={tabs}
          value={mode as DisplayMode}
          onChange={(value: DisplayMode) => setMode(value)}
        />
      </div>

      {mode === 'orders' && <WhsTruckBuilderOrders />}
      {mode === 'truckloads' && <TruckloadsView />}
      {mode === 'completed' && <CompletedView />}
    </AlloySpin>
  );
};

export default WarehouseTruckBuilderPage;
