import * as Types from '../../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type SendWarehouseTruckloadsToPlceMutationVariables = Types.Exact<{
  input: Types.SendWarehouseTruckloadsToPlceInput;
}>;


export type SendWarehouseTruckloadsToPlceMutation = { __typename?: 'RootMutationType', sendWarehouseTruckloadsToPlce?: { __typename?: 'SendWarehouseTruckloadsToPlcePayload', message: string, updatedTruckloads: number } | null };


export const SendWarehouseTruckloadsToPlceDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"sendWarehouseTruckloadsToPlce"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SendWarehouseTruckloadsToPlceInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"sendWarehouseTruckloadsToPlce"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"message"}},{"kind":"Field","name":{"kind":"Name","value":"updatedTruckloads"}}]}}]}}]} as unknown as DocumentNode<SendWarehouseTruckloadsToPlceMutation, SendWarehouseTruckloadsToPlceMutationVariables>;