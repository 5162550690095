import * as Types from '../../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type WhseTruckBuilderBuildTruckMutationVariables = Types.Exact<{
  input: Types.CreateWarehouseTruckloadInput;
}>;


export type WhseTruckBuilderBuildTruckMutation = { __typename?: 'RootMutationType', createWarehouseTruckload?: { __typename?: 'CreateWarehouseTruckloadPayload', message: string, ordersInTruck: number } | null };


export const WhseTruckBuilderBuildTruckDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"whseTruckBuilderBuildTruck"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateWarehouseTruckloadInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createWarehouseTruckload"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"message"}},{"kind":"Field","name":{"kind":"Name","value":"ordersInTruck"}}]}}]}}]} as unknown as DocumentNode<WhseTruckBuilderBuildTruckMutation, WhseTruckBuilderBuildTruckMutationVariables>;