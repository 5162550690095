import * as Types from '../../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type RemoveWarehouseTruckloadsMutationVariables = Types.Exact<{
  input: Types.RemoveWarehouseTruckloadsInput;
}>;


export type RemoveWarehouseTruckloadsMutation = { __typename?: 'RootMutationType', removeWarehouseTruckloads?: { __typename?: 'RemoveWarehouseTruckloadsPayload', message: string, removedTruckloads: number } | null };


export const RemoveWarehouseTruckloadsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"removeWarehouseTruckloads"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"RemoveWarehouseTruckloadsInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"removeWarehouseTruckloads"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"message"}},{"kind":"Field","name":{"kind":"Name","value":"removedTruckloads"}}]}}]}}]} as unknown as DocumentNode<RemoveWarehouseTruckloadsMutation, RemoveWarehouseTruckloadsMutationVariables>;