import React, { useEffect, useRef, useState } from 'react';
import { ApolloClient, useQuery } from '@apollo/client';
import s from './StoDelivery.module.scss';
import { DEFAULT_PAGE_SIZE, EMPTY } from '@/src/common/constants';
import { AlloyTable, SorterResult } from 'components/ui/AlloyTable/AlloyTable';
import { PageHeader } from 'components/ui/PageHeader/PageHeader';
import { AlloyRangePicker } from 'components/ui/AlloyDatePicker/AlloyDatePicker';
import moment from 'moment';
import { ArrayParam, NumberParam, StringParam, useQueryParam, withDefault } from 'use-query-params';
import clsx from 'clsx';
import { CustomStatsTile, StatsButton } from '@/src/components/StatsTile/StatsTile';
import {
  LateStockTransferOrderReportsDocument,
  LateStockTransferOrderReportsQuery,
  LateStockTransferOrderReportsQueryVariables
} from '@/src/pages/StoDeliveryPage/gql/__generated__/lateStockTransferOrderReports.query';
import { SearchOutlined } from '@ant-design/icons';
import { LateStockTransferOrderReportsTotalsDocument } from '@/src/pages/StoDeliveryPage/gql/__generated__/lateStockTransferOrderReportsTotals.query';
import {
  LateStoSortColumn,
  SortOrderDirection,
  StoLateness,
  StoSapShippingStatus
} from '@/src/graphql/__generated__/types';
import { getNodesFromEdges, InferNodeType } from '@/src/common/helpers/mappingHelper';
import { Paginator } from '@/src/components/Paginator/Paginator';
import { AllStoDashboardDistributionCentersDocument } from '@/src/pages/StoDeliveryPage/gql/__generated__/allStoDashboardDistributionCenters.query';
import { notEmpty } from '@/src/common/helpers/notEmpty';
import {
  ColumnsWithExportRender,
  prepareSingleRecordForXLSXExport
} from '@/src/common/helpers/tableExportAlloy';
import { utils as XLSXutils, writeFileXLSX } from '@/src/non_npm_dependencies/xlsx';
import { SortingType } from '@/src/common/types';
import { useDeepCompareMemo } from 'use-deep-compare';
import { AlloyButton } from '@/src/components/ui/AlloyButton/AlloyButton';
import { AlloySelect } from '@/src/components/ui/AlloySelect/AlloySelect';
import { AlloyInput, InputRef } from '@/src/components/ui/AlloyInput/AlloyInput';
import { setArrayWithAll } from '@/src/common/helpers/selectAll';
import { AlloySpin } from '@/src/components/ui/AlloySpin/AlloySpin';
import { AlloyRow } from '@/src/components/ui/AlloyRow/AlloyRow';
import { AlloyCol } from '@/src/components/ui/AlloyCol/AlloyCol';
import { AlloyModal } from '@/src/components/ui/AlloyModal/AlloyModal';
import { AlloyTag } from '@/src/components/ui/AlloyTag/AlloyTag';
import { CustomTagProps } from 'rc-select/lib/BaseSelect';

const DATE_FORMAT = 'MM-DD-YYYY';

const TODAY = moment().format(DATE_FORMAT);

const humanReadableDeliveryStatuses: Record<StoSapShippingStatus, string> = {
  PLND: 'Planned',
  CHKIN: 'Checked In',
  LDSRT: 'Start Loading',
  LDEND: 'End Loading',
  SHSRT: 'Start Shipping',
  SHCOMP: 'Complete Shipping',
  SHEND: 'End Shipping'
};

const deliveryStatusList: Record<string, StoSapShippingStatus[]> = {
  'At Origin': ['PLND', 'CHKIN', 'LDSRT', 'LDEND'],
  'In Transit': ['SHSRT'],
  'At Destination': ['SHCOMP', 'SHEND']
};

type StockTransferOrder = InferNodeType<
  LateStockTransferOrderReportsQuery,
  'lateStockTransferOrderReports'
>;

const prepareOrdersForExport = async (
  client: ApolloClient<object>,
  filters: LateStockTransferOrderReportsQueryVariables,
  columns: ColumnsWithExportRender<StockTransferOrder>
) => {
  const ordersWithDetails = await client.query({
    query: LateStockTransferOrderReportsDocument,
    variables: { ...filters, first: 5000 }
  });

  return getNodesFromEdges(ordersWithDetails?.data?.lateStockTransferOrderReports)
    .filter(notEmpty)
    .map((x) => ({
      ...prepareSingleRecordForXLSXExport(x, columns)
    }));
};

const StoDeliveryPage = () => {
  const [exportModalVisible, setExportModalVisible] = useState(false);
  const [startDate, setStartDate] = useQueryParam(
    'startDate',
    withDefault(StringParam, '6-1-2024')
  );
  const [endDate, setEndDate] = useQueryParam('endDate', withDefault(StringParam, TODAY));

  const [sortColumn, setSortColumn] = useQueryParam(
    'sortColumn',
    withDefault(StringParam, 'REQUESTED_DELIVERY_DATE')
  );
  const [sortDirection, setSortDirection] = useQueryParam(
    'sortDirection',
    withDefault(StringParam, 'DESC')
  );

  const inputRef = useRef<InputRef>(null);
  const [searchTerm, setSearchTerm] = useQueryParam('search', withDefault(StringParam, undefined));
  const [inputValue, setInputValue] = useState<string>('');

  const [lateness, setLateness] = useQueryParam('lateness', withDefault(StringParam, undefined));

  const [before, setBefore] = useQueryParam('before', withDefault(StringParam, undefined));
  const [after, setAfter] = useQueryParam('after', withDefault(StringParam, undefined));

  const [pageLimit, setPageLimit] = useQueryParam(
    'limit',
    withDefault(NumberParam, DEFAULT_PAGE_SIZE)
  );

  const [shipmentStatusFilter, setShipmentStatusFilter] = useQueryParam(
    'shipmentStatus',
    withDefault(StringParam, 'all')
  );

  const [originDcFilter, setOriginDcFilter] = useQueryParam(
    'originDc',
    withDefault(ArrayParam, ['all'])
  );
  const [destinationDcFilter, setDestinationDcFilter] = useQueryParam(
    'destinationDc',
    withDefault(ArrayParam, ['all'])
  );

  const handlePageSizeChange = async (value: number) => {
    setPageLimit(value);
    window.scrollTo(0, 0);
  };

  const nextPage = async () => {
    setAfter(stockTransferOrders?.lateStockTransferOrderReports?.pageInfo.endCursor);
    setBefore(undefined);
    window.scrollTo(0, 0);
  };

  const prevPage = async () => {
    setAfter(undefined);
    setBefore(stockTransferOrders?.lateStockTransferOrderReports?.pageInfo.startCursor);
    window.scrollTo(0, 0);
  };

  const filters = useDeepCompareMemo(
    () => ({
      lateness: lateness as StoLateness,
      shipmentStatus: (shipmentStatusFilter === 'all'
        ? undefined
        : deliveryStatusList[shipmentStatusFilter]) as StoSapShippingStatus[],
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD'),
      originCode: originDcFilter.includes('all') ? undefined : originDcFilter,
      destinationCode: destinationDcFilter.includes('all') ? undefined : destinationDcFilter,
      smartSearch: searchTerm,
      sort: {
        column: sortColumn as LateStoSortColumn,
        direction: sortDirection as SortOrderDirection
      }
    }),
    [
      lateness,
      shipmentStatusFilter,
      startDate,
      endDate,
      originDcFilter,
      destinationDcFilter,
      searchTerm,
      sortColumn,
      sortDirection
    ]
  );

  const filtersWithPagination = {
    ...filters,
    before: before,
    after: after,
    first: after || !before ? pageLimit : undefined,
    last: !after && before ? pageLimit : undefined
  };

  const totalsQueryFilters = {
    shipmentStatus: filters.shipmentStatus,
    smartSearch: filters.smartSearch,
    startDate: filters.startDate,
    endDate: filters.endDate,
    originCode: filters.originCode,
    destinationCode: filters.destinationCode
  };

  const isFiltersOn: boolean =
    !!filters.lateness ||
    !!filters.shipmentStatus ||
    !!filters.originCode ||
    !!filters.destinationCode ||
    !!filters.smartSearch;

  const { data: stoDistributionCenters, loading: stoDcLoading } = useQuery(
    AllStoDashboardDistributionCentersDocument,
    {
      variables: {
        destinationCode: destinationDcFilter.includes('all') ? undefined : destinationDcFilter,
        originCode: originDcFilter.includes('all') ? undefined : originDcFilter
      }
    }
  );

  const originDcs = stoDistributionCenters?.allStoDashboardDistributionCenters?.originCodes || [];
  const destinationDcs =
    stoDistributionCenters?.allStoDashboardDistributionCenters?.destinationCodes || [];

  const {
    data: stockTransferOrders,
    loading,
    client,
    error
  } = useQuery(LateStockTransferOrderReportsDocument, {
    variables: filtersWithPagination
  });

  const { data: totalsData, loading: totalsLoading } = useQuery(
    LateStockTransferOrderReportsTotalsDocument,
    {
      variables: totalsQueryFilters
    }
  );

  const stockTransferOrdersData = getNodesFromEdges(
    stockTransferOrders?.lateStockTransferOrderReports
  );

  const [exportAsync, setExportAsync] = useState<{
    loading: boolean;
    error: boolean;
  }>({ loading: false, error: false });

  const exportViewData = async () => {
    try {
      setExportAsync({ loading: true, error: false });
      const ordersForExport = await prepareOrdersForExport(client, filters, columns);
      const filename = `STO_${moment().format('MM-DD-YYYY_hh-mm-ss_A')}.xlsx`;
      const worksheet = XLSXutils.json_to_sheet(ordersForExport);
      const workbook = XLSXutils.book_new();
      XLSXutils.book_append_sheet(workbook, worksheet, 'STO Delivery status');
      writeFileXLSX(workbook, filename, { compression: true });

      setExportModalVisible(false);
      setExportAsync({
        loading: false,
        error: false
      });
    } catch {
      setExportAsync({ loading: false, error: true });
    }
  };

  useEffect(() => {
    setAfter(undefined);
    setBefore(undefined);
  }, [filters]);

  const getSortOrder = (columnKey: string) =>
    columnKey === sortColumn
      ? ((sortDirection === 'ASC' ? 'ascend' : 'descend') as SortingType)
      : undefined;

  const columns: ColumnsWithExportRender<StockTransferOrder> = [
    {
      title: 'Order Number',
      key: 'ORDER_NUMBER',
      sortOrder: getSortOrder('ORDER_NUMBER'),
      sorter: true,
      exportConfig: {
        render: ({ orderNumber }) => orderNumber || EMPTY
      },
      render: (_, { orderNumber }) => orderNumber || EMPTY
    },
    {
      title: 'RSD',
      key: 'REQUESTED_SHIP_DATE',
      sortOrder: getSortOrder('REQUESTED_SHIP_DATE'),
      sorter: true,
      exportConfig: {
        render: ({ requestedShipDate }) => requestedShipDate || EMPTY
      },
      onCell: ({ requestedShipDate, shipmentStatus, requestedDeliveryDate, shipDate }) => {
        return {
          className: clsx({
            [s.yellow_cell]:
              moment(TODAY).isAfter(requestedShipDate) &&
              moment(TODAY).isBefore(requestedDeliveryDate) &&
              (shipmentStatus === 'PLND' ||
                shipmentStatus === 'CHKIN' ||
                shipmentStatus === 'LDSRT' ||
                shipmentStatus === 'LDEND') &&
              (moment(TODAY).isAfter(requestedShipDate) ||
                moment(shipDate).isAfter(requestedShipDate)),
            [s.red_cell]:
              moment(TODAY).isAfter(requestedShipDate) &&
              moment(TODAY).isBefore(requestedDeliveryDate) &&
              (shipmentStatus === 'PLND' ||
                shipmentStatus === 'CHKIN' ||
                shipmentStatus === 'LDSRT' ||
                shipmentStatus === 'LDEND') &&
              ((moment(TODAY).isAfter(requestedShipDate) &&
                moment(TODAY).diff(requestedShipDate, 'days') >= 10) ||
                (moment(shipDate).isAfter(requestedShipDate) &&
                  moment(shipDate).diff(requestedShipDate, 'days') >= 10))
          })
        };
      },
      render: (_, { requestedShipDate }) =>
        requestedShipDate ? moment(requestedShipDate).format('ll') : EMPTY
    },
    {
      title: 'RDD',
      key: 'REQUESTED_DELIVERY_DATE',
      sortOrder: getSortOrder('REQUESTED_DELIVERY_DATE'),
      sorter: true,
      exportConfig: {
        render: ({ requestedDeliveryDate }) => requestedDeliveryDate || EMPTY
      },
      render: (_, { requestedDeliveryDate }) =>
        requestedDeliveryDate ? moment(requestedDeliveryDate).format('ll') : EMPTY
    },
    {
      title: 'Ship Date',
      key: 'SHIP_DATE',
      sortOrder: getSortOrder('SHIP_DATE'),
      sorter: true,
      exportConfig: {
        render: ({ shipDate }) => shipDate || EMPTY
      },
      onCell: ({ shipDate, requestedShipDate, shipmentStatus, requestedDeliveryDate }) => {
        return {
          className: clsx({
            [s.yellow_cell]:
              (moment(TODAY).isSameOrBefore(requestedShipDate) &&
                moment(shipDate).isAfter(requestedShipDate)) ||
              (moment(TODAY).isSameOrAfter(requestedDeliveryDate) &&
                (shipmentStatus === 'PLND' ||
                  shipmentStatus === 'CHKIN' ||
                  shipmentStatus === 'LDSRT' ||
                  shipmentStatus === 'LDEND') &&
                (moment(TODAY).isAfter(requestedShipDate) ||
                  moment(shipDate).isAfter(requestedShipDate))),
            [s.red_cell]:
              (moment(TODAY).isSameOrBefore(moment(requestedShipDate)) &&
                moment(shipDate).isAfter(requestedShipDate) &&
                moment(shipDate).diff(requestedShipDate, 'days') >= 10) ||
              (moment(TODAY).isSameOrAfter(requestedDeliveryDate) &&
                (shipmentStatus === 'PLND' ||
                  shipmentStatus === 'CHKIN' ||
                  shipmentStatus === 'LDSRT' ||
                  shipmentStatus === 'LDEND') &&
                moment(TODAY).isAfter(requestedShipDate) &&
                moment(TODAY).diff(requestedShipDate, 'days') >= 10) ||
              (moment(shipDate).isAfter(requestedShipDate) &&
                moment(shipDate).diff(requestedShipDate, 'days') >= 10)
          })
        };
      },
      render: (_, { shipDate }) => (shipDate ? moment(shipDate).format('ll') : EMPTY)
    },
    {
      title: 'Delivery Date',
      key: 'DELIVERY_DATE',
      sortOrder: getSortOrder('DELIVERY_DATE'),
      sorter: true,
      exportConfig: {
        render: ({ deliveryDate }) => deliveryDate || EMPTY
      },
      onCell: ({ deliveryDate, requestedDeliveryDate, shipmentStatus, requestedShipDate }) => {
        return {
          className: clsx({
            [s.yellow_cell]:
              (moment(TODAY).isSameOrBefore(requestedShipDate) &&
                moment(deliveryDate).isAfter(requestedDeliveryDate)) ||
              (moment(TODAY).isAfter(requestedShipDate) &&
                moment(TODAY).isBefore(requestedDeliveryDate) &&
                shipmentStatus !== 'SHCOMP' &&
                shipmentStatus !== 'SHEND' &&
                moment(deliveryDate).isAfter(requestedDeliveryDate)) ||
              (moment(TODAY).isSameOrAfter(requestedDeliveryDate) &&
                (shipmentStatus === 'PLND' ||
                  shipmentStatus === 'CHKIN' ||
                  shipmentStatus === 'LDSRT' ||
                  shipmentStatus === 'LDEND' ||
                  shipmentStatus === 'SHSRT') &&
                (moment(TODAY).isAfter(requestedDeliveryDate) ||
                  moment(deliveryDate).isAfter(requestedDeliveryDate))),
            [s.red_cell]:
              (moment(TODAY).isSameOrBefore(requestedShipDate) &&
                moment(deliveryDate).isAfter(requestedDeliveryDate) &&
                moment(deliveryDate).diff(requestedDeliveryDate, 'days') >= 10) ||
              (moment(TODAY).isAfter(requestedShipDate) &&
                moment(TODAY).isBefore(requestedDeliveryDate) &&
                shipmentStatus !== 'SHCOMP' &&
                shipmentStatus !== 'SHEND' &&
                moment(deliveryDate).isAfter(requestedDeliveryDate) &&
                moment(deliveryDate).diff(requestedDeliveryDate, 'days') >= 10) ||
              (moment(TODAY).isSameOrAfter(requestedDeliveryDate) &&
                (shipmentStatus === 'PLND' ||
                  shipmentStatus === 'CHKIN' ||
                  shipmentStatus === 'LDSRT' ||
                  shipmentStatus === 'LDEND' ||
                  shipmentStatus === 'SHSRT') &&
                moment(TODAY).isAfter(requestedDeliveryDate) &&
                moment(TODAY).diff(requestedDeliveryDate, 'days') >= 10) ||
              (moment(deliveryDate).isAfter(requestedDeliveryDate) &&
                moment(deliveryDate).diff(requestedDeliveryDate, 'days') >= 10)
          })
        };
      },
      render: (_, { deliveryDate }) => (deliveryDate ? moment(deliveryDate).format('ll') : EMPTY)
    },
    {
      title: 'Ship Status',
      key: 'SHIPMENT_STATUS',
      sortOrder: getSortOrder('SHIPMENT_STATUS'),
      sorter: true,
      exportConfig: {
        render: ({ shipmentStatus }) => shipmentStatus || EMPTY
      },
      render: (_, { shipmentStatus }) =>
        shipmentStatus ? humanReadableDeliveryStatuses[shipmentStatus] : EMPTY
    },
    {
      title: 'Origin DC',
      key: 'ORIGIN_CODE',
      sortOrder: getSortOrder('ORIGIN_CODE'),
      sorter: true,
      exportConfig: {
        render: ({ originCode }) => (originCode && originCode[0]) || EMPTY
      },
      render: (_, { originCode }) => originCode || EMPTY
    },
    {
      title: 'Destination Dc',
      key: 'DESTINATION_CODE',
      sortOrder: getSortOrder('DESTINATION_CODE'),
      sorter: true,
      exportConfig: {
        render: ({ destinationCode }) => (destinationCode && destinationCode[0]) || EMPTY
      },
      render: (_, { destinationCode }) => destinationCode || EMPTY
    },
    {
      title: 'GPID',
      key: 'USER_GPID',
      sortOrder: getSortOrder('USER_GPID'),
      sorter: true,
      exportConfig: {
        render: ({ userGpid }) => userGpid || EMPTY
      },
      render: (_, { userGpid }) => userGpid || EMPTY
    }
  ];

  const setDefaultSorting = (latenessFilter: string | undefined) => {
    if (
      latenessFilter === 'ON_TIME' ||
      latenessFilter === 'AT_RISK' ||
      latenessFilter === 'HIGH_RISK'
    ) {
      setSortColumn('REQUESTED_DELIVERY_DATE');
      setSortDirection('ASC');
    } else {
      setSortColumn('REQUESTED_DELIVERY_DATE');
      setSortDirection('DESC');
    }
  };

  const tagRender = ({ label, closable, ...otherProps }: CustomTagProps) => {
    return label === 'All' ? (
      <AlloyTag closable={false} className={s.custom_tag_style} {...otherProps}>
        {label}
      </AlloyTag>
    ) : (
      <AlloyTag className={s.custom_tag_style} closable={closable} {...otherProps}>
        {label}
      </AlloyTag>
    );
  };

  return (
    <>
      <AlloyModal
        title="Export view"
        open={exportModalVisible}
        onCancel={() => setExportModalVisible(false)}
        width={400}
        destroyOnClose
        footer={
          <>
            <div className={s.modal_buttons}>
              <AlloyButton
                className={clsx('filled_grey_btn_no_border', s.bold)}
                size="large"
                onClick={() => setExportModalVisible(false)}
                data-testid="inventory-visibility-export-modal-close-button"
              >
                Cancel
              </AlloyButton>
              <AlloyButton
                className={s.export_modal_download_btn}
                size="large"
                style={{ width: 'auto' }}
                type="primary"
                onClick={() => exportViewData()}
                loading={exportAsync.loading}
                data-testid="inventory-visibility-export-modal-download-button"
                disabled={exportAsync.loading}
              >
                Download filtered
              </AlloyButton>
            </div>
            {exportAsync.error && (
              <p className={s.error}>Error occured while loading xlsx, try again later.</p>
            )}
          </>
        }
      >
        <AlloySpin spinning={exportAsync.loading}>
          <>
            <p>
              We will export filtered orders into an <code>.xlsx</code> file.
            </p>
          </>
        </AlloySpin>
      </AlloyModal>
      <div className={s.page}>
        <div className={s.header}>
          <PageHeader className={s.title}>STO Delivery status</PageHeader>
          <div className={s.page_filters}>
            <AlloyRangePicker
              data-testid="select-date-range-filter"
              allowClear
              value={[moment(startDate, DATE_FORMAT), moment(endDate, DATE_FORMAT)]}
              onChange={(dates) => {
                setStartDate(dates ? moment(dates[0]).format(DATE_FORMAT) : dates);
                setEndDate(dates ? moment(dates[1]).format(DATE_FORMAT) : dates);
              }}
              format={'ll'}
              placement="topRight"
            />

            <AlloyButton
              data-testid="export-inventory-button"
              onClick={() => setExportModalVisible(true)}
              size="large"
              disabled={!isFiltersOn}
              style={{ minWidth: '180px' }}
              className="filled_grey_btn_no_border"
            >
              Export
            </AlloyButton>
          </div>
        </div>
        <section className={s.stats}>
          <StatsButton
            title="All"
            count={totalsData?.lateStockTransferOrderReportsTotals?.all || 0}
            loading={totalsLoading}
            isActive={lateness === undefined}
            onClick={() => {
              setLateness(undefined);
              setDefaultSorting(undefined);
            }}
          />
          <StatsButton
            title="On-time"
            count={totalsData?.lateStockTransferOrderReportsTotals?.onTime || 0}
            loading={totalsLoading}
            isActive={lateness === 'ON_TIME'}
            onClick={() => {
              if (lateness === 'ON_TIME') {
                setLateness(undefined);
                setDefaultSorting(undefined);
              } else {
                setLateness('ON_TIME');
                setDefaultSorting('ON_TIME');
              }
            }}
          />
          <CustomStatsTile
            title="At risk"
            className={s.custom_stats_tile}
            isActive={lateness === 'AT_RISK' || lateness === 'HIGH_RISK'}
            content={
              <div className={s.double_stats_button}>
                <div
                  className={clsx(s.first_number, {
                    [s.tile_number_active]: lateness === 'AT_RISK'
                  })}
                  onClick={() => {
                    if (lateness === 'AT_RISK') {
                      setLateness(undefined);
                      setDefaultSorting(undefined);
                    } else {
                      setLateness('AT_RISK');
                      setDefaultSorting('AT_RISK');
                    }
                  }}
                >
                  {totalsData?.lateStockTransferOrderReportsTotals?.atRisk || 0}
                </div>{' '}
                |{' '}
                <div
                  className={clsx(s.second_number, {
                    [s.tile_number_active]: lateness === 'HIGH_RISK'
                  })}
                  onClick={() => {
                    if (lateness === 'HIGH_RISK') {
                      setLateness(undefined);
                      setDefaultSorting(undefined);
                    } else {
                      setLateness('HIGH_RISK');
                      setDefaultSorting('HIGH_RISK');
                    }
                  }}
                >
                  {totalsData?.lateStockTransferOrderReportsTotals?.highRisk || 0}
                </div>{' '}
                <span className={s.subtitle}>at sox</span>
              </div>
            }
            loading={totalsLoading}
          />
          <StatsButton
            title="Delivered"
            count={totalsData?.lateStockTransferOrderReportsTotals?.delivered || 0}
            loading={totalsLoading}
            isActive={lateness === 'DELIVERED'}
            onClick={() => {
              if (lateness === 'DELIVERED') {
                setLateness(undefined);
                setDefaultSorting(undefined);
              } else {
                setLateness('DELIVERED');
                setDefaultSorting('DELIVERED');
              }
            }}
          />
        </section>
        <AlloyRow className={s.filters}>
          <AlloyCol>
            <AlloyInput
              className={s.search_input}
              placeholder="Search by Order Number, GPID"
              ref={inputRef}
              onChange={(event) => {
                if (event.currentTarget.value) {
                  setInputValue(event.currentTarget.value);
                } else {
                  setInputValue(event.currentTarget.value);
                  setSearchTerm(undefined);
                }
              }}
              onPressEnter={() => {
                inputValue ? setSearchTerm(inputValue) : setSearchTerm(undefined);
              }}
              value={inputValue ? inputValue : searchTerm}
              allowClear={true}
              prefix={<SearchOutlined width="14px" height="14px" />}
            />
          </AlloyCol>
          <AlloyCol>
            <AlloyRow className={s.dropdown_filters}>
              <AlloyCol>
                <div className={s.filter_title}>Shipment status</div>
                <AlloySelect
                  className={s.select}
                  value={shipmentStatusFilter}
                  onChange={(value) => setShipmentStatusFilter(value)}
                  options={[
                    {
                      key: 'all',
                      value: 'all',
                      label: 'All'
                    },
                    {
                      key: 'At_Origin',
                      value: 'At Origin',
                      label: 'At Origin'
                    },
                    {
                      key: 'In_Transit',
                      value: 'In Transit',
                      label: 'In Transit'
                    },
                    {
                      key: 'At_Destination',
                      value: 'At Destination',
                      label: 'At Destination'
                    }
                  ]}
                />
              </AlloyCol>
              <AlloyCol>
                <div className={s.filter_title}>Origin DC</div>
                <AlloySelect
                  className={clsx(s.dc_select, s.select)}
                  value={originDcFilter}
                  loading={stoDcLoading}
                  maxTagCount={3}
                  mode="multiple"
                  allowClear
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().startsWith(input.toLowerCase())
                  }
                  tagRender={tagRender}
                  onChange={(value) =>
                    setArrayWithAll(
                      value as string[],
                      originDcFilter as string[],
                      setOriginDcFilter
                    )
                  }
                  options={[
                    {
                      key: 'all',
                      value: 'all',
                      label: 'All'
                    },
                    ...originDcs.map((originDistributionCenter) => ({
                      key: originDistributionCenter,
                      value: originDistributionCenter,
                      label: originDistributionCenter
                    }))
                  ]}
                />
              </AlloyCol>
              <AlloyCol>
                <div className={s.filter_title}>Destination DC</div>
                <AlloySelect
                  className={clsx(s.dc_select, s.select)}
                  value={destinationDcFilter}
                  loading={stoDcLoading}
                  maxTagCount={3}
                  mode="multiple"
                  allowClear
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().startsWith(input.toLowerCase())
                  }
                  tagRender={tagRender}
                  onChange={(value) =>
                    setArrayWithAll(
                      value as string[],
                      destinationDcFilter as string[],
                      setDestinationDcFilter
                    )
                  }
                  options={[
                    {
                      key: 'all',
                      value: 'all',
                      label: 'All'
                    },
                    ...destinationDcs.map((destinationDistributionCenter) => ({
                      key: destinationDistributionCenter,
                      value: destinationDistributionCenter,
                      label: destinationDistributionCenter
                    }))
                  ]}
                />
              </AlloyCol>
            </AlloyRow>
          </AlloyCol>
        </AlloyRow>
        <section>
          <AlloyTable
            dataSource={stockTransferOrdersData}
            loading={loading}
            pagination={false}
            className={s.sto_table}
            rowKey={(order) => order.id}
            columns={columns}
            sortDirections={[
              'ascend' as SortingType,
              'descend' as SortingType,
              'ascend' as SortingType
            ]}
            onChange={(pagination, filters, sorter) => {
              setSortColumn(String((sorter as SorterResult<StockTransferOrder>).columnKey));
              setSortDirection(
                (sorter as SorterResult<StockTransferOrder>).order === 'ascend' ? 'ASC' : 'DESC'
              );
            }}
          />
          <div className={s.paging}>
            <div className={s.total}></div>
            <div className={s.actions}>
              <Paginator
                hasNextPage={
                  !!stockTransferOrders?.lateStockTransferOrderReports?.pageInfo?.hasNextPage
                }
                hasPreviousPage={
                  !!stockTransferOrders?.lateStockTransferOrderReports?.pageInfo?.hasPreviousPage
                }
                nextPage={nextPage}
                prevPage={prevPage}
                pageSize={pageLimit || DEFAULT_PAGE_SIZE}
                handlePageSizeChange={handlePageSizeChange}
                onlyButtons={false}
              />
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default StoDeliveryPage;
