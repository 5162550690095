import React, { useMemo } from 'react';
import s from './ForecastTable.module.scss';
import { EMPTY } from 'common/constants';
import clsx from 'clsx';
import { StarFilled } from '@ant-design/icons';
import { AlloySelect } from 'components/ui/AlloySelect/AlloySelect';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import {
  FiscalCalendarWeekInputWithWeekOnly,
  getCurrentPeriod
} from 'components/PeriodCalendar/helpers';
import {
  convertWeekToPeriodWeekString,
  getPeriodFromWeek,
  getWeeksInFinancialYear
} from 'common/helpers/fiscalCalendar';
import { range, sumBy, groupBy } from 'lodash-es';
import { getNextWeekAndYear, yearPeriodWeekSorter } from 'common/helpers/periodHelper';
import { AlloyTable, ColumnsType, ColumnType } from 'components/ui/AlloyTable/AlloyTable';
import lockedLock from 'assets/icons/lock_locked.svg';
import { AlloySpin } from 'components/ui/AlloySpin/AlloySpin';
import { useLazyQuery, useQuery } from '@apollo/client';
import {
  ProductForecastTableDocument,
  ProductForecastTableQuery
} from 'pages/ForecastPlanning/gql/__generated__/productForecastTable.query';
import { ProductForecastFilters, ProductForecastName } from 'graphql/__generated__/types';
import { exportDataToXlsx } from 'common/helpers/exportToXlsx';
import {
  ProductForecastExportDocument,
  ProductForecastExportQuery
} from 'pages/ForecastPlanning/gql/__generated__/productForecastExport.query';
import { safeLocaleCompare } from 'common/helpers/comparators';
import { App } from 'ant5';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { AlloyModal } from 'components/ui/AlloyModal/AlloyModal';
import moment from 'moment';

const createWeekYearMatcher = ({ week, year }: { week: number; year: number }) => {
  return (item: { fiscalCalendarWeek: { week: number; year: number } }) =>
    item.fiscalCalendarWeek.week === week && item.fiscalCalendarWeek.year === year;
};

const isRecommendedForecast = (data: TableData) => data.forecastName === 'RECOMMENDED_FORECAST';
const isCurrentForecast = (data: TableData) => data.forecastName === 'CURRENT_FORECAST';

const prepareForecastForExport = async (
  data: ProductForecastExportQuery,
  fiscalCalendarWeeks: FiscalCalendarWeekInputWithWeekOnly[]
) => {
  const forecastDataGroupedBy = groupBy(data.productForecastExport, (x) => {
    // TODO: figure out if we need it or not
    // Data coming from backend sometimes has unknown and sometimes UNKNOWN, and xlsx doesn't like different casing for tab names.
    if (x.brand === 'unknown') return 'UNKNOWN_BRAND';
    return x.brand;
  });

  const result: Parameters<typeof exportDataToXlsx>[1] = [];
  const headers = [
    { name: 'ASIN', width: 75 },
    { name: 'SAP Material ID', width: 120 },
    { name: 'Description', width: 200 },
    { name: 'Brand', width: 140 },
    { name: 'Vendor Code', width: 70 },
    ...fiscalCalendarWeeks
      .sort(yearPeriodWeekSorter)
      .map((x) => ({ name: `${x.year} ${convertWeekToPeriodWeekString(x.week)}`, width: 75 }))
  ];

  Object.entries(forecastDataGroupedBy).forEach(([brand, forecastData]) => {
    result.push({
      tabName: brand,
      headers,
      itemsToExport: [
        headers.map((x) => x.name),
        ...forecastData.map((x) => {
          const recommendations = [
            ...(x.forecastValues.find(
              (forecast) => forecast?.forecastName === 'RECOMMENDED_FORECAST'
            )?.values || [])
          ]
            .sort((a, b) => yearPeriodWeekSorter(a.fiscalCalendarWeek, b.fiscalCalendarWeek))
            .map((x) =>
              isNaN(parseFloat(x.value))
                ? undefined
                : { v: parseFloat(x.value) ?? null, t: 'n' as const, z: '#,##' }
            );

          return [
            x.asin.join(','),
            x.sapMaterialId,
            x.productDescription || undefined,
            x.brand,
            x.vendorCode,
            ...recommendations
          ];
        })
      ]
    });
  });

  return result.sort((a, b) => safeLocaleCompare(a.tabName, b.tabName));
};

const TITLES: Record<ProductForecastName, { name: string; order: number }> = {
  CURRENT_FORECAST: {
    name: 'Current Forecast',
    order: 0
  },
  TREND_RECOMMENDATION: {
    name: 'Trend Recommendation',
    order: 1
  },
  PROMOTION: {
    name: 'Promotion',
    order: 2
  },
  STAT_FORECAST: {
    name: 'Stat Forecast',
    order: 3
  },
  AMAZON_PO_FCST: {
    name: 'Amazon PO Fcst',
    order: 4
  },
  RECOMMENDED_FORECAST: {
    name: 'Recommended Forecast',
    order: 5
  }
};

const formatNumber = (value: string | number) => {
  const parsedValue = typeof value === 'number' ? value : parseFloat(value);
  if (Number.isNaN(parsedValue)) return EMPTY;
  return parsedValue.toLocaleString(undefined, { maximumFractionDigits: 0 });
};

type TimeframeOptionValue = 'balance-of-year' | '1-year-view' | 'next-3-periods' | 'next-1-period';

type TableData = ProductForecastTableQuery['productForecastTable'][number];
type ChangeStatus = 'increased' | 'decreased';
type SingleValue = TableData['values'][number] & { currentComparedToRecommended?: ChangeStatus };

type ExtendedTableData = Omit<TableData, 'values'> & {
  values: SingleValue[];
};

const TIMEFRAME_OPTIONS: { label: string; value: TimeframeOptionValue; disabled?: boolean }[] = [
  {
    label: 'Balance of the year',
    value: 'balance-of-year'
  },
  {
    label: '1 year view',
    value: '1-year-view'
  },
  {
    label: 'Next 3 periods',
    value: 'next-3-periods'
  },
  {
    label: 'Next period',
    value: 'next-1-period'
  }
];

const getWeekYearPairsByTypeAndCurrentPeriod = (type: TimeframeOptionValue) => {
  const { week: currentWeek, year: currentYear } = getCurrentPeriod(true);
  const maxWeeks = getWeeksInFinancialYear(currentYear);

  let weekYearPairs: { week: number; year: number }[] = [{ week: currentWeek, year: currentYear }];

  if (type === 'balance-of-year') {
    const weeks = range(currentWeek + 1, maxWeeks + 1);
    weekYearPairs = [...weekYearPairs, ...weeks.map((week) => ({ week, year: currentYear }))];
  } else {
    // Currently BE returns 12 weeks in total for "next-3-periods", so we use 11. TODO: replace back with 12
    const iterations = type === 'next-3-periods' ? 11 : type === 'next-1-period' ? 4 : 52;
    let currentWeekYear = weekYearPairs[0];
    for (let i = 0; i < iterations; i++) {
      currentWeekYear = getNextWeekAndYear(currentWeekYear.week, currentWeekYear.year);
      weekYearPairs.push(currentWeekYear);
    }
  }

  return weekYearPairs;
};
const getColumns = (type: TimeframeOptionValue) => {
  const weekYearPairs = getWeekYearPairsByTypeAndCurrentPeriod(type);

  const weekYearPairsByYear = groupBy(weekYearPairs, 'year');

  const columns: ColumnsType<ExtendedTableData> = [
    {
      title: '',
      render: (_, record) => (
        <div className={clsx(s.name, { [s.bold]: isRecommendedForecast(record) })}>
          {TITLES[record.forecastName]?.name || record.forecastName}{' '}
          {record.forecastName === 'PROMOTION' && <StarFilled width={16} height={16} />}
        </div>
      ),
      width: 160,
      fixed: 'left',
      onCell: (record) => ({
        style: {
          background: isRecommendedForecast(record) ? '#F8F8F8' : ''
        }
      })
    },
    ...Object.entries(weekYearPairsByYear).map(([year, weekYearPairsForYear]) => ({
      key: `${year}`,
      title: (
        <div className={s.year_wrapper}>
          <div className={s.year_title}>{year}</div>
        </div>
      ),
      children: weekYearPairsForYear.map(({ week, year }): ColumnType<ExtendedTableData> => {
        // We alywas "lock" first 2 columns, and "highlight" 3-6 columns, but we need a "global" column index for that
        const index = weekYearPairs.findIndex((x) => x.week === week && x.year === year);
        const isColumnLocked = index < 2;
        const isColumnHighlightedBlue = index >= 2 && index < 6;

        return {
          key: `${week}_${year}`,
          title: (
            <div className={s.column_title}>
              {convertWeekToPeriodWeekString(week)}
              {isColumnLocked && <img src={lockedLock} alt="locked" />}
            </div>
          ),
          render: (_, record) => (
            <div className={clsx(s.value)}>
              {formatNumber(record.values.find(createWeekYearMatcher({ week, year }))?.value)}
            </div>
          ),
          onCell: (record) => {
            const GRAY = '#F8F8F8';
            const BLUE = '#E5F7FF';
            const GREEN = '#D5E9CA';
            const RED = '#fee8e7';

            const current = record.values.find(createWeekYearMatcher({ week, year }));
            const increaseOrDecreaseColor =
              current?.currentComparedToRecommended === 'increased'
                ? RED
                : current?.currentComparedToRecommended === 'decreased'
                  ? GREEN
                  : undefined;

            return {
              style: {
                background:
                  isColumnLocked || isRecommendedForecast(record)
                    ? GRAY
                    : increaseOrDecreaseColor
                      ? increaseOrDecreaseColor
                      : isColumnHighlightedBlue
                        ? BLUE
                        : ''
              },
              'data-testid': `${week}_${year}_${record.forecastName}`
            };
          },
          width: 100,
          align: 'center'
        };
      })
    }))
  ];

  return {
    columns,
    width: sumBy(columns, 'width')
  };
};

const prepareTableData = (data: TableData[]): ExtendedTableData[] => {
  const determineChange = (firstValue?: string, secondValue?: string): ChangeStatus | undefined => {
    if (firstValue === undefined || secondValue === undefined) return undefined;
    const parsedFirstValue = parseFloat(firstValue);
    const parsedSecondValue = parseFloat(secondValue);
    if (Number.isNaN(parsedFirstValue) || Number.isNaN(parsedSecondValue)) return undefined;
    if (parsedFirstValue === parsedSecondValue) return undefined;
    return parsedFirstValue > parsedSecondValue ? 'increased' : 'decreased';
  };

  return (data || [])
    .sort((a, b) => (TITLES[a.forecastName]?.order || 0) - (TITLES[b.forecastName]?.order || 0))
    .map((x) => {
      if (isCurrentForecast(x)) {
        const recommended = data.find(isRecommendedForecast);

        const updated = x.values.map((current) => {
          const recommendedValue = recommended?.values.find(
            createWeekYearMatcher(current.fiscalCalendarWeek)
          )?.value;
          const currentComparedToRecommended = determineChange(current.value, recommendedValue);
          return {
            ...current,
            currentComparedToRecommended
          };
        });

        return {
          ...x,
          values: updated
        };
      } else {
        return {
          ...x
        };
      }
    });
};

export const ForecastTable = ({
  sapMaterialId,
  vendorCodes,
  filters,
  totalProducts,
  exportModalVisible,
  setExportModalVisible
}: {
  sapMaterialId: string;
  vendorCodes: string[];
  filters: ProductForecastFilters;
  totalProducts?: number;
  exportModalVisible: boolean;
  setExportModalVisible: (value: boolean) => void;
}) => {
  const { message } = App.useApp();
  const [timeframe, setTimeframe] = useQueryParam(
    'timeframe',
    withDefault(StringParam, TIMEFRAME_OPTIONS[0].value)
  );
  const typedTimeframe = timeframe as TimeframeOptionValue;

  const weekYearPairs = useMemo(
    () =>
      getWeekYearPairsByTypeAndCurrentPeriod(typedTimeframe).map((x) => ({
        ...x,
        period: getPeriodFromWeek(x.week)
      })),
    [typedTimeframe]
  );

  const forecastTableData = useQuery(ProductForecastTableDocument, {
    variables: {
      filters: {
        sapMaterialId,
        vendorCodes,
        fiscalCalendarWeeks: weekYearPairs
      }
    }
  });

  const [fetchExportData, exportForecastData] = useLazyQuery(ProductForecastExportDocument);

  const exportData = async () => {
    const { data } = await fetchExportData({
      variables: {
        filters: {
          brands: filters.brands,
          businessUnits: filters.businessUnits,
          smartSearchTerms: filters.smartSearchTerms,
          vendorCodes: filters.vendorCodes,
          fiscalCalendarWeeks: weekYearPairs
        }
      },
      onError: (error) => {
        message.error(`Could not export forecast data: ${error.message}`);
      }
    });
    if (data) {
      const recordsForExport = await prepareForecastForExport(data, weekYearPairs);
      const filename = `forecast-iq-recommended-${filters.fiscalCalendarWeek.year}-${convertWeekToPeriodWeekString(filters.fiscalCalendarWeek.week as number)}${moment().format(
        'MM-DD-YYYY_hh-mm-ss_A'
      )}.xlsx`;
      exportDataToXlsx(filename, recordsForExport);
      message.success(`Forecast data exported into ${filename}`);
      setExportModalVisible(false);
    }
  };

  const { columns, width } = useMemo(() => getColumns(typedTimeframe), [typedTimeframe]);

  const tableData = useMemo(
    () => prepareTableData(forecastTableData.data?.productForecastTable || []),
    [forecastTableData.data?.productForecastTable]
  );

  if (forecastTableData.error)
    return (
      <div className={s.wrapper}>Can not get forecast data: {forecastTableData.error?.message}</div>
    );

  return (
    <>
      <AlloySpin spinning={forecastTableData.loading}>
        <section className={s.wrapper}>
          <div className={s.top}>
            <h2 className={s.title}>Forecast</h2>
            <div className={s.legend_wrapper}>
              <div className={s.legend}>
                Promotional
                <StarFilled width={16} height={16} />
              </div>
              <div className={s.legend}>
                Time Fence
                <div className={clsx(s.square, s.time_fence)} />
              </div>
              <div className={s.legend}>
                Recommendation
                <div className={clsx(s.square, s.recommendation)} />
              </div>
            </div>
            <AlloySelect
              options={TIMEFRAME_OPTIONS}
              onChange={(value) => setTimeframe(value)}
              value={timeframe}
              className={s.timeframe}
            />
          </div>
          <div className={s.table_wrapper}>
            <AlloyTable
              columns={columns}
              dataSource={tableData}
              rowKey="forecastName"
              pagination={false}
              size="small"
              scroll={{ x: width }}
            />
          </div>
        </section>
      </AlloySpin>
      <AlloyModal
        title="Export forecast data"
        open={exportModalVisible}
        onCancel={() => setExportModalVisible(false)}
        width={400}
        destroyOnClose
        footer={
          <>
            <div className={s.modal_buttons}>
              <AlloyButton
                type="secondary"
                className={s.bold}
                size="large"
                onClick={() => setExportModalVisible(false)}
                data-testid="forecast-export-modal-close-button"
              >
                Cancel
              </AlloyButton>

              <AlloyButton
                size="large"
                style={{ width: 'auto' }}
                type="primary"
                onClick={() => exportData()}
                loading={exportForecastData.loading}
                data-testid="forecast-export-modal-download-button"
                disabled={!totalProducts}
              >
                Download all
              </AlloyButton>
            </div>
            {exportForecastData.error && (
              <p className={s.error}>Error occured while loading xlsx, try again later.</p>
            )}
          </>
        }
      >
        <AlloySpin spinning={exportForecastData.loading}>
          {totalProducts ? (
            <p>
              We will export forecast data for {totalProducts} products into an <code>.xlsx</code>{' '}
              file.
            </p>
          ) : (
            <p>No products match filters, nothing to export.</p>
          )}
        </AlloySpin>
      </AlloyModal>
    </>
  );
};
