import React from 'react';
import s from './AlloyChip.module.scss';
import checkIcon from 'assets/icons/common/check.svg';
import { AlloyTag } from '../AlloyTag/AlloyTag';
import { CheckableTagProps } from 'ant5/es/tag';
import clsx from 'clsx';

export interface AlloyChipProps extends CheckableTagProps {
  id?: string;
  size?: 'small' | 'regular';
  label: string;
  showSelectedIcon?: boolean;
}

export const AlloyChip = ({
  label,
  className,
  size,
  showSelectedIcon = true,
  ...props
}: AlloyChipProps) => {
  const chipProps = {
    className: clsx(s.chip, className),
    'data-size': size,
    'data-selected': props.checked,
    'data-selectable': !!props.onChange,
    ...props
  };

  return (
    <AlloyTag.CheckableTag {...chipProps} className={clsx(s.chip, className)} aria-label={label}>
      {props.checked && showSelectedIcon && <img src={checkIcon} />}
      {label}
    </AlloyTag.CheckableTag>
  );
};
