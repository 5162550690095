import { useQuery } from '@apollo/client';
import LoaderSpinner from 'components/LoaderSpinner';
import React, { ReactNode } from 'react';
import s from './ChangeHistory.module.scss';
import clsx from 'clsx';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import moment from 'moment';
import {
  ChangeHistoryData,
  ChangeHistoryEntityType,
  mapAuditTrailVersionToChangeHistoryData,
  isChangeHistorySimpleValue,
  mapRepackChangeLogsToChangeHistoryData
} from './helpers/baseHelper';
import {
  RddAuditTrailVersionsDocument,
  RddAuditTrailVersionsQuery
} from './gql/__generated__/rddAuditVersions.query';
import { DistributionCentersForShipToAuditDocument } from './gql/__generated__/distributionCentersForShipToAudit.query';
import { getNodesFromEdges } from 'common/helpers/mappingHelper';
import { TradingPartnersForShipToAuditDocument } from './gql/__generated__/tradingPartnersForShipToAudit.query';
import { useEnumValue } from 'common/useEnumValue';
import { VendorAuditTrailVersionsDocument } from './gql/__generated__/retailerAuditVersions.query';
import { CloseOutlined } from '@ant-design/icons';
import { VendorMarketAuditTrailVersionsDocument } from './gql/__generated__/retailerChannelAuditVersions.query';
import { RetailersForRetailerChannelAuditDocument } from './gql/__generated__/retailersForRetailerChannelAudit.query';
import { RetailerChannelsForShipToAuditDocument } from './gql/__generated__/retailerChannelsForShipToAudit.query';
import { DistributionCenterAuditTrailVersionsDocument } from './gql/__generated__/distributionCenterAuditVersions.query';
import { TpAuditTrailVersionsDocument } from './gql/__generated__/tpAuditVersions.query';
import {
  RetailerProductRddAssortmentsDocument,
  RetailerProductRddAssortmentsQuery
} from './gql/__generated__/retailerProductRddAssortments.query';
import { RetailerDeliveryDestinationsForAssortmentAuditDocument } from './gql/__generated__/retailerDeliveryDestinationsForAssortmentAudit.query';
import { ListOfItemsColumn } from 'components/Common/Table/ListOfItemsColumn/ListOfItemsColumn';
import { BusinessUnitsForAuditDocument } from './gql/__generated__/businessUnitsForAudit.query';
import { UserForAuditTrailDocument } from './gql/__generated__/userForAuditTrail.query';
import { ChannelAdvisorProfilesDocument } from 'pages/TradingPartnersPage/gql/__generated__/channelAdvisorProfiles.query';
import { FacilitiesForDistributionCenterAuditDocument } from './gql/__generated__/facilitiesForDistributionCenterAudit.query';
import { AlloyDrawer } from 'components/ui/AlloyDrawer/AlloyDrawer';
import { CatalogProductAuditTrailVersionsDocument } from '../../components/ChangeHistory/gql/__generated__/catalogProductAuditTrailVersions.query';
import { InventoryRepackFilters } from 'graphql/__generated__/types';
import { RepackChangeLogsDocument } from './gql/__generated__/repackChangeLogs.query';
import { notEmpty } from 'common/helpers/notEmpty';
import { AlloyTable, ColumnType } from 'components/ui/AlloyTable/AlloyTable';
import { AlloyTooltip } from 'components/ui/AlloyTooltip/AlloyTooltip';
import { AlloyTimeline } from 'components/ui/AlloyTimeline/AlloyTimeline';
import { RetailerDeliveryDestinationsForAuditDocument } from './gql/__generated__/retailerDeliveryDestinationsForAudit.query';

export type AuditTrailVersion = NonNullable<
  RetailerProductRddAssortment['retailerProduct']
>['auditTrailVersions'][number];

export type AuditTrailDiff = AuditTrailVersion['diff'][number];

export type RetailerDeliveryDestinationForAudit = NonNullable<
  RddAuditTrailVersionsQuery['retailerDeliveryDestination']
>;

export type RetailerProductRddAssortment = NonNullable<
  RetailerProductRddAssortmentsQuery['retailerProductRddAssortments']
>;

interface ChangeHistoryProps {
  entity?: {
    id: string;
    name: string;
    externalId?: string;
    type: ChangeHistoryEntityType;
    tradingPartnerId?: string;
    // TODO: write a union type? Right now doesn't seem feasible.
    inventoryRepackFilters?: InventoryRepackFilters;
  };
  onClose: () => void;
  title?: ReactNode;
}

const withSubPage = (type: ChangeHistoryEntityType | undefined) =>
  type !== 'Assortment' &&
  type !== 'New Assortment' &&
  type !== 'User' &&
  type !== 'Repack Planning' &&
  type !== 'unknown-use-for-mocks-only';

const withDetailsTooltip = (type: ChangeHistoryEntityType | undefined) =>
  type === 'Repack Planning';

const getDrawerWidth = (type?: ChangeHistoryEntityType): number => {
  const subPageWidth = withSubPage(type) ? 195 : 0;
  const detailsTooltipWidth = withDetailsTooltip(type) ? 40 : 0;
  return 570 + subPageWidth + detailsTooltipWidth;
};
export const ChangeHistory = ({ entity, onClose, title = '' }: ChangeHistoryProps) => {
  // data by type
  const retailer = useQuery(VendorAuditTrailVersionsDocument, {
    skip: entity?.type !== 'Retailer',
    variables: {
      id: entity?.id || ''
    }
  });

  const retailerChannel = useQuery(VendorMarketAuditTrailVersionsDocument, {
    skip: entity?.type !== 'Retailer Channel',
    variables: {
      id: entity?.id || ''
    }
  });

  const tradingPartner = useQuery(TpAuditTrailVersionsDocument, {
    skip: entity?.type !== 'Trading Partner',
    variables: {
      id: entity?.id || ''
    }
  });

  const shipTo = useQuery(RddAuditTrailVersionsDocument, {
    skip: entity?.type !== 'Ship-To',
    variables: {
      id: entity?.id || ''
    }
  });

  const distributionCenter = useQuery(DistributionCenterAuditTrailVersionsDocument, {
    skip: entity?.type !== 'Distribution Center',
    variables: {
      id: entity?.id || ''
    }
  });

  const distributionCenterFacilities = useQuery(FacilitiesForDistributionCenterAuditDocument, {
    skip: entity?.type !== 'Distribution Center'
  });

  // OLD ASSORTMENT
  const assortment = useQuery(RetailerProductRddAssortmentsDocument, {
    skip: entity?.type !== 'Assortment',
    variables: {
      retailerProductId: entity?.id || '',
      tradingPartnerId: entity?.tradingPartnerId || ''
    }
  });

  // NEW ASSORTMENT AUDIT TRAIL DATA WITH OLD ASSORTMENT
  const newAssortment = useQuery(CatalogProductAuditTrailVersionsDocument, {
    skip: entity?.type !== 'New Assortment',
    variables: {
      retailerProductId: entity?.id || '',
      tradingPartnerId: entity?.tradingPartnerId || ''
    }
  });

  const user = useQuery(UserForAuditTrailDocument, {
    skip: entity?.type !== 'User',
    variables: {
      id: entity?.id || ''
    }
  });

  // additional data
  const dcs = useQuery(DistributionCentersForShipToAuditDocument, {
    skip:
      entity?.type !== 'Ship-To' &&
      entity?.type !== 'User' &&
      entity?.type !== 'Trading Partner' &&
      entity?.type !== 'New Assortment'
  });

  const tps = useQuery(TradingPartnersForShipToAuditDocument, {
    skip:
      entity?.type !== 'Ship-To' &&
      entity?.type !== 'Assortment' &&
      entity?.type !== 'New Assortment' &&
      entity?.type !== 'User'
  });

  const channelAdvisorProfiles = useQuery(ChannelAdvisorProfilesDocument, {
    skip: entity?.type !== 'Trading Partner'
  });

  const retailerChannels = useQuery(RetailerChannelsForShipToAuditDocument, {
    skip:
      entity?.type !== 'Ship-To' &&
      entity?.type !== 'Trading Partner' &&
      entity?.type !== 'Retailer Channel'
  });

  const retailers = useQuery(RetailersForRetailerChannelAuditDocument, {
    skip: entity?.type !== 'Retailer Channel'
  });

  const bus = useQuery(BusinessUnitsForAuditDocument, {
    skip: entity?.type !== 'Trading Partner'
  });

  const rddsForAssortment = useQuery(RetailerDeliveryDestinationsForAssortmentAuditDocument, {
    skip: entity?.type !== 'Assortment' && entity?.type !== 'New Assortment'
  });

  const rdds = useQuery(RetailerDeliveryDestinationsForAuditDocument, {
    skip: entity?.type !== 'Trading Partner'
  });

  // TODO: WIP: Write a mapper for this
  const repackChangeLogs = useQuery(RepackChangeLogsDocument, {
    skip: entity?.type !== 'Repack Planning',
    variables: {
      filters: entity?.inventoryRepackFilters
    }
  });

  const daysOfWeek = useEnumValue('DayOfWeek');

  const mapVersions = (type: ChangeHistoryEntityType) => {
    switch (type) {
      case 'Ship-To':
        return mapAuditTrailVersionToChangeHistoryData(
          shipTo.data?.retailerDeliveryDestination?.auditTrailVersions || [],
          type,
          shipTo.data?.retailerDeliveryDestination,
          {
            daysOfWeek: daysOfWeek.enumValues,
            distributionCenters: getNodesFromEdges(dcs.data?.distributionCenters),
            tradingPartners: getNodesFromEdges(tps.data?.tradingPartners),
            retailerChannels: getNodesFromEdges(retailerChannels.data?.vendorMarkets)
          }
        );
      case 'Trading Partner':
        return mapAuditTrailVersionToChangeHistoryData(
          tradingPartner.data?.tradingPartner?.auditTrailVersions || [],
          type,
          tradingPartner.data?.tradingPartner,
          {
            daysOfWeek: daysOfWeek.enumValues,
            retailerChannels: getNodesFromEdges(retailerChannels.data?.vendorMarkets),
            businessUnits: getNodesFromEdges(bus.data?.businessUnits),
            distributionCenters: getNodesFromEdges(dcs.data?.distributionCenters),
            profileIds: channelAdvisorProfiles.data?.channelAdvisorProfiles || [],
            retailerDeliveryDestinations: getNodesFromEdges(rdds.data?.retailerDeliveryDestinations)
          }
        );
      case 'Retailer':
        return mapAuditTrailVersionToChangeHistoryData(
          retailer.data?.vendor?.auditTrailVersions || [],
          type,
          retailer.data?.vendor,
          {}
        );
      case 'Retailer Channel':
        return mapAuditTrailVersionToChangeHistoryData(
          retailerChannel.data?.vendorMarket?.auditTrailVersions || [],
          type,
          retailerChannel.data?.vendorMarket,
          {
            daysOfWeek: daysOfWeek.enumValues,
            retailers: getNodesFromEdges(retailers.data?.vendors),
            retailerChannels: getNodesFromEdges(retailerChannels.data?.vendorMarkets)
          }
        );
      case 'Distribution Center':
        return mapAuditTrailVersionToChangeHistoryData(
          distributionCenter.data?.distributionCenter?.auditTrailVersions || [],
          type,
          distributionCenter.data?.distributionCenter,
          {
            daysOfWeek: daysOfWeek.enumValues,
            retailers: getNodesFromEdges(retailers.data?.vendors),
            distributionCenterFacilities: getNodesFromEdges(
              distributionCenterFacilities.data?.distributionCenterFacilities
            )
          }
        );
      case 'Assortment':
        return mapAuditTrailVersionToChangeHistoryData(
          assortment.data?.retailerProductRddAssortments?.retailerProduct.auditTrailVersions || [],
          type,
          assortment.data?.retailerProductRddAssortments,
          {
            daysOfWeek: daysOfWeek.enumValues,
            tradingPartners: getNodesFromEdges(tps.data?.tradingPartners),
            retailerDeliveryDestinations: getNodesFromEdges(
              rddsForAssortment.data?.retailerDeliveryDestinations
            )
          },
          entity?.tradingPartnerId
        );
      case 'New Assortment': {
        const newAssortmentsAuditTrailVersions = [
          ...(newAssortment.data?.retailerProductRddAssortments?.retailerProduct
            ?.auditTrailVersions || []),
          ...getNodesFromEdges(newAssortment.data?.catalogProductAuditTrailVersions)
        ];
        return mapAuditTrailVersionToChangeHistoryData(
          newAssortmentsAuditTrailVersions,
          type,
          newAssortment.data?.retailerProductRddAssortments,
          {
            daysOfWeek: daysOfWeek.enumValues,
            tradingPartners: getNodesFromEdges(tps.data?.tradingPartners),
            retailerDeliveryDestinations: getNodesFromEdges(
              rddsForAssortment.data?.retailerDeliveryDestinations
            ),
            distributionCenters: getNodesFromEdges(dcs.data?.distributionCenters)
          },
          entity?.tradingPartnerId
        );
      }
      case 'User':
        return mapAuditTrailVersionToChangeHistoryData(
          user.data?.user?.auditTrailVersions || [],
          type,
          user.data?.user,
          {
            daysOfWeek: daysOfWeek.enumValues,
            tradingPartners: getNodesFromEdges(tps.data?.tradingPartners),
            distributionCenters: getNodesFromEdges(dcs.data?.distributionCenters)
          }
        );
      case 'Repack Planning':
        return mapRepackChangeLogsToChangeHistoryData(
          repackChangeLogs.data?.repackChangeLogs?.logs || []
        );
      default:
        return [];
    }
  };

  const loading =
    tradingPartner.loading ||
    shipTo.loading ||
    retailer.loading ||
    dcs.loading ||
    tps.loading ||
    daysOfWeek.loading ||
    retailerChannel.loading ||
    retailers.loading ||
    retailerChannels.loading ||
    distributionCenter.loading ||
    distributionCenterFacilities.loading ||
    assortment.loading ||
    newAssortment.loading ||
    rdds.loading ||
    rddsForAssortment.loading ||
    bus.loading ||
    channelAdvisorProfiles.loading ||
    user.loading ||
    repackChangeLogs.loading;

  const titleText = entity?.externalId ? `${entity?.externalId}: ${entity?.name}` : entity?.name;

  const getColumns = () => {
    const subPageColumn: ColumnType<ChangeHistoryData> = {
      title: 'Subpage',
      width: 170,
      render: (_, record) => (
        <span data-testid={`change-history-subpage-${record.key}`}>{record.subpageName}</span>
      )
    };

    const detailsTooltipColumn: ColumnType<ChangeHistoryData> = {
      title: '',
      width: 40,
      render: (_, record) => record.detailsTooltip || <></>
    };

    const columns: ColumnType<ChangeHistoryData>[] = [
      {
        title: 'Event',
        width: 170,
        render: (_, record) => (
          <span data-testid={`change-history-event-${record.key}`} className={s.event_title}>
            <span data-testid={`change-history-actor-${record.key}-eventName`}>
              {record.eventName}
            </span>
            {(entity?.type === 'Assortment' || entity?.type === 'New Assortment') &&
              record.meta.tradingPartnerId &&
              record.meta.tradingPartnerId !== entity.tradingPartnerId && (
                <span
                  className={s.trading_partner_meta}
                  data-testid={`change-history-actor-${record.key}-meta`}
                >
                  <span className={s.indicator} />
                  <AlloyTooltip title={record.meta.tradingPartnerName}>
                    {record.meta.tradingPartnerExternalId}
                  </AlloyTooltip>
                </span>
              )}
          </span>
        )
      },
      ...(withSubPage(entity?.type) ? [subPageColumn] : []),
      {
        title: 'Actor',
        width: withSubPage(entity?.type) ? 160 : 150,
        render: (_, record) => (
          <span data-testid={`change-history-actor-${record.key}`}>{record.actor}</span>
        )
      },
      {
        title: `Time (${Intl.DateTimeFormat().resolvedOptions().timeZone})`,
        width: 174,
        render: (record: ChangeHistoryData) => (
          <span data-testid={`change-history-time-${record.key}`}>
            {moment(record.updatedAt).format('MMM D YYYY, h:mm a')}
          </span>
        )
      },
      ...(withDetailsTooltip(entity?.type) ? [detailsTooltipColumn] : [])
    ];
    return columns.filter(notEmpty);
  };

  return (
    <AlloyDrawer
      className={s.change_history_drawer}
      data-testid={`change-history-${entity?.type}-${entity?.id}`}
      width={getDrawerWidth(entity?.type)}
      open={!!entity}
      classNames={{
        body: s.change_history_drawer_body,
        header: s.change_history_drawer_header
      }}
      title={
        <AlloyTooltip title={titleText} placement="bottom">
          <div className={s.title}>
            <span className={s.bold}>Audit Trail |</span> {title ? title : titleText}
          </div>
        </AlloyTooltip>
      }
      onClose={onClose}
      destroyOnClose
      closeIcon={<CloseOutlined data-testid={`change-history-close`} />}
    >
      {loading && <LoaderSpinner />}
      {entity && !loading && (
        <AlloyTable<ChangeHistoryData>
          data-testid={`change-history-table`}
          className={s.history_table}
          size="small"
          columns={getColumns()}
          expandable={{
            expandIcon: ({ expanded, onExpand, record }) =>
              record.changes.length > 0 ? (
                expanded ? (
                  <div
                    data-testid={`change-history-hide-${record.key}`}
                    className={s.expand_icon_wraper}
                    onClick={(e) => onExpand(record, e)}
                  >
                    <UpOutlined sizes={'12px, 10px'} />
                  </div>
                ) : (
                  <div
                    data-testid={`change-history-expand-${record.key}`}
                    className={s.expand_icon_wraper}
                    onClick={(e) => onExpand(record, e)}
                  >
                    <DownOutlined />
                  </div>
                )
              ) : null,
            expandedRowRender: (record) => (
              <AlloyTimeline
                data-testid={`change-history-changes-${record.key}`}
                className={clsx(s.list_of_changes, {
                  [s.assortment]: !withSubPage(entity.type)
                })}
              >
                {record.changes.map((change) =>
                  isChangeHistorySimpleValue(change) ? (
                    <AlloyTimeline.Item key={change.fieldTitle} className={s.change_item}>
                      <span className={s.field_name}>{change.fieldTitle}</span>
                      <ValueItem value={change.value} className={s.value} />
                    </AlloyTimeline.Item>
                  ) : (
                    <AlloyTimeline.Item key={change.fieldTitle} className={s.change_item}>
                      <span className={s.field_name}>{change.fieldTitle}</span>
                      <ValueItem value={change.previousValue} className={s.previous} />→{' '}
                      <ValueItem value={change.newValue} className={s.new} />
                    </AlloyTimeline.Item>
                  )
                )}
              </AlloyTimeline>
            ),
            rowExpandable: (record) => record.changes.length > 0
          }}
          dataSource={mapVersions(entity.type)}
          rowKey={(record) => `${record.updatedAt}-${record.eventName}-${record.key}}`}
          sticky
          rowClassName={s.expanded_row}
          pagination={false}
        />
      )}
    </AlloyDrawer>
  );
};

const ValueItem = ({
  value,
  className
}: {
  value: string | string[] | null | undefined;
  className: string;
}) =>
  Array.isArray(value) ? (
    value.length > 0 ? (
      <span className={className}>
        <ListOfItemsColumn items={value} splitToLines />
      </span>
    ) : (
      <span className={className}>{'<Empty>'}</span>
    )
  ) : (
    <span className={className}>{value || '<Empty>'}</span>
  );
