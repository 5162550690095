import React, { useState, useMemo, useRef } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { GenerateBulkCustomizationsDocument } from './gql/__generated__/generateBulkCustomizations.mutation';
import { GetSalesOrderDetailsForCustomizationDocument } from './gql/__generated__/getSalesOrderDetails.query';
import { getNodesFromEdges } from 'common/helpers/mappingHelper';
import s from './DownloadCustomizations.module.scss';
import { GeneratedCustomization } from 'graphql/__generated__/types';
import { CSVLink } from 'react-csv';
import { AlloyModal } from 'components/ui/AlloyModal/AlloyModal';
import { AlloyCheckbox } from 'components/ui/AlloyCheckbox/AlloyCheckbox';
import { AlloyTable, ColumnsType } from 'components/ui/AlloyTable/AlloyTable';
import { App } from 'ant5';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';

interface SalesOrderQuery {
  id: string;
  hasCustomizations: boolean;
  distributionCenter: {
    id: string;
    name: string;
  };
}

interface CsvLinkRef {
  [index: string]: HTMLAnchorElement;
}

const parseCustomizationContent = (content: string) => {
  const rows = content.split('\n').filter((row, index) => {
    if (row.length == 0) return false;
    return true;
  });

  const csvData = rows.map((row) => row.replace(/[\r\n]/g, '').split(','));

  return csvData;
};

export const DownloadCustomizationsModal = ({
  open,
  toggleModal,
  purchaseOrderIds
}: {
  open: boolean;
  toggleModal: () => void;
  purchaseOrderIds: string[];
}) => {
  const { notification } = App.useApp();
  const [selectedSalesOrderIds, setSelectedSalesOrderIds] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState(true);
  const [generateCustomizations] = useMutation(GenerateBulkCustomizationsDocument);
  const [customizationsByFilename, setCustomizationsByFilename] = useState<
    GeneratedCustomization[]
  >([]);
  const csvLinks = useRef<CsvLinkRef>({});
  const [mergeByDc, setMergeByDC] = useState(true);

  const { data, loading } = useQuery(GetSalesOrderDetailsForCustomizationDocument, {
    skip: !open,
    variables: {
      ids: purchaseOrderIds
    }
  });

  let purchaseOrdersWithoutCustomizations = 0;
  const purchaseOrders = useMemo(() => {
    return getNodesFromEdges(data?.purchaseOrders).filter((po) => {
      if (po.salesOrders.find((so) => so.hasCustomizations)) {
        return true;
      } else {
        purchaseOrdersWithoutCustomizations++;
        return false;
      }
    });
  }, [data]);

  const allSalesOrderIds = useMemo(() => {
    const allIds = purchaseOrders
      .filter((po) => po.salesOrders.find((so) => so.hasCustomizations))
      .reduce((salesOrderIds: string[], po) => {
        return salesOrderIds.concat(po.salesOrders.map((so) => so.id));
      }, []);

    if (selectAll) {
      setSelectedSalesOrderIds(allIds);
    }

    return allIds;
  }, [purchaseOrders]);

  const getCustomizations = async () => {
    try {
      const requests = purchaseOrders
        .filter((po) =>
          po.salesOrders.find((so) => selectedSalesOrderIds.find((id) => id === so.id))
        )
        .reduce((ids, po) => {
          return ids.concat(
            po.salesOrders.filter((so) => selectedSalesOrderIds.includes(so.id)).map((so) => so.id)
          );
        }, [] as string[]);

      const data = await generateCustomizations({
        variables: {
          input: {
            salesOrderIds: requests,
            mergeCustomizationsByDc: mergeByDc
          }
        }
      });

      setCustomizationsByFilename([
        ...((data?.data?.generateSalesOrdersCustomizations
          ?.customizations as GeneratedCustomization[]) || [])
      ]);
      for (const key in csvLinks.current) {
        if (csvLinks.current[key]) csvLinks.current[key].click();
      }

      toggleModal();
    } catch (error) {
      notification.error({
        message: 'Error generating customizations',
        description: error.message
      });
    }
  };

  const toggleSelectAll = () => {
    setSelectedSalesOrderIds(!selectAll ? allSalesOrderIds : []);
    setSelectAll(!selectAll);
  };

  const columns: ColumnsType<SalesOrderQuery> = [
    {
      title: 'DC',
      width: 350,
      render: (_, so) => so.distributionCenter?.name
    },
    {
      title: '',
      width: 200,
      render: (_, so, index) => `Sales Order ${index + 1}`
    }
  ];

  return (
    <AlloyModal
      data-testid="download_customization_modal"
      title="Download Customization Files"
      open={open}
      onCancel={toggleModal}
      width={600}
      footer={
        purchaseOrders.length > 0 && (
          <div className={s.footer}>
            <div className={s.merge_checkbox}>
              <AlloyCheckbox
                checked={mergeByDc}
                onChange={() => setMergeByDC(!mergeByDc)}
                data-testid="merge_by_dc"
              />
              <span className={s.label} onClick={() => setMergeByDC(!mergeByDc)}>
                Merge customization files going to the same DC
              </span>
            </div>
            <div>
              <AlloyButton
                data-testid="download_customizations"
                type="primary"
                size="large"
                onClick={getCustomizations}
              >
                Downloads
              </AlloyButton>
            </div>
          </div>
        )
      }
      okText="Download"
    >
      {purchaseOrders.length === 0 && (
        <div className={s.no_files}>No customization files for the selected purchase orders.</div>
      )}
      {purchaseOrders.length > 0 && (
        <>
          <div className={s.select_all}>
            <AlloyCheckbox
              checked={selectAll}
              onChange={toggleSelectAll}
              data-testid="select_all"
            />
            <div className={s.label} onClick={toggleSelectAll}>
              Select All
            </div>
          </div>
          {purchaseOrders.map((po) => {
            return (
              <div className={s.purchase_order}>
                <div className={s.header}>
                  <span>{po.customerPo}</span>
                </div>
                <AlloyTable
                  columns={columns}
                  dataSource={
                    po.salesOrders.filter((so) => so.hasCustomizations) as SalesOrderQuery[]
                  }
                  showHeader={false}
                  rowKey={(so) => so.id}
                  rowSelection={{
                    selectedRowKeys: selectedSalesOrderIds,
                    hideSelectAll: po.salesOrders.length < 2,
                    onSelect: (so) => {
                      if (selectAll) setSelectAll(false);

                      if (selectedSalesOrderIds.includes(so.id)) {
                        setSelectedSalesOrderIds(
                          selectedSalesOrderIds.filter((id) => id !== so.id)
                        );
                      } else {
                        const update = [...selectedSalesOrderIds, so.id];
                        if (update.length === allSalesOrderIds.length) setSelectAll(true);
                        setSelectedSalesOrderIds(update);
                      }
                    }
                  }}
                  pagination={false}
                />
              </div>
            );
          })}
          <div className="dc_csv_links">
            {customizationsByFilename.map((customization) => {
              return (
                <div
                  ref={(element) => {
                    csvLinks.current[customization.filename || ''] = element
                      ?.children[0] as HTMLAnchorElement;
                  }}
                >
                  <CSVLink
                    filename={customization.filename || ''}
                    data={parseCustomizationContent(customization.content || '')}
                    enclosingCharacter=""
                  />
                </div>
              );
            })}
          </div>
        </>
      )}
    </AlloyModal>
  );
};
