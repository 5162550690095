import React, { Ref, forwardRef, useRef, useState } from 'react';
import './MultipleValuesInput.scss';
import { CloseCircleFilled } from '@ant-design/icons';
import { AlloySpin } from 'components/ui/AlloySpin/AlloySpin';
import { AlloyTag } from 'components/ui/AlloyTag/AlloyTag';
import { AlloyInput, InputRef } from 'components/ui/AlloyInput/AlloyInput';

interface MultipleValuesInputProps {
  onChange: (value: string[]) => void;
  allowClear?: boolean;
  value: string[];
  prefix?: React.ReactNode;
  splitInputValue?: {
    [Symbol.split](string: string): string[];
  };
  dataTestId?: string;
  placeholder?: string;
  loading?: boolean;
  disabled?: boolean;
}

export const MultipleValuesInput = forwardRef<HTMLDivElement, MultipleValuesInputProps>(
  (
    {
      value,
      onChange,
      allowClear = false,
      prefix,
      splitInputValue,
      dataTestId,
      placeholder,
      loading = false,
      disabled
    },
    ref
  ) => {
    const [inputValue, setInputValue] = useState('');
    const inputRef = useRef<InputRef>(null);

    const onRemoveValue = (removeValue: string) => {
      const newValue = [...value];
      newValue.splice(
        value.findIndex((v) => removeValue === v),
        1
      );
      onChange(newValue);
    };

    const onAddValue = (addValue: string) => {
      if (splitInputValue) {
        const splitted = addValue.split(splitInputValue).filter((v) => v.length > 0);
        const newValue = [...value];
        splitted.forEach((splittedValue) => {
          const found = newValue.find((v) => v === splittedValue);
          if (!found) {
            newValue.push(splittedValue);
          }
        });
        onChange(newValue);
      } else {
        const found = value.find((v) => v === addValue);
        if (!found) {
          onChange([...value, addValue]);
        }
      }
    };

    return (
      <AlloySpin spinning={loading}>
        <div
          ref={ref}
          className="MultipleValuesInput_body"
          onClick={() => inputRef.current?.focus()}
          data-testid={dataTestId || 'multiple-values-input'}
        >
          <span className="MultipleValuesInput_prefix">{prefix}</span>
          <div className="MultipleValuesInput_container">
            {value.map((v) => (
              <AlloyTag
                key={v}
                closable
                onClose={() => onRemoveValue(v)}
                className="MultipleValuesInput_tag"
                data-testid={`MultipleValuesInput_${v}`}
                disabled={disabled}
              >
                {v}
              </AlloyTag>
            ))}
            <AlloyInput
              placeholder={value.length === 0 ? placeholder : undefined}
              ref={inputRef}
              disabled={disabled}
              variant="borderless"
              onPressEnter={() => {
                if (inputValue.length > 0) {
                  onAddValue(inputValue);
                  setInputValue('');
                }
              }}
              onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                if (event.key === 'Backspace' && inputValue.length === 0 && value.length > 0) {
                  onChange(value.slice(0, value.length - 1));
                }
              }}
              onChange={(event) => {
                setInputValue(event.currentTarget.value);
              }}
              value={inputValue}
              className="MultipleValuesInput_input"
            />
          </div>
          {allowClear && value?.length > 0 && (
            <div className="MultipleValuesInput_clear" role="button">
              <CloseCircleFilled onClick={() => !disabled && onChange([])} />
            </div>
          )}
        </div>
      </AlloySpin>
    );
  }
);

MultipleValuesInput.displayName = 'MultipleValuesInput';
