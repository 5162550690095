import { NOT_AVAILABLE } from 'common/constants';

export function convertToMM(
  rawNumber?: string | number | null,
  notAvailable = NOT_AVAILABLE
): string {
  if (rawNumber === null || typeof rawNumber === 'undefined') return notAvailable;

  const number = typeof rawNumber === 'number' ? rawNumber : parseFloat(rawNumber);

  if (isNaN(number)) {
    return notAvailable; // Handle invalid input
  }

  const oneMillion = 1_000_000;
  const hundredMillion = oneMillion * 100;

  // If number is less than 10,000, display it as-is
  if (number < 10_000) {
    return '$' + number.toFixed(0).toString();
  }

  // If number is less than 1 million, display it as a fraction of a million with 2 decimal places
  if (number < oneMillion) {
    return '$' + (number / oneMillion).toFixed(2) + 'MM';
  }

  // If number is more than 100 million, display it as a fraction of a million with 0 decimal places
  if (number >= hundredMillion) {
    return '$' + (number / oneMillion).toFixed(0) + 'MM';
  }

  // Otherwise, convert to MM and display with 2 decimal places
  return '$' + (number / oneMillion).toFixed(2) + 'MM';
}
