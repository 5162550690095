import * as Types from '../../../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ExecutiveIntelligenceChargebacksTotalQueryVariables = Types.Exact<{
  filters: Types.ExecutiveReportingFilters;
}>;


export type ExecutiveIntelligenceChargebacksTotalQuery = { __typename?: 'RootQueryType', chargebackDetailsReportsByPeriod: Array<{ __typename?: 'ChargebackDetailsReportByPeriod', financialCharge: any, status?: string | null, totalValue?: boolean | null, fiscalCalendarWeek: { __typename?: 'FiscalCalendarWeek', year: number, week: number } }> };


export const ExecutiveIntelligenceChargebacksTotalDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"executiveIntelligenceChargebacksTotal"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"filters"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ExecutiveReportingFilters"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"chargebackDetailsReportsByPeriod"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"filters"},"value":{"kind":"Variable","name":{"kind":"Name","value":"filters"}}},{"kind":"Argument","name":{"kind":"Name","value":"dimensions"},"value":{"kind":"ListValue","values":[{"kind":"EnumValue","value":"STATUS"}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"financialCharge"}},{"kind":"Field","name":{"kind":"Name","value":"fiscalCalendarWeek"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"year"}},{"kind":"Field","name":{"kind":"Name","value":"week"}}]}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"totalValue"}}]}}]}}]} as unknown as DocumentNode<ExecutiveIntelligenceChargebacksTotalQuery, ExecutiveIntelligenceChargebacksTotalQueryVariables>;