import React, { useMemo } from 'react';
import s from './SalesOrderItemsTable.module.scss';
import clsx from 'clsx';
import { AlloyTable, ColumnsType } from 'components/ui/AlloyTable/AlloyTable';
import { AlloyTooltip } from 'components/ui/AlloyTooltip/AlloyTooltip';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { AlignType } from 'common/types';
import {
  MeasurementSystem,
  PO_STATUS_NEW,
  PO_STATUS_SCRUBBED,
  PO_STATUS_SUBMITTED,
  PO_ERROR_MAP
} from 'common/constants';
import CutReasonDropdown from '../../CutReasonDropdown/CutReasonDropdown';
import { EditingAllocatedQty } from './EditingAllocatedQty/EditingAllocatedQty';
import { safeLocaleCompare, safeNumberComparator } from 'common/helpers/comparators';
import { PurchaseOrderWithOverdue, SalesOrderItem } from 'pages/OrderDetailsPage/types';

const { Summary } = AlloyTable;

export const getAllocatedQty = (item: SalesOrderItem, toShow: boolean) =>
  item.acceptedUnits === null || typeof item.acceptedUnits === 'undefined'
    ? toShow
      ? '-'
      : ''
    : new Intl.NumberFormat().format(item.acceptedUnits);

export const convertSoItemToModifiedSoItem = (
  item: SalesOrderItem,
  measurementSystem: string | undefined | null
) => {
  const onHand = !item.inventoryOnHand || item.inventoryOnHand <= 0 ? 0 : item.inventoryOnHand;
  const quantityCut =
    (item.quantityOrdered || 0) - onHand >= 0 ? (item.quantityOrdered || 0) - onHand : 0;
  const quantityPromised = (item.quantityOrdered || 0) - quantityCut;
  const weight =
    measurementSystem === MeasurementSystem.METRIC ? item.weightInKilograms : item.weightInLbs;

  const pallets = item.pallets;

  return {
    ...item,
    quantityCut,
    onHand,
    quantityPromised,
    weight,
    pallets
  };
};

export type ModifiedSalesOrderItem = ReturnType<typeof convertSoItemToModifiedSoItem>;

interface SalesOrderItemTableProps {
  items: SalesOrderItem[];
  onSelectAction: (selectedRows: SalesOrderItem[]) => void;
  selectedKeys: string[];
  orderStatus: string;
  purchaseOrder: PurchaseOrderWithOverdue;
}

const SalesOrderItemTable = ({
  items,
  onSelectAction,
  selectedKeys,
  orderStatus,
  purchaseOrder
}: SalesOrderItemTableProps) => {
  const salesOrderItems = useMemo(
    () =>
      items.map((item) =>
        convertSoItemToModifiedSoItem(item, purchaseOrder?.tradingPartner?.measurementSystem)
      ),
    [items, purchaseOrder]
  );

  const dsdColumns: ColumnsType<ModifiedSalesOrderItem> = [
    {
      title: 'UPC', //TODO: does the customer want this to be UPC or GTIN? It is GTIN in the tables for PO Item Tab.
      className: s.upc,
      key: 'gtin',
      width: 170,
      render: (_, item) => item.catalogProduct?.gtin12 ?? item.product?.upc ?? '',
      sorter: (a, b) => {
        if (a?.catalogProduct) {
          return safeLocaleCompare(a?.catalogProduct?.gtin12, b?.catalogProduct?.gtin12);
        } else {
          return safeLocaleCompare(a?.product?.upc, b?.product?.upc);
        }
      }
    },
    {
      title: 'EXTERNAL ID',
      key: 'externalId',
      width: 170,
      render: (_, item) => item?.externalId,
      sorter: (a, b) => safeLocaleCompare(a.externalId, b.externalId)
    },
    {
      title: 'ITEM DESCRIPTION',
      className: s.description,
      key: 'productName',
      render: (_, item) => item.catalogProduct?.name ?? item.product?.name ?? '',
      sorter: (a, b) => {
        if (a?.catalogProduct) {
          return safeLocaleCompare(a?.catalogProduct?.name, b?.catalogProduct?.name);
        } else {
          return safeLocaleCompare(a?.product?.name, b?.product?.name);
        }
      }
    },
    {
      title: 'ORDER QTY',
      className: s.order_qty,
      key: 'orderQuantity',
      width: 150,
      align: 'right' as AlignType,
      sorter: (a, b) => safeNumberComparator(a.quantityOriginal, b.quantityOriginal),
      render: (_, item) => {
        return `${
          item.quantityOriginal && item.quantityOriginal !== null
            ? new Intl.NumberFormat().format(item.quantityOriginal)
            : '0'
        } ${item?.unitOfMeasure || 'EA'}`;
      }
    },
    {
      title: 'PROMISED QTY',
      className: s.promised_qty,
      key: 'promisedQuantity',
      width: 150,
      align: 'right' as AlignType,
      sorter: (a, b) => safeNumberComparator(a.quantityOrdered, b.quantityOrdered),
      render: (_, item) => {
        //TODO: why are we returning quantityOrdered here in stead of quantityPromised?
        return `${
          item.quantityOrdered && item.quantityOrdered !== null
            ? new Intl.NumberFormat().format(item.quantityOrdered)
            : '0'
        } ${item?.unitOfMeasure || 'EA'}`;
      }
    },
    {
      title: 'DELIVERED QTY',
      className: s.delivered_qty,
      key: 'deliveredQty',
      width: 150,
      align: 'right' as AlignType,
      sorter: (a, b) => safeNumberComparator(a.quantityDelivered, b.quantityDelivered),
      render: (_, item) => {
        return `${
          item.quantityDelivered && item.quantityDelivered !== null
            ? new Intl.NumberFormat().format(item.quantityDelivered)
            : '0'
        } ${item?.unitOfMeasure}`;
      }
    }
  ];

  const ecommColumns: ColumnsType<ModifiedSalesOrderItem> = [
    {
      title: 'EXTERNAL ID', //NOTE: ONLY for ECOMM, External ID is actually UPC. They are the same
      className: s.external_id,
      key: 'gtin',
      width: 200,
      render: (_, item) => item.catalogProduct?.gtin12 ?? item?.product?.upc ?? '',
      align: 'left' as AlignType,
      sorter: (a, b) => {
        if (a.catalogProduct) {
          return safeLocaleCompare(a.catalogProduct?.gtin12, b.catalogProduct?.gtin12);
        } else {
          return safeLocaleCompare(a.product?.upc, b.product?.upc);
        }
      }
    },
    {
      title: 'ITEM DESCRIPTION',
      className: s.description,
      key: 'productName',
      align: 'left' as AlignType,
      render: (_, item) => item.catalogProduct?.name ?? item.product?.name ?? '',
      sorter: (a, b) => {
        if (a.catalogProduct) {
          return safeLocaleCompare(a.catalogProduct?.name, b.catalogProduct?.name);
        } else {
          return safeLocaleCompare(a.product?.name, b.product?.name);
        }
      }
    },
    {
      title: 'ALLOCATED QTY',
      className: s.allocated_qty_ecomm,
      key: 'allocatedQuantity',
      width: 150,
      align: 'right' as AlignType,
      sorter: (a, b) => safeNumberComparator(a.quantityOrdered, b.quantityOrdered),
      render: (_, item) => {
        return `${
          item.quantityOrdered && item.quantityOrdered !== null
            ? new Intl.NumberFormat().format(item.quantityOrdered)
            : '0'
        } ${item?.unitOfMeasure || 'EA'}`;
      }
    }
  ];

  let AMOUNT_OF_SUMMARY_COLUMNS = 5;
  const whdColumns: ColumnsType<ModifiedSalesOrderItem> = [
    {
      title: 'UPC',
      key: 'gtin',
      width: 160,
      render: (_, item) => item.catalogProduct?.gtin12 ?? item.product?.upc ?? '',
      sorter: (a, b) => {
        if (a.catalogProduct) {
          return safeLocaleCompare(a.catalogProduct?.gtin12, b.catalogProduct?.gtin12);
        } else {
          return safeLocaleCompare(a.product?.upc, b.product?.upc);
        }
      }
    },
    {
      title: 'EXTERNAL ID',
      key: 'externalId',
      width: 150,
      render: (_, item) => item.externalId,
      sorter: (a, b) => safeLocaleCompare(a.externalId, b.externalId)
    },
    {
      title: 'ITEM DESCRIPTION',
      key: 'productName',
      render: (_, item) => item.catalogProduct?.name ?? item.product?.name ?? '',
      sorter: (a, b) => {
        if (a.catalogProduct) {
          return safeLocaleCompare(a.catalogProduct?.name, b.catalogProduct?.name);
        } else {
          return safeLocaleCompare(a.product?.name, b.product?.name);
        }
      }
    },
    {
      title: 'PRODUCT TYPE',
      key: 'productType',
      width: 150,
      align: 'center' as AlignType,
      render: (_, item) => (
        <span className={s.capitalize}>{(item.catalogProduct ?? item.product)?.productType}</span>
      ),
      sorter: (a, b) =>
        safeLocaleCompare(
          (a.catalogProduct ?? a.product)?.productType,
          (b.catalogProduct ?? b.product)?.productType
        )
    },
    {
      title: 'ORDER QTY',
      key: 'orderQuantity',
      width: 120,
      align: 'right' as AlignType,
      sorter: (a, b) => safeNumberComparator(a.quantityOrdered, b.quantityOrdered),
      render: (_, item) => {
        return `${
          item.quantityOrdered && item.quantityOrdered !== null
            ? new Intl.NumberFormat().format(item.quantityOrdered)
            : '0'
        } ${item?.unitOfMeasure}`;
      }
    },
    {
      title: 'ALLOCATED QTY',
      key: 'promised',
      width: 120,
      align: 'right' as AlignType,
      sorter: (a, b) =>
        safeNumberComparator(
          a.acceptedUnits !== null ? a.acceptedUnits : a.quantityOrdered,
          b.acceptedUnits !== null ? b.acceptedUnits : b.quantityOrdered
        ),
      render: (_, item) => {
        const value = getAllocatedQty(item, true);
        const unit = item?.unitOfMeasure;
        if (orderStatus === PO_STATUS_NEW || orderStatus === PO_STATUS_SCRUBBED) {
          return (
            <EditingAllocatedQty
              measurementSystem={purchaseOrder?.tradingPartner?.measurementSystem}
              productId={item?.product?.id}
              catalogProductId={item.catalogProduct?.id}
              salesOrderItemId={item?.id}
              item={item}
            />
          );
        }
        return `${value} ${unit}`;
      }
    },
    {
      title: 'ALLOCATED WEIGHT',
      key: 'weight',
      width: 120,
      align: 'right' as AlignType,
      sorter: (a, b) => safeNumberComparator(a.weight, b.weight),
      render: (_, item) => {
        if (item?.weight) {
          return purchaseOrder?.tradingPartner?.measurementSystem === MeasurementSystem.METRIC ? (
            <AlloyTooltip
              data-testid="help-metric-tooltip"
              className={s.help}
              overlayStyle={{ whiteSpace: 'pre-line' }}
              title={`Product weight ${Intl.NumberFormat().format(item.weight)} kg
              Pallets weight ${Intl.NumberFormat().format(
                Math.round(item.palletsWeightInKilograms || 0)
              )} kg`}
            >
              {new Intl.NumberFormat().format(
                Math.round(item.weight + (item.palletsWeightInKilograms || 0))
              )}{' '}
              kg
            </AlloyTooltip>
          ) : (
            <AlloyTooltip
              data-testid="help-imperial-tooltip"
              className={s.help}
              overlayStyle={{ whiteSpace: 'pre-line' }}
              title={`Product weight ${Intl.NumberFormat().format(item.weight)} lbs
              Pallets weight ${Intl.NumberFormat().format(
                Math.round(item.palletsWeightInLbs || 0)
              )} lbs`}
            >
              {new Intl.NumberFormat().format(
                Math.round(item.weight + (item.palletsWeightInLbs || 0))
              )}{' '}
              lbs
            </AlloyTooltip>
          );
        } else {
          return '-';
        }
      }
    },
    {
      title: 'NO. OF PALLETS',
      key: 'numberOfPallets',
      width: 90,
      align: 'right' as AlignType,
      render: (_, item) => item.pallets || '-'
    },
    {
      title: 'CUT REASON',
      key: 'cutReasons',
      width: 232,
      render: (_, item) => <CutReasonDropdown purchaseOrder={purchaseOrder} item={item} />
    }
  ];

  if (['ACKNOWLEDGED', 'DELIVERED'].includes(orderStatus)) {
    whdColumns.splice(5, 0, {
      title: 'DELIVERED QTY',
      key: 'deliveredQty',
      width: 120,
      align: 'right' as AlignType,
      render: (_, item) => {
        return `${
          item.quantityDelivered && item.quantityDelivered !== null
            ? new Intl.NumberFormat().format(item.quantityDelivered)
            : '0'
        } ${item?.unitOfMeasure}`;
      }
    });

    AMOUNT_OF_SUMMARY_COLUMNS++;
  }

  if (purchaseOrder.purchaseOrderItems.find((item) => item.purchaseOrderItemWarnings.length > 0)) {
    whdColumns.splice(0, 0, {
      className: s.product_warning_column,
      key: 'product_warning',
      width: 45,
      render: (_, item) => {
        const poItem = purchaseOrder.purchaseOrderItems.find(
          (i) => i.externalId === item.externalId
        );

        const hasWarnings =
          poItem?.purchaseOrderItemWarnings && poItem.purchaseOrderItemWarnings.length > 0;

        if (item && hasWarnings) {
          const warning = (poItem?.purchaseOrderItemWarnings || [])
            .map((e) => PO_ERROR_MAP[e] || e)
            .join('\n');

          return (
            <AlloyTooltip
              data-testid="warning-display-tooltip"
              placement="right"
              overlayStyle={{ whiteSpace: 'pre-line' }}
              title={warning}
            >
              <ExclamationCircleFilled
                data-testid="product-warning"
                className={s.product_warning_symbol}
              />
            </AlloyTooltip>
          );
        }
      }
    });
  }

  //TODO: do we need to filter out rows that have warnings/errors? we used to filter out if zeroPromise was true
  //TODO: we need to rely on availableActions here instead of order type -- future refactor should involve BE could create a boolean called "isSelectable"
  const hasSelection = !(
    orderStatus === PO_STATUS_SUBMITTED ||
    purchaseOrder.tradingPartner?.deliveryType === 'DSD' ||
    purchaseOrder.tradingPartner?.deliveryType === 'ECOMM'
  );

  const lengthOfWHDColumns = whdColumns.length + (hasSelection ? 1 : 0);
  const rowSpan = lengthOfWHDColumns - AMOUNT_OF_SUMMARY_COLUMNS;

  const singleUom =
    items
      .map((item) => item.unitOfMeasure)
      .filter((value, index, self) => self.indexOf(value) === index).length === 1
      ? items[0].unitOfMeasure
      : 'MIXED';

  const getColumns = () => {
    if (purchaseOrder.tradingPartner?.deliveryType === 'DSD') {
      return dsdColumns;
    } else if (purchaseOrder.tradingPartner?.deliveryType === 'ECOMM') {
      return ecommColumns;
    } else {
      return whdColumns;
    }
  };

  return (
    <>
      <AlloyTable
        data-testid="sales-order-items-table"
        className={s.sales_order_items_table}
        sticky={{ offsetHeader: 85 }}
        scroll={
          purchaseOrder.tradingPartner?.deliveryType === 'DSD' ||
          purchaseOrder.tradingPartner?.deliveryType === 'ECOMM'
            ? { x: '100%' }
            : { x: 1560 } // for WHD table
        }
        dataSource={salesOrderItems}
        columns={getColumns()}
        rowKey={(item) => item?.id}
        pagination={false}
        rowSelection={
          hasSelection
            ? {
                onSelect: (_record, _selected, selectedRows) =>
                  onSelectAction(selectedRows as SalesOrderItem[]),
                onSelectAll: (_selected, selectedRows) => {
                  onSelectAction(selectedRows as SalesOrderItem[]);
                },
                type: 'checkbox',
                selectedRowKeys: selectedKeys
              }
            : undefined
        }
        summary={(items) => {
          return (
            <>
              <Summary.Row data-testid="summary-row" className={s.summary}>
                <>
                  {/************** DSD SUMMARY ROW **************/}
                  {purchaseOrder.tradingPartner?.deliveryType === 'DSD' && (
                    <>
                      <Summary.Cell
                        index={0}
                        colSpan={3}
                        className={clsx(s.total, s.right_aligned)}
                      >
                        <span className={s.total}>Total</span>
                      </Summary.Cell>

                      {/* Order Qty column TODO: need to confirm this is the right datapoint for this total */}
                      <Summary.Cell
                        index={1}
                        colSpan={1}
                        className={clsx(s.total, s.right_aligned)}
                      >
                        {items.reduce(
                          (total, { quantityOriginal }) =>
                            quantityOriginal ? (total += quantityOriginal) : total,
                          0
                        )}{' '}
                        {singleUom}
                      </Summary.Cell>

                      {/* Promised Qty column  TODO: need to confirm this is the right datapoint for this total */}
                      <Summary.Cell
                        index={2}
                        colSpan={1}
                        className={clsx(s.total, s.right_aligned)}
                      >
                        {items.reduce(
                          (total, { quantityOrdered }) =>
                            quantityOrdered ? (total += quantityOrdered) : total,
                          0
                        )}{' '}
                        {singleUom}
                      </Summary.Cell>

                      {/* Delivered Qty column */}
                      <Summary.Cell
                        index={3}
                        colSpan={1}
                        className={clsx(s.total, s.delivered_qty_right_aligned)}
                      >
                        {items.reduce(
                          (total, { quantityDelivered }) =>
                            quantityDelivered ? (total += quantityDelivered) : total,
                          0
                        )}{' '}
                        {singleUom}
                      </Summary.Cell>
                    </>
                  )}

                  {/************** ECOMM SUMMARY ROW **************/}
                  {purchaseOrder.tradingPartner?.deliveryType === 'ECOMM' && (
                    <>
                      <Summary.Cell index={0} colSpan={3}>
                        <span className={s.summary_right_aligned}>
                          Total
                          <span className={s.total_qty_ordered}>
                            {` ${items.reduce(
                              (total, { quantityOrdered }) =>
                                quantityOrdered ? (total += quantityOrdered) : total,
                              0
                            )}${' '}
                              ${singleUom}
                            `}
                          </span>
                        </span>
                      </Summary.Cell>
                    </>
                  )}

                  {/*************  WHD SUMMARY ROW *************/}
                  {purchaseOrder.tradingPartner?.deliveryType === 'WHD' && (
                    <>
                      <Summary.Cell
                        index={0}
                        colSpan={rowSpan}
                        className={clsx(s.total, s.right_aligned)}
                      >
                        <span className={s.total}>Total</span>
                      </Summary.Cell>
                      {/* total Order Quantity */}
                      <Summary.Cell
                        index={1}
                        colSpan={1}
                        className={clsx(s.total, s.right_aligned)}
                      >
                        {items.reduce(
                          (total, { quantityOrdered }) =>
                            quantityOrdered ? (total += quantityOrdered) : total,
                          0
                        )}{' '}
                        {singleUom}
                      </Summary.Cell>
                      {/* total Delivered Qty */}
                      {['ACKNOWLEDGED', 'DELIVERED'].includes(orderStatus) && (
                        <Summary.Cell
                          index={3}
                          colSpan={1}
                          className={clsx(s.total, s.right_aligned)}
                        >
                          {items.reduce(
                            (total, { quantityDelivered }) =>
                              quantityDelivered ? (total += quantityDelivered) : total,
                            0
                          )}{' '}
                          {singleUom}
                        </Summary.Cell>
                      )}
                      {/* total Allocated Qty */}
                      <Summary.Cell
                        index={2}
                        colSpan={1}
                        className={clsx(s.total, s.right_aligned)}
                      >
                        {items.filter(({ acceptedUnits }) => acceptedUnits !== null).length === 0
                          ? '-'
                          : items.reduce(
                              (total, { acceptedUnits }) =>
                                acceptedUnits ? (total += acceptedUnits) : total,
                              0
                            )}{' '}
                        {singleUom}
                      </Summary.Cell>
                      {/* total Allocated Weight */}
                      <Summary.Cell
                        index={4}
                        colSpan={1}
                        className={clsx(s.total, s.right_aligned)}
                      >
                        {purchaseOrder?.tradingPartner?.measurementSystem ===
                        MeasurementSystem.METRIC
                          ? new Intl.NumberFormat().format(
                              Math.round(
                                items.reduce(
                                  (total, { weight, palletsWeightInKilograms }) =>
                                    (weight || 0) + (palletsWeightInKilograms || 0) + total,
                                  0
                                )
                              )
                            ) + ' kg'
                          : new Intl.NumberFormat().format(
                              Math.round(
                                items.reduce(
                                  (total, { weight, palletsWeightInLbs }) =>
                                    (weight || 0) + (palletsWeightInLbs || 0) + total,
                                  0
                                )
                              )
                            ) + ' lbs'}
                      </Summary.Cell>
                      {/* total No. of Pallets */}
                      <Summary.Cell
                        index={6}
                        colSpan={1}
                        className={clsx(s.total, s.right_aligned)}
                      >
                        {items.reduce(
                          (total, { pallets }) => (pallets ? (total += Number(pallets)) : total),
                          0
                        )}
                      </Summary.Cell>
                      <Summary.Cell index={7} colSpan={1}></Summary.Cell>
                    </>
                  )}
                </>
              </Summary.Row>
            </>
          );
        }}
      />
    </>
  );
};

export default SalesOrderItemTable;
