import { InfoCircleOutlined } from '@ant-design/icons';
import { EMPTY } from 'common/constants';
import { AlloyTooltip } from 'components/ui/AlloyTooltip/AlloyTooltip';
import {
  StatChangeIndicator,
  StatChangeIndicatorProps
} from 'components/ui/StatChangeIndicator/StatChangeIndicator';
import React from 'react';
import s from './StatTile.module.scss';

interface StatsTileProps {
  title: string;
  value: React.ReactChild;
  loading?: boolean;
  tooltip?: string;
  change?: StatChangeIndicatorProps;
  changeText?: string;
  changeTooltip?: string;
}

export const StatTile = ({
  title,
  value,
  loading,
  tooltip,
  change,
  changeText,
  changeTooltip
}: StatsTileProps) => {
  return (
    <div className={s.tile}>
      <div className={s.title}>
        {title}
        {tooltip && (
          <AlloyTooltip title={tooltip} placement="topRight">
            <InfoCircleOutlined className={s.tooltip_icon} />
          </AlloyTooltip>
        )}
      </div>
      <div className={s.bottom}>
        {loading ? (
          <div className={s.loading} />
        ) : (
          <>
            <div className={s.value}>{value}</div>
          </>
        )}
      </div>
      <AlloyTooltip title={change && changeTooltip}>
        <div className={s.change}>
          {loading ? (
            <div className={s.loading_additional} />
          ) : (
            <>
              {change && <StatChangeIndicator {...change} />}
              {change && changeText && <div className={s.changeText}>{changeText}</div>}
            </>
          )}
        </div>
      </AlloyTooltip>
    </div>
  );
};

export const NumberAndText = ({
  text,
  number = '',
  tooltip
}: {
  text?: string;
  number: string | number;
  tooltip?: string | number;
}) => {
  return (
    <div className={s.number_and_text}>
      {tooltip ? (
        <AlloyTooltip title={tooltip} placement="bottom">
          <span className={s.number}>{number}</span>
        </AlloyTooltip>
      ) : (
        <span className={s.number}>{number}</span>
      )}
      {text && number !== EMPTY && <span className={s.text}>{text}</span>}
    </div>
  );
};
