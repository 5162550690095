import { convertToMM } from 'common/helpers/convertToMM';
import { isContinuous } from 'common/helpers/graph';
import { notEmpty } from 'common/helpers/notEmpty';
import { yearWeekSorter } from 'common/helpers/periodHelper';
import { FiscalCalendarWeekInputWithWeekOnly } from 'components/PeriodCalendar/helpers';
import { StatChangeIndicatorProps } from 'components/ui/StatChangeIndicator/StatChangeIndicator';
import currency from 'currency.js';
import { ChargebackDetailsReportByPeriod } from 'graphql/__generated__/types';

export function calculateChange(
  actualPeriodValue: number | undefined,
  comparisonPeriodValue: number | undefined,
  shouldShowChange: boolean,
  isMoreBetter: boolean,
  valueFormatter: (string: string) => string
): StatChangeIndicatorProps | undefined {
  if (actualPeriodValue === undefined || comparisonPeriodValue === undefined) return undefined;

  const diff = actualPeriodValue - comparisonPeriodValue;

  if (shouldShowChange && !Number.isNaN(diff)) {
    const isPositive = diff > 0 ? isMoreBetter : diff < 0 ? !isMoreBetter : undefined;

    return {
      value: valueFormatter(Math.abs(diff).toString()),
      change: diff > 0 ? ('increase' as const) : diff < 0 ? ('decrease' as const) : undefined,
      isPositive: isPositive
    };
  }

  // Return undefined if conditions are not met
  return undefined;
}

export type ChargebackGroupType = 'gross' | 'net' | 'waived/approved' | 'pending';

export const getFilterByChargebackGroupType = (type: ChargebackGroupType) => {
  const waivedOrApproved = [
    'Reversed',
    'Grace Period - Notification Only',
    'Grace Waived - Notification Only',
    'Waived',
    'Waived - Notification Only',
    'Dispute Approved'
  ].map((x) => x.toLowerCase());

  const pending = ['In Dispute', 'Disputed Needs More Info'].map((x) => x.toLowerCase());

  return <T extends { status?: string | null }>(item: T) => {
    const status = (item.status || '').toLowerCase();

    if (type === 'gross') return true;
    if (type === 'net') return !waivedOrApproved.includes(status) && !pending.includes(status);
    if (type === 'waived/approved') return waivedOrApproved.includes(status);
    if (type === 'pending') return pending.includes(status);

    return true;
  };
};

type WeekYear = { week: number; year: number };

export const prepareChargebacksData = ({
  data,
  mode,
  fiscalCalendarWeeks,
  actualPeriod,
  comparisonPeriod,
  shouldShowChange
}: {
  data: (Pick<ChargebackDetailsReportByPeriod, 'financialCharge' | 'totalValue' | 'status'> & {
    fiscalCalendarWeek: WeekYear;
  })[];
  mode: ChargebackGroupType;
  fiscalCalendarWeeks: FiscalCalendarWeekInputWithWeekOnly[];
  actualPeriod: WeekYear;
  comparisonPeriod: WeekYear;
  shouldShowChange: boolean;
}) => {
  const mapDataWithPeriodsAndSum = (
    data: (Pick<ChargebackDetailsReportByPeriod, 'financialCharge'> & {
      fiscalCalendarWeek: WeekYear;
    })[],
    periods: FiscalCalendarWeekInputWithWeekOnly[]
  ) => {
    const sortedData = [...periods].sort((a, b) => yearWeekSorter(a, b));

    const dataWithFilledHoles = (sortedData || []).map((fiscalCalendarWeek) => {
      const foundDataForPeriod = data.filter(
        (x) =>
          x.fiscalCalendarWeek.week === fiscalCalendarWeek.week &&
          fiscalCalendarWeek.year === x.fiscalCalendarWeek.year
      );

      const total = foundDataForPeriod
        .map((x) => x.financialCharge as string)
        .reduce((accumulator, value) => {
          return accumulator.add(value);
        }, currency(0)).value;

      return {
        fiscalCalendarWeek,
        value: total
      };
    });

    return dataWithFilledHoles;
  };

  const relatedData = mapDataWithPeriodsAndSum(
    (data || [])
      .filter((x) => !x.totalValue)
      .filter(getFilterByChargebackGroupType(mode))
      .filter(notEmpty),
    fiscalCalendarWeeks
  );

  const total = relatedData
    .map((x) => x.value)
    .reduce((accumulator, value) => {
      return accumulator.add(value);
    }, currency(0)).value;

  const isContinious = isContinuous(relatedData.map((x) => x.fiscalCalendarWeek.week));

  const actualPeriodValue = relatedData.find(
    (x) =>
      x.fiscalCalendarWeek.week === actualPeriod.week &&
      x.fiscalCalendarWeek.year === actualPeriod.year
  )?.value;
  const comparisonPeriodValue = relatedData.find(
    (x) =>
      x.fiscalCalendarWeek.week === comparisonPeriod.week &&
      x.fiscalCalendarWeek.year === comparisonPeriod.year
  )?.value;

  const change = calculateChange(
    actualPeriodValue,
    comparisonPeriodValue,
    shouldShowChange,
    false,
    (value) => convertToMM(value)
  );

  return {
    graphData: relatedData,
    change,
    isContinious,
    total
  };
};
