import { useQuery } from '@apollo/client';
import clsx from 'clsx';
import { TradingPartnerDefaultConfigsDocument } from 'pages/TradingPartnersPage/gql/__generated__/tradingPartnerDefaultConfigs.query';
import React, { useState } from 'react';
import { TradingPartner } from '../../../../TradingPartnerViewDrawer';
import { ViewField } from 'components/Common/ViewMode/ViewField/ViewField';
import { ViewRow } from 'components/Common/ViewMode/ViewRow/ViewRow';
import s from './IntegrationsViewTab.module.scss';
import { DisabledIntegration } from 'components/Common/ViewMode/DisabledIntegration/DisabledIntegration';
import { ChannelAdvisorProfilesDocument } from 'pages/TradingPartnersPage/gql/__generated__/channelAdvisorProfiles.query';
import { AlloySegmented } from 'components/ui/AlloySegmented/AlloySegmented';

type EdiConfig = NonNullable<TradingPartner['ediConfigExternal']>;
type EmailConfig = NonNullable<TradingPartner['emailConfigSalesOrderExport']>;

export const IntegrationsViewTab = ({ tradingPartner }: { tradingPartner: TradingPartner }) => {
  const { data: defaultConfigsData } = useQuery(TradingPartnerDefaultConfigsDocument);

  const [ediConfigurationSelected, setEdiConfigurationSelected] = useState<string>('external');
  const [emailConfigSalesOrderSelected, setEmailConfigSalesOrderSelectedSelected] =
    useState<string>('export');

  const { data: channelAdvisoryProfileData } = useQuery(ChannelAdvisorProfilesDocument);

  const channelAdvisorConfig = (
    <>
      <ViewField
        type="INPUT"
        title="Site Name"
        value={tradingPartner?.channelAdvisorConfig?.siteName}
      />
      <div className={s.row}>
        <ViewField
          type="INPUT"
          title="Site ID"
          value={tradingPartner?.channelAdvisorConfig?.siteId}
        />
        <ViewField
          type="INPUT"
          title="Profile ID"
          value={
            channelAdvisoryProfileData?.channelAdvisorProfiles.find(
              (profile) => profile.profileId === tradingPartner?.channelAdvisorConfig?.profileId
            )?.accountName
          }
        />
      </div>
      <div className={s.row}>
        <ViewField
          type="INPUT"
          title="Special Instructions Prefix"
          value={tradingPartner?.channelAdvisorConfig?.specialInstructionsPrefix}
        />
        <ViewField
          type="INPUT"
          title="Special Instructions Postfix"
          value={tradingPartner?.channelAdvisorConfig?.specialInstructionsPostfix}
        />
      </div>
      <ViewField
        type="INPUT"
        title="Private Notes"
        value={tradingPartner?.channelAdvisorConfig?.privateNotes}
      />
    </>
  );

  const ochConfig = (
    <>
      <div className={s.row}>
        <ViewField type="INPUT" title="Scope" value={tradingPartner.ochConfig?.scope} />
        <ViewField
          type="INPUT"
          title="Source System ID"
          value={tradingPartner.ochConfig?.sourceSystemId}
        />
      </div>
      <ViewField type="INPUT" title="Region" value={tradingPartner.ochConfig?.region} />
    </>
  );

  const pepdirectConfig = (
    <>
      <ViewField title="App ID" value={tradingPartner?.pepdirectConfig?.appId} type="INPUT" />
    </>
  );

  const shippingConfig = (
    <>
      <ViewField
        className={s.margin_bottom}
        type="SWITCH"
        title="Routing Enabled"
        value={tradingPartner?.shippingConfig?.routingEnabled}
      />
      <ViewField
        className={s.margin_bottom}
        type="SWITCH"
        title="Tendering Enabled"
        value={tradingPartner?.shippingConfig?.tenderingEnabled}
      />
      <div className={s.row}>
        <ViewField
          type="INPUT"
          title="Pickup Days"
          value={`${tradingPartner.shippingConfig?.minimalPickupDays} MIN - ${tradingPartner.shippingConfig?.maximalPickupDays} MAX`}
        />
        <ViewField type="INPUT" title="Shipping SLA" value={tradingPartner.shippingConfig?.sla} />
      </div>
      <ViewField
        type="LIST"
        title="Available Carrier Codes"
        value={tradingPartner?.shippingConfig?.availableCarrierCodes?.filter(
          (code): code is string => !!code
        )}
      />
    </>
  );

  const invoiceConfig = (
    <>
      {/* Bill To */}
      <div className={s.row}>
        <ViewField
          type="INPUT"
          title="Bill To Name"
          value={tradingPartner?.invoiceConfig?.billTo?.name}
        />
        <ViewField
          type="INPUT"
          title="Bill To Party ID"
          value={tradingPartner?.invoiceConfig?.billTo?.partyId}
        />
      </div>
      <div className={s.row}>
        <ViewField
          type="INPUT"
          title="Bill To Address"
          value={tradingPartner?.invoiceConfig?.billTo?.address}
        />
        <ViewField
          type="INPUT"
          title="Bill To City Name"
          value={tradingPartner?.invoiceConfig?.billTo?.cityName}
        />
      </div>
      <div className={s.row}>
        <ViewField
          type="INPUT"
          title="Bill To State"
          value={tradingPartner?.invoiceConfig?.billTo?.state}
        />
        <ViewField
          type="INPUT"
          title="Bill To Postal Code"
          value={tradingPartner?.invoiceConfig?.billTo?.postalCode}
        />
      </div>
      <div className={s.row}>
        <ViewField
          type="INPUT"
          title="Bill To Country Code"
          value={tradingPartner?.invoiceConfig?.billTo?.countryCode}
        />
        <ViewField
          type="INPUT"
          title="Bill To Tax Registration Number"
          value={tradingPartner?.invoiceConfig?.billTo?.taxRegistrationNumber}
        />
      </div>

      {/* Remittance */}
      <div className={s.row}>
        <ViewField
          type="INPUT"
          title="Remittance Name"
          value={tradingPartner?.invoiceConfig?.remittance?.name}
        />
        <ViewField
          type="INPUT"
          title="Remittance Party ID"
          value={tradingPartner?.invoiceConfig?.remittance?.partyId}
        />
      </div>
      <div className={s.row}>
        <ViewField
          type="INPUT"
          title="Remittance Address"
          value={tradingPartner?.invoiceConfig?.remittance?.address}
        />
        <ViewField
          type="INPUT"
          title="Remittance City Name"
          value={tradingPartner?.invoiceConfig?.remittance?.cityName}
        />
      </div>
      <div className={s.row}>
        <ViewField
          type="INPUT"
          title="Remittance State"
          value={tradingPartner?.invoiceConfig?.remittance?.state}
        />
        <ViewField
          type="INPUT"
          title="Remittance Postal Code"
          value={tradingPartner?.invoiceConfig?.remittance?.postalCode}
        />
      </div>
      <div className={s.row}>
        <ViewField
          type="INPUT"
          title="Remittance Country Code"
          value={tradingPartner?.invoiceConfig?.remittance?.countryCode}
        />
        <ViewField
          type="INPUT"
          title="Remittance Tax Registration Number"
          value={tradingPartner?.invoiceConfig?.remittance?.taxRegistrationNumber}
        />
      </div>

      {/* Terms of Sale */}
      <div className={s.row}>
        <ViewField
          type="INPUT"
          title="Terms of Sale Basis Date Code"
          value={tradingPartner?.invoiceConfig?.termsOfSale?.basisDateCode}
        />
        <ViewField
          type="INPUT"
          title="Terms of Sale Discount Days Due"
          value={tradingPartner?.invoiceConfig?.termsOfSale?.discountDaysDue}
        />
      </div>
      <div className={s.row}>
        <ViewField
          type="INPUT"
          title="Terms of Sale Discount Percent"
          value={tradingPartner?.invoiceConfig?.termsOfSale?.discountPercent}
        />
        <ViewField
          type="INPUT"
          title="Terms of Sale Net Days"
          value={tradingPartner?.invoiceConfig?.termsOfSale?.netDays}
        />
      </div>
      <div className={s.row}>
        <ViewField
          type="INPUT"
          title="Terms of Sale Payment Type"
          value={tradingPartner?.invoiceConfig?.termsOfSale?.paymentType}
        />
      </div>
    </>
  );

  const getEdiConfigurationContent = (ediConfig: EdiConfig) => (
    <>
      <div className={s.row}>
        <ViewField type="INPUT" title="GS Local Code" value={ediConfig.gsLocalCode} />
        <ViewField type="INPUT" title="GS Remote Code" value={ediConfig.gsRemoteCode} />
      </div>
      <div className={s.subtitle}>ISA Information</div>
      <div className={s.isa_container}>
        <div className={s.row}>
          <ViewField type="INPUT" title="ISA Local ID" value={ediConfig.isaLocalId} />
          <ViewField type="INPUT" title="ISA Local Qualifier" value={ediConfig.isaLocalQualifier} />
        </div>
        <div className={s.row}>
          <ViewField type="INPUT" title="ISA Remote ID" value={ediConfig.isaRemoteId} />
          <ViewField
            type="INPUT"
            title="ISA Remote Qualifier"
            value={ediConfig.isaRemoteQualifier}
          />
        </div>
        <div className={s.row}>
          <ViewField
            type="INPUT"
            title="ISA Component Element Separator"
            value={ediConfig.isaComponentElementSeparator}
          />
          <ViewField
            type="INPUT"
            title="ISA Interchange Control Version Number"
            value={ediConfig.isaInterchangeControlVersionNumber}
          />
        </div>
        <ViewField
          type="INPUT"
          title="ISA Repetition Separator "
          value={ediConfig.isaRepetitionSeparator}
          className={s.remove_margin_bottom}
        />
      </div>
      <div className={s.row}>
        <ViewField type="INPUT" title="Supplier ID" value={ediConfig.supplierId} />
        <ViewField type="INPUT" title="Supplier ID Path" value={ediConfig.supplierIdPath} />
      </div>
      <div className={s.row}>
        <ViewField type="INPUT" title="Format" value={ediConfig.format} />
        <ViewField type="INPUT" title="Webhook Endpoint" value={ediConfig.webhookEndpoint} />
      </div>
      <ViewField
        type="INPUT"
        title="Interchange Usage Indicator"
        value={ediConfig.interchangeUsageIndicator}
      />
    </>
  );

  const ediMessagePurchaseOrder850 = (
    <>
      <ViewField
        type="SWITCH"
        title="Purchase Order (850)"
        value={!!tradingPartner.ediSelfServiceConfig?.ediMessageConfig?.edi850Config}
        className={
          tradingPartner.ediSelfServiceConfig?.ediMessageConfig?.edi850Config
            ? s.margin_bottom_small
            : s.margin_bottom
        }
      />
      {!!tradingPartner.ediSelfServiceConfig?.ediMessageConfig?.edi850Config && (
        <div className={clsx(s.subconfig_container, s.margin_bottom)}>
          <div className={s.subtitle}>EDI customizations</div>
          <div className={s.row}>
            <ViewField
              type="INPUT"
              title="Ship-To/Supplier info segment"
              value={tradingPartner.ediSelfServiceConfig?.ediMessageConfig.edi850Config.shipToPath}
            />
            <ViewField
              type="INPUT"
              title="Product unit of measurement"
              value={
                tradingPartner.ediSelfServiceConfig?.ediMessageConfig.edi850Config
                  .productUnitOfMeasurement
              }
            />
          </div>
          <div className={s.subtitle}>Business logic</div>
          <ViewField
            type="INPUT"
            title="Pass UPC through to BU"
            value={
              tradingPartner.ediSelfServiceConfig.ediMessageConfig.edi850Config.passThroughUpcToBu
                ? 'Yes'
                : 'No'
            }
            className={s.padding_bottom}
          />
        </div>
      )}
    </>
  );

  const ediMessagePurchaseOrderAcknowledgements855 = (
    <>
      <ViewField
        type="SWITCH"
        title="Purchase Order Acknowledgements (855)"
        value={!!tradingPartner.ediSelfServiceConfig?.ediMessageConfig?.edi855Config}
        className={
          tradingPartner.ediSelfServiceConfig?.ediMessageConfig?.edi855Config
            ? s.margin_bottom_small
            : s.margin_bottom
        }
      />
      {!!tradingPartner.ediSelfServiceConfig?.ediMessageConfig?.edi855Config && (
        <div className={clsx(s.subconfig_container, s.margin_bottom)}>
          <div className={s.subtitle}>EDI customizations</div>
          <ViewField
            type={
              tradingPartner.ediSelfServiceConfig?.ediMessageConfig.edi855Config
                .ignoreAdditionalItems
                ? 'INPUT'
                : 'LIST'
            }
            title="PO1 - Additional item(s) added"
            value={
              tradingPartner.ediSelfServiceConfig?.ediMessageConfig.edi855Config
                .ignoreAdditionalItems
                ? 'Ignore'
                : tradingPartner.ediSelfServiceConfig?.ediMessageConfig.edi855Config.ediAdditionalItemConfig?.map(
                    (item) => `PO10${item?.position} - ${item?.value || 'Empty'}`
                  )
            }
          />
          <div className={s.row}>
            <ViewField
              type="INPUT"
              title="MTX02 Item not available"
              value={
                tradingPartner.ediSelfServiceConfig?.ediMessageConfig.edi855Config
                  ?.mtx02UnavailableItem
              }
            />
            <ViewField
              type="INPUT"
              title="MTX02 Item invalid/rejected"
              value={
                tradingPartner.ediSelfServiceConfig?.ediMessageConfig.edi855Config?.mtx02InvalidItem
              }
            />
          </div>
          <div className={s.subtitle}>Business logic</div>
          <ViewField
            type="INPUT"
            title="Match PO101 sequence to original 850"
            value={
              tradingPartner.ediSelfServiceConfig.ediMessageConfig.edi855Config?.match850Sequence
                ? 'Yes'
                : 'No'
            }
          />
          <ViewField
            type="CHECKBOX"
            title="Release document to Trading Partner"
            value={
              tradingPartner.ediSelfServiceConfig.ediMessageConfig.edi855Config
                ?.releaseDocumentToTradingPartner
            }
            className={s.padding_bottom}
          />
        </div>
      )}
    </>
  );

  const ediMessageAsn856 = (
    <>
      <ViewField
        type="SWITCH"
        title="ASN (856)"
        value={!!tradingPartner.ediSelfServiceConfig?.ediMessageConfig?.edi856Config}
        className={
          tradingPartner.ediSelfServiceConfig?.ediMessageConfig?.edi856Config
            ? s.margin_bottom_small
            : s.margin_bottom
        }
      />
      {!!tradingPartner.ediSelfServiceConfig?.ediMessageConfig?.edi856Config && (
        <div className={clsx(s.subconfig_container, s.margin_bottom)}>
          <div className={s.subtitle}>EDI customizations</div>
          <ViewField
            type={
              tradingPartner.ediSelfServiceConfig?.ediMessageConfig.edi856Config
                .ignoreAdditionalItems
                ? 'INPUT'
                : 'LIST'
            }
            title="LIN - Additional item(s) added"
            value={
              tradingPartner.ediSelfServiceConfig?.ediMessageConfig.edi856Config
                .ignoreAdditionalItems
                ? 'Ignore'
                : tradingPartner.ediSelfServiceConfig?.ediMessageConfig.edi856Config.ediAdditionalItemConfig?.map(
                    (item) => `LIN0${item?.position} - ${item?.value || 'Empty'}`
                  )
            }
          />
          <div className={s.subtitle}>Business logic</div>
          <div className={s.row}>
            <ViewField
              type="INPUT"
              title="Match LIN01 Sequence to original 850"
              value={
                tradingPartner.ediSelfServiceConfig.ediMessageConfig.edi856Config?.match850Sequence
                  ? 'Yes'
                  : 'No'
              }
            />
            <ViewField
              type="INPUT"
              title="Pass through Ship-to from BU"
              value={
                tradingPartner.ediSelfServiceConfig?.ediMessageConfig.edi856Config
                  ?.passThroughShipToFromBu
                  ? 'Yes'
                  : 'No'
              }
            />
          </div>
          <ViewField
            type="INPUT"
            title="Match LIN01 Sequence to original 850"
            value={
              tradingPartner.ediSelfServiceConfig.ediMessageConfig.edi856Config
                ?.ctt01TargetSegmentCount
            }
          />
          <ViewField
            type="CHECKBOX"
            title="Release document to Trading Partner"
            value={
              tradingPartner.ediSelfServiceConfig.ediMessageConfig.edi856Config
                ?.releaseDocumentToTradingPartner
            }
            className={s.padding_bottom}
          />
        </div>
      )}
    </>
  );

  const ediMessageInvoice810 = (
    <>
      <ViewField
        type="SWITCH"
        title="Invoice (810)"
        value={!!tradingPartner.ediSelfServiceConfig?.ediMessageConfig?.edi810Config}
        className={
          tradingPartner.ediSelfServiceConfig?.ediMessageConfig?.edi810Config
            ? s.margin_bottom_small
            : s.margin_bottom
        }
      />
      {!!tradingPartner.ediSelfServiceConfig?.ediMessageConfig?.edi810Config && (
        <div className={clsx(s.subconfig_container, s.margin_bottom)}>
          <div className={s.subtitle}>EDI customizations</div>
          <div className={s.row}>
            <ViewField
              type="INPUT"
              title="N1 Remittance: Recipient Name"
              value={
                tradingPartner.ediSelfServiceConfig?.ediMessageConfig?.edi810Config?.n1Remittance
              }
            />
            <ViewField
              type="INPUT"
              title="N3 Remittance: Address"
              value={
                tradingPartner.ediSelfServiceConfig?.ediMessageConfig?.edi810Config?.n3Remittance
              }
            />
          </div>
          <ViewField
            type="INPUT"
            title="N4 Remittance: City/State/Zip/Country"
            value={
              tradingPartner.ediSelfServiceConfig?.ediMessageConfig?.edi810Config?.n4Remittance
            }
          />
          <ViewField
            type={
              tradingPartner.ediSelfServiceConfig?.ediMessageConfig?.edi810Config
                ?.ignoreAdditionalItems
                ? 'INPUT'
                : 'LIST'
            }
            title="IT1 - Additional item(s) added"
            value={
              tradingPartner.ediSelfServiceConfig?.ediMessageConfig?.edi810Config
                ?.ignoreAdditionalItems
                ? 'Ignore'
                : tradingPartner.ediSelfServiceConfig?.ediMessageConfig?.edi810Config?.ediAdditionalItemConfig?.map(
                    (item) => `IT10${item?.position} - ${item?.value || 'Empty'}`
                  )
            }
          />
          <div className={s.row}>
            <ViewField
              type="INPUT"
              title="Match IT101 Sequence to original 850"
              value={
                tradingPartner.ediSelfServiceConfig.ediMessageConfig.edi810Config?.match850Sequence
                  ? 'Yes'
                  : 'No'
              }
            />
            <ViewField
              type="INPUT"
              title="Pass remittance address from BU"
              value={
                tradingPartner.ediSelfServiceConfig?.ediMessageConfig?.edi810Config
                  ?.passRemittanceToBu
                  ? 'Yes'
                  : 'No'
              }
            />
          </div>
          <ViewField
            type="INPUT"
            title="Pass through bill-to address from BU"
            value={
              tradingPartner.ediSelfServiceConfig?.ediMessageConfig?.edi810Config
                ?.passThroughBillToFromBu
                ? 'Yes'
                : 'No'
            }
          />
          <ViewField
            type="CHECKBOX"
            title="Release document to Trading Partner"
            value={
              tradingPartner.ediSelfServiceConfig.ediMessageConfig.edi810Config
                ?.releaseDocumentToTradingPartner
            }
            className={s.padding_bottom}
          />
        </div>
      )}
    </>
  );

  const ediMessageConfiguration = (
    <>
      <div className={s.subtitle}>Global settings</div>
      <div className={s.row}>
        <ViewField
          type="INPUT"
          title="External Product ID Qualifier"
          value={tradingPartner.ediSelfServiceConfig?.ediGlobalConfig.externalProductIdQualifier}
        />
        <ViewField
          type="INPUT"
          title="Internal Product ID Qualifier"
          value={tradingPartner.ediSelfServiceConfig?.ediGlobalConfig.internalProductIdQualifier}
        />
      </div>
      <div className={s.row}>
        <ViewField
          type="INPUT"
          title="GS Local Code"
          value={tradingPartner.ediSelfServiceConfig?.ediGlobalConfig.gsLocalId}
        />
        <ViewField
          type="INPUT"
          title="GS Remote Code"
          value={tradingPartner.ediSelfServiceConfig?.ediGlobalConfig.gsRemoteId}
        />
      </div>
      <div className={s.row}>
        <ViewField
          type="INPUT"
          title="ISA Local ID"
          value={tradingPartner.ediSelfServiceConfig?.ediGlobalConfig.isaLocalId}
        />
        <ViewField
          type="INPUT"
          title="ISA Local Qualifier"
          value={tradingPartner.ediSelfServiceConfig?.ediGlobalConfig.isaLocalQualifier}
        />
      </div>
      <div className={s.row}>
        <ViewField
          type="INPUT"
          title="ISA Remote ID"
          value={tradingPartner.ediSelfServiceConfig?.ediGlobalConfig.isaRemoteId}
        />
        <ViewField
          type="INPUT"
          title="ISA Remote Qualifier"
          value={tradingPartner.ediSelfServiceConfig?.ediGlobalConfig.isaRemoteQualifier}
        />
      </div>
      <div className={s.row}>
        <ViewField
          type="INPUT"
          title="ISA Component Element Separator"
          value={tradingPartner.ediSelfServiceConfig?.ediGlobalConfig.isaComponentElementSeparator}
        />
        <ViewField
          type="INPUT"
          title="ISA Interchange Control Version Number"
          value={tradingPartner.ediSelfServiceConfig?.ediGlobalConfig.isaInterchangeControlVersion}
        />
      </div>
      <div className={s.row}>
        <ViewField
          type="INPUT"
          title="ISA Repetition Separator"
          value={tradingPartner.ediSelfServiceConfig?.ediGlobalConfig.isaRepetitionSeparator}
        />
        <ViewField
          type="INPUT"
          title="Interchange Usage Indicator"
          value={tradingPartner.ediSelfServiceConfig?.ediGlobalConfig.isaInterchangeUsageIndicator}
        />
      </div>
      <div className={s.row}>
        <ViewField
          type="INPUT"
          title="Supplier ID"
          value={tradingPartner.ediSelfServiceConfig?.ediGlobalConfig.supplierId}
        />
        <ViewField
          type="INPUT"
          title="Supplier ID Path"
          value={tradingPartner.ediSelfServiceConfig?.ediGlobalConfig.supplierIdPath}
        />
      </div>
      <div className={s.row}>
        <ViewField
          type="INPUT"
          title="Format"
          value={tradingPartner.ediSelfServiceConfig?.ediGlobalConfig.format}
        />
        <ViewField
          type="INPUT"
          title="Webhook Endpoint"
          value={tradingPartner.ediSelfServiceConfig?.ediGlobalConfig.webhookEndpoint}
        />
      </div>
      {ediMessagePurchaseOrder850}
      {ediMessagePurchaseOrderAcknowledgements855}
      {ediMessageAsn856}
      {ediMessageInvoice810}
    </>
  );

  const ediConfiguration = (
    <>
      <div className={clsx(s.margin_bottom, s.tabs_switch_container)}>
        <AlloySegmented
          block
          size="small"
          options={[
            { label: 'External', value: 'external' },
            { label: 'Internal', value: 'internal' }
          ]}
          onChange={(key) => setEdiConfigurationSelected(key)}
          value={ediConfigurationSelected}
        />
      </div>
      {ediConfigurationSelected === 'external' &&
        (tradingPartner.ediConfigExternal ? (
          getEdiConfigurationContent(tradingPartner.ediConfigExternal)
        ) : (
          <DisabledIntegration partly />
        ))}
      {ediConfigurationSelected === 'internal' &&
        (tradingPartner.ediConfigInternal ? (
          getEdiConfigurationContent(tradingPartner.ediConfigInternal)
        ) : (
          <DisabledIntegration partly />
        ))}
    </>
  );

  const getEmailConfig = (emailConfig: EmailConfig) => (
    <>
      <ViewField
        className={s.margin_bottom}
        type="SWITCH"
        title={emailConfig?.active ? 'Active' : 'Not Active'}
        value={emailConfig?.active}
      />
      <ViewField type="INPUT" title="Email From Address" value={emailConfig?.emailFromAddress} />
      <ViewField type="INPUT" title="Email From Name" value={emailConfig?.emailFromName} />
      <ViewField
        type="LIST"
        title="Available Carrier Codes"
        value={emailConfig?.emailToList?.filter((email): email is string => !!email)}
      />
    </>
  );

  const emailConfigurationSalesOrder = (
    <>
      <div className={clsx(s.margin_bottom, s.tabs_switch_container)}>
        <AlloySegmented
          block
          size="small"
          options={[
            { label: 'Export', value: 'export' },
            { label: 'Release', value: 'release' }
          ]}
          onChange={(key) => setEmailConfigSalesOrderSelectedSelected(key)}
          value={emailConfigSalesOrderSelected}
        />
      </div>
      {emailConfigSalesOrderSelected === 'export' &&
        (tradingPartner.emailConfigSalesOrderExport ? (
          getEmailConfig(tradingPartner.emailConfigSalesOrderExport)
        ) : (
          <DisabledIntegration partly />
        ))}
      {emailConfigSalesOrderSelected === 'release' &&
        (tradingPartner.emailConfigSalesOrderRelease ? (
          getEmailConfig(tradingPartner.emailConfigSalesOrderRelease)
        ) : (
          <DisabledIntegration partly />
        ))}
    </>
  );

  const internalAlertConfig = (
    <>
      <ViewField
        className={s.margin_bottom_small}
        title="Send Duplicate Order Items Alert"
        type="SWITCH"
        value={tradingPartner.internalAlertConfig?.sendDuplicateOrderItemsAlert}
      />
      <ViewField
        className={s.margin_bottom_small}
        title="Send Invalid Order Items Alert"
        type="SWITCH"
        value={tradingPartner.internalAlertConfig?.sendInvalidOrderItemsAlert}
      />
      <ViewField
        className={s.margin_bottom}
        title="Send Late Order Items Alert"
        type="SWITCH"
        value={tradingPartner.internalAlertConfig?.sendLateOrdersAlert}
      />
    </>
  );

  const internalAlertEmailConfig = tradingPartner.internalAlertEmailConfig ? (
    getEmailConfig(tradingPartner.internalAlertEmailConfig)
  ) : (
    <DisabledIntegration />
  );

  const amazonSellingPartnerConfig = (
    <>
      <ViewField
        type="INPUT"
        title="Access Key ID"
        value={
          tradingPartner.amazonSellingPartnerConfig?.accessKeyId ||
          defaultConfigsData?.tradingPartnerDefaultConfigs?.defaultAmazonSellingPartnerConfig
            ?.accessKeyId
        }
      />
      <ViewField
        type="INPUT"
        title="Client ID"
        value={
          tradingPartner.amazonSellingPartnerConfig?.clientId ||
          defaultConfigsData?.tradingPartnerDefaultConfigs?.defaultAmazonSellingPartnerConfig
            ?.clientId
        }
      />
      <ViewField
        type="INPUT"
        title="Region"
        value={
          tradingPartner.amazonSellingPartnerConfig?.region ||
          defaultConfigsData?.tradingPartnerDefaultConfigs?.defaultAmazonSellingPartnerConfig
            ?.region
        }
      />
      <ViewField
        type="INPUT"
        title="Write API Site"
        value={
          tradingPartner.amazonSellingPartnerConfig?.writeApiSite ||
          defaultConfigsData?.tradingPartnerDefaultConfigs?.defaultAmazonSellingPartnerConfig
            ?.writeApiSite
        }
      />
      <ViewField
        type="INPUT"
        title="Read API Site"
        value={
          tradingPartner.amazonSellingPartnerConfig?.readApiSite ||
          defaultConfigsData?.tradingPartnerDefaultConfigs?.defaultAmazonSellingPartnerConfig
            ?.readApiSite
        }
      />
      <ViewField
        type="INPUT"
        title="Access Token Site"
        value={
          tradingPartner.amazonSellingPartnerConfig?.accessTokenSite ||
          defaultConfigsData?.tradingPartnerDefaultConfigs?.defaultAmazonSellingPartnerConfig
            ?.accessTokenSite
        }
      />
      <ViewField
        type="INPUT"
        title="Client Secret"
        value={
          tradingPartner.amazonSellingPartnerConfig?.clientSecret ||
          defaultConfigsData?.tradingPartnerDefaultConfigs?.defaultAmazonSellingPartnerConfig
            ?.clientSecret
        }
      />
      <ViewField
        type="INPUT"
        title="Refresh Token"
        value={
          tradingPartner.amazonSellingPartnerConfig?.refreshToken ||
          defaultConfigsData?.tradingPartnerDefaultConfigs?.defaultAmazonSellingPartnerConfig
            ?.refreshToken
        }
      />
      <ViewField
        type="INPUT"
        title="Secret Access Key"
        value={
          tradingPartner.amazonSellingPartnerConfig?.secretAccessKey ||
          defaultConfigsData?.tradingPartnerDefaultConfigs?.defaultAmazonSellingPartnerConfig
            ?.secretAccessKey
        }
      />
    </>
  );

  const samsClubConfig = (
    <>
      <ViewField
        type="INPUT"
        title="Client ID"
        value={
          tradingPartner.samsClubConfig?.clientId ||
          defaultConfigsData?.tradingPartnerDefaultConfigs?.defaultSamsClubConfig?.clientId
        }
      />
      <ViewField
        type="INPUT"
        title="Client Secret"
        value={
          tradingPartner.samsClubConfig?.clientSecret ||
          defaultConfigsData?.tradingPartnerDefaultConfigs?.defaultSamsClubConfig?.clientSecret
        }
      />
    </>
  );

  const integrations = [
    {
      title: 'Channel Advisor Config',
      show: !!tradingPartner?.channelAdvisorConfig,
      content: channelAdvisorConfig
    },
    {
      title: 'OCH Configuration',
      show: !!tradingPartner?.ochConfig,
      content: ochConfig
    },
    {
      title: 'PEPDirect Configuration',
      show: !!tradingPartner?.pepdirectConfig,
      content: pepdirectConfig
    },
    {
      title: 'Shipping Configuration',
      show: !!tradingPartner?.shippingConfig,
      content: shippingConfig
    },
    {
      title: 'EDI Message Configuration',
      show: !!tradingPartner?.ediSelfServiceConfig,
      content: ediMessageConfiguration
    },
    {
      title: 'EDI Configuration',
      show: !!tradingPartner?.ediConfigExternal || !!tradingPartner?.ediConfigInternal,
      content: ediConfiguration
    },
    {
      title: 'Email Configuration Sales Order',
      show:
        !!tradingPartner?.emailConfigSalesOrderExport ||
        !!tradingPartner.emailConfigSalesOrderRelease,
      content: emailConfigurationSalesOrder
    },
    {
      title: 'Internal Alert Configuration',
      show: !!tradingPartner?.internalAlertConfig,
      content: internalAlertConfig
    },
    {
      title: 'Internal Alert Email Configuration',
      show: !!tradingPartner?.internalAlertEmailConfig,
      content: internalAlertEmailConfig
    },
    {
      title: 'Amazon Selling Partner Configuration',
      show: !!tradingPartner?.amazonSellingPartnerConfig,
      content: amazonSellingPartnerConfig
    },
    {
      title: "Sam's Club Configuration",
      show: !!tradingPartner?.samsClubConfig,
      content: samsClubConfig
    },
    {
      title: 'Invoice Configuration',
      show: !!tradingPartner?.invoiceConfig,
      content: invoiceConfig
    }
  ];

  return (
    <>
      {integrations
        .sort((int1, int2) => (int1.show === int2.show ? 0 : int1.show ? -1 : 1))
        .map((integration, number) => (
          <ViewRow
            key={integration.title}
            title={integration.title}
            content={integration.show ? integration.content : <DisabledIntegration />}
            bordered={number !== integrations.length - 1 || !integration.show}
          />
        ))}
    </>
  );
};
