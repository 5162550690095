import { ExportSeedDataKind } from 'graphql/__generated__/enums';
import React, { useState } from 'react';
import { ExportSeedDataDocument } from './gql/__generated__/exportSeedData.query';
import { download } from 'components/Common/utils';
import * as Sentry from '@sentry/browser';
import { useLazyQuery } from '@apollo/client';
import { App } from 'ant5';
import { AlloyModal } from 'components/ui/AlloyModal/AlloyModal';
import { AlloyRadio } from 'components/ui/AlloyRadio/AlloyRadio';

export const ExportSeedDataModal = ({
  open,
  toggleModal,
  selectedRowIds
}: {
  open: boolean;
  toggleModal: () => void;
  selectedRowIds: string[];
}) => {
  const { notification } = App.useApp();
  const [selectedKind, setSelectedKind] = useState<ExportSeedDataKind>(
    ExportSeedDataKind.PURCHASE_ORDERS_WITH_ALL_REFS
  );
  const [exportSeedData] = useLazyQuery(ExportSeedDataDocument, {
    onCompleted: (data) => {
      if (data) {
        const url = data.exportSeedData;
        download(`${import.meta.env.REACT_APP_API_URL}${url}`, 'SeedData.yaml');
      }
    },
    onError: (error) => {
      Sentry.captureException(error);
      notification.error({
        message: 'Failed to download',
        description: 'Something went wrong while downloading data. Please try again'
      });
    }
  });

  const onToggleModal = () => {
    toggleModal();
    setSelectedKind(ExportSeedDataKind.PURCHASE_ORDERS_WITH_ALL_REFS);
  };

  const downloadSelected = async () => {
    exportSeedData({
      variables: {
        purchaseOrderIds: selectedRowIds,
        kind: selectedKind
      }
    });
  };

  return (
    <AlloyModal
      data-testid="export_so_items_modal"
      className="export_so_items_modal"
      title="Download selected POs"
      open={open}
      onCancel={() => onToggleModal()}
      width={500}
      onOk={async () => {
        onToggleModal();
        await downloadSelected();
      }}
      okText="Yes, Continue"
    >
      <AlloyRadio.Group
        onChange={(e) => {
          setSelectedKind(e.target.value);
        }}
        value={selectedKind}
        options={[
          {
            label: 'POs with all refs (big)',
            value: ExportSeedDataKind.PURCHASE_ORDERS_WITH_ALL_REFS
          },
          {
            label: 'POs with direct refs (small)',
            value: ExportSeedDataKind.PURCHASE_ORDERS_WITH_DIRECT_REFS
          }
        ]}
      />
    </AlloyModal>
  );
};
