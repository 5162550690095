import { CellObject, utils as XLSXutils, writeFileXLSX } from 'non_npm_dependencies/xlsx';

export const exportDataToXlsx = (
  fileName: string,
  tabs: {
    tabName: string;
    itemsToExport: (
      | { [name: string]: string | number | boolean | string[] | undefined | null }
      | (string | number | boolean | string[] | undefined | null | CellObject)[]
    )[];
    headers?: {
      name: string;
      width?: number;
    }[];
  }[]
) => {
  const workbook = XLSXutils.book_new();
  tabs.forEach((tab) => {
    let worksheet;

    if (
      tab.itemsToExport.length > 0 &&
      typeof tab.itemsToExport[0] === 'object' &&
      !Array.isArray(tab.itemsToExport[0])
    ) {
      // Case of array of objects
      worksheet = XLSXutils.json_to_sheet(
        tab.itemsToExport as { [name: string]: string | number | boolean | string[] | undefined }[],
        { header: tab.headers?.map((header) => header.name) }
      );
    } else {
      // Case of array of arrays
      worksheet = XLSXutils.aoa_to_sheet(
        tab.itemsToExport as (string | number | boolean | string[] | undefined | CellObject)[][]
      );
    }

    if (tab.headers) {
      worksheet['!cols'] = tab.headers.map((header) => ({ wpx: header.width }));
    }

    XLSXutils.book_append_sheet(workbook, worksheet, tab.tabName);
  });
  writeFileXLSX(workbook, fileName, { compression: true });
};
