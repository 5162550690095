import React from 'react';
import {
  AlloyInput,
  AlloyTextArea,
  InputProps,
  TextAreaProps
} from 'components/ui/AlloyInput/AlloyInput';
import { Field } from 'react-final-form';
import { FormLabel } from '../FormLabel/FormLabel';
import { FieldInfo } from 'components/ui/formFields/FieldInfo/FieldInfo';
import { FieldState } from 'final-form';
import {
  composeDateValidators,
  composeValidators,
  validateRequired,
  validateRequiredDate
} from 'common/helpers/validationHelper';
import { AlloySelect, DefaultOptionType, SelectProps } from 'components/ui/AlloySelect/AlloySelect';
import { AlloyCheckbox, CheckboxProps } from 'components/ui/AlloyCheckbox/AlloyCheckbox';
import s from './AlloyFormField.module.scss';
import clsx from 'clsx';
import { AlloySwitch, SwitchProps } from 'components/ui/AlloySwitch/AlloySwitch';
import {
  AlloyInputNumber,
  InputNumberProps
} from 'components/ui/AlloyInputNumber/AlloyInputNumber';
import { safeLocaleCompare } from 'common/helpers/comparators';
import {
  AlloyDatePicker,
  AlloyTimePicker,
  DatePickerProps,
  TimePickerProps
} from 'components/ui/AlloyDatePicker/AlloyDatePicker';
import type { Moment } from 'moment';

export type FieldValidatorWithValues<FieldValue> = (
  value: FieldValue,
  allValues: { [index: string]: any },
  meta?: FieldState<FieldValue>
) => any | Promise<any>;

type HideInfo = 'ONLY_ERROR' | 'WITHOUT_MARGIN' | 'WITH_MARGIN';

interface BaseFormFieldProps {
  name: string;
  hideInfo?: HideInfo;
  countRule?: string;
  inline?: 'before' | 'after';
  'data-testid'?: string;
}

interface InputFormFieldProps extends BaseFormFieldProps {
  component: 'input';
  fieldProps?: InputProps;
  required?: boolean;
  validate?: FieldValidatorWithValues<string>;
  parse?: (value: string) => string;
}

interface InputNumberFormFieldProps extends BaseFormFieldProps {
  component: 'inputNumber';
  fieldProps?: InputNumberProps;
  required?: boolean;
  validate?: FieldValidatorWithValues<number>;
  parse?: (value: number) => number;
}

interface SelectFormFieldProps extends BaseFormFieldProps {
  component: 'select';
  fieldProps?: SelectProps<any, DefaultOptionType>;
  required?: boolean;
  validate?: FieldValidatorWithValues<any>;
}

const selectDefaultProperties: Partial<SelectProps> = {
  size: 'large',
  popupMatchSelectWidth: false,
  maxTagCount: 'responsive',
  optionFilterProp: 'children',
  filterOption: (input, option) =>
    String(option?.label ?? '')
      .toLowerCase()
      .includes(input.toLowerCase()),
  filterSort: (optionA, optionB) => {
    if (optionA.value === 'all') return -1;
    if (optionB.value === 'all') return 1;
    return safeLocaleCompare(
      String(optionA?.label ?? '').toLowerCase(),
      String(optionB?.label ?? '').toLowerCase()
    );
  },
  showSearch: true
};

interface TextAreaFormFieldProps extends BaseFormFieldProps {
  component: 'textarea';
  fieldProps?: TextAreaProps;
  required?: boolean;
  validate?: FieldValidatorWithValues<string>;
}

interface SwitchFormFieldProps extends BaseFormFieldProps {
  component: 'switch';
  fieldProps?: SwitchProps;
  validate?: FieldValidatorWithValues<boolean>;
}

interface CheckboxFormFieldProps extends BaseFormFieldProps {
  component: 'checkbox';
  fieldProps?: CheckboxProps;
  validate?: FieldValidatorWithValues<boolean>;
}

interface DatePickerFormFieldProps extends BaseFormFieldProps {
  component: 'datepicker';
  fieldProps: DatePickerProps;
  required?: boolean;
  validate?: FieldValidatorWithValues<Moment>;
}

interface TimePickerFormFieldProps extends BaseFormFieldProps {
  component: 'timepicker';
  fieldProps: TimePickerProps & { title?: string };
  required?: boolean;
  validate?: FieldValidatorWithValues<Moment>;
}

export type FormFieldProps =
  | InputFormFieldProps
  | InputNumberFormFieldProps
  | SelectFormFieldProps
  | TextAreaFormFieldProps
  | SwitchFormFieldProps
  | CheckboxFormFieldProps
  | DatePickerFormFieldProps
  | TimePickerFormFieldProps;

export function AlloyFormField(props: InputFormFieldProps): JSX.Element;
export function AlloyFormField(props: InputNumberFormFieldProps): JSX.Element;
export function AlloyFormField(props: SelectFormFieldProps): JSX.Element;
export function AlloyFormField(props: TextAreaFormFieldProps): JSX.Element;
export function AlloyFormField(props: SwitchFormFieldProps): JSX.Element;
export function AlloyFormField(props: CheckboxFormFieldProps): JSX.Element;
export function AlloyFormField(props: DatePickerFormFieldProps): JSX.Element;
export function AlloyFormField(props: TimePickerFormFieldProps): JSX.Element;

export function AlloyFormField(props: FormFieldProps) {
  switch (props.component) {
    case 'input': {
      const { component: _, ...rest } = props;
      return <FormFieldInput {...rest} />;
    }
    case 'inputNumber': {
      const { component: _, ...rest } = props;
      return <FormFieldInputNumber {...rest} />;
    }
    case 'select': {
      const { component: _, ...rest } = props;
      return <FormFieldSelect {...rest} />;
    }
    case 'textarea': {
      const { component: _, ...rest } = props;
      return <FormFieldTextArea {...rest} />;
    }
    case 'checkbox': {
      const { component: _, ...rest } = props;
      return <FormFieldCheckbox {...rest} />;
    }
    case 'switch': {
      const { component: _, ...rest } = props;
      return <FormFieldSwitch {...rest} />;
    }
    case 'datepicker': {
      const { component: _, ...rest } = props;
      return <FormFieldDatePicker {...rest} />;
    }
    case 'timepicker': {
      const { component: _, ...rest } = props;
      return <FormFieldTimePicker {...rest} />;
    }
    default:
      return null;
  }
}

const FormFieldInput = React.memo(
  ({
    required,
    validate,
    inline,
    fieldProps,
    hideInfo,
    countRule,
    'data-testid': dataTestId,
    ...rest
  }: Omit<InputFormFieldProps, 'component'>) => (
    <Field<string>
      {...rest}
      validate={
        required
          ? validate
            ? composeValidators([validateRequired, validate])
            : validateRequired
          : validate
      }
    >
      {({ input, meta }) => {
        const error = meta.error && (meta.modified || meta.touched) ? meta.error : undefined;
        const showInfoPart = !hideInfo || (hideInfo === 'ONLY_ERROR' && !!error);
        return (
          <div
            className={clsx(s.form_field, {
              [s[`inline_${inline}`]]: !!inline,
              [s.margin_bottom]: hideInfo === 'WITH_MARGIN'
            })}
          >
            <div>
              {fieldProps?.title && (
                <FormLabel
                  title={fieldProps.title}
                  disabled={fieldProps.disabled}
                  required={required}
                  error={error}
                  data-testid={dataTestId ? `${dataTestId}_label` : undefined}
                />
              )}
              <AlloyInput {...input} {...(fieldProps as InputProps)} data-testid={dataTestId} />
            </div>
            {showInfoPart && (
              <FieldInfo
                error={error}
                info={countRule ? `${String(input.value).length}/${countRule}` : undefined}
                disabled={fieldProps?.disabled}
                data-testid={dataTestId ? `${dataTestId}_info` : undefined}
              />
            )}
          </div>
        );
      }}
    </Field>
  )
);
FormFieldInput.displayName = 'FormFieldInput';

const FormFieldInputNumber = ({
  required,
  validate,
  inline,
  fieldProps,
  hideInfo,
  countRule,
  'data-testid': dataTestId,
  ...rest
}: Omit<InputNumberFormFieldProps, 'component'>) => (
  <Field<number>
    {...rest}
    validate={
      required
        ? validate
          ? composeValidators([validateRequired, validate])
          : validateRequired
        : validate
    }
  >
    {({ input, meta }) => {
      const error = meta.error && (meta.modified || meta.touched) ? meta.error : undefined;
      const showInfoPart = !hideInfo || (hideInfo === 'ONLY_ERROR' && !!error);
      return (
        <div
          className={clsx(s.form_field, {
            [s[`inline_${inline}`]]: !!inline,
            [s.margin_bottom]: hideInfo === 'WITH_MARGIN'
          })}
        >
          <div>
            {fieldProps?.title && (
              <FormLabel
                title={fieldProps.title}
                disabled={fieldProps.disabled}
                required={required}
                error={error}
                data-testid={dataTestId ? `${dataTestId}_label` : undefined}
              />
            )}
            <AlloyInputNumber
              {...input}
              {...(fieldProps as InputNumberProps)}
              data-testid={dataTestId}
            />
          </div>
          {showInfoPart && (
            <FieldInfo
              error={error}
              info={countRule ? `${String(input.value).length}/${countRule}` : undefined}
              disabled={fieldProps?.disabled}
              data-testid={dataTestId ? `${dataTestId}_info` : undefined}
            />
          )}
        </div>
      );
    }}
  </Field>
);

const FormFieldSelect = React.memo(
  ({
    required,
    validate,
    inline,
    fieldProps,
    hideInfo,
    countRule,
    'data-testid': dataTestId,
    ...rest
  }: Omit<SelectFormFieldProps, 'component'>) => (
    <Field<string | number | string[] | number[]>
      {...rest}
      validate={
        required
          ? validate
            ? composeValidators([validateRequired, validate])
            : validateRequired
          : validate
      }
    >
      {({ input, meta }) => {
        const error = meta.error && (meta.modified || meta.touched) ? meta.error : undefined;
        const showInfoPart = !hideInfo || (hideInfo === 'ONLY_ERROR' && !!error);
        return (
          <div
            className={clsx(s.form_field, {
              [s[`inline_${inline}`]]: !!inline,
              [s.margin_bottom]: hideInfo === 'WITH_MARGIN'
            })}
          >
            <div>
              {fieldProps?.title && (
                <FormLabel
                  title={fieldProps.title}
                  disabled={fieldProps.disabled}
                  required={required}
                  error={error}
                  data-testid={dataTestId ? `${dataTestId}_label` : undefined}
                />
              )}
              <AlloySelect
                {...selectDefaultProperties}
                allowClear={!required && !!input.value}
                {...input}
                {...(fieldProps as SelectProps)}
                data-testid={dataTestId}
              />
            </div>
            {showInfoPart && (
              <FieldInfo
                error={error}
                info={countRule ? `${String(input.value).length}/${countRule}` : undefined}
                disabled={fieldProps?.disabled}
                data-testid={dataTestId ? `${dataTestId}_info` : undefined}
              />
            )}
          </div>
        );
      }}
    </Field>
  )
);
FormFieldSelect.displayName = 'FormFieldSelect';

const FormFieldCheckbox = ({
  inline,
  fieldProps,
  hideInfo,
  countRule,
  'data-testid': dataTestId,
  ...rest
}: Omit<CheckboxFormFieldProps, 'component'>) => (
  <Field<boolean> {...rest} type="checkbox">
    {({ input, meta }) => {
      const error = meta.error && (meta.modified || meta.touched) ? meta.error : undefined;
      const showInfoPart = !hideInfo || (hideInfo === 'ONLY_ERROR' && !!error);
      return (
        <div
          className={clsx(s.form_field, {
            [s[`inline_${inline}`]]: !!inline,
            [s.margin_bottom]: hideInfo === 'WITH_MARGIN'
          })}
        >
          <div>
            {fieldProps?.title && (
              <FormLabel
                title={fieldProps.title}
                disabled={fieldProps.disabled}
                error={error}
                data-testid={dataTestId ? `${dataTestId}_label` : undefined}
              />
            )}
            <AlloyCheckbox {...(fieldProps as CheckboxProps)} {...input} data-testid={dataTestId} />
          </div>
          {showInfoPart && (
            <FieldInfo
              error={error}
              info={countRule ? `${String(input.value).length}/${countRule}` : undefined}
              disabled={fieldProps?.disabled}
              data-testid={dataTestId ? `${dataTestId}_info` : undefined}
            />
          )}
        </div>
      );
    }}
  </Field>
);

const FormFieldSwitch = ({
  inline,
  fieldProps,
  hideInfo,
  countRule,
  'data-testid': dataTestId,
  ...rest
}: Omit<SwitchFormFieldProps, 'component'>) => (
  <Field<boolean> {...rest}>
    {({ input, meta }) => {
      const error = meta.error && (meta.modified || meta.touched) ? meta.error : undefined;
      const showInfoPart = !hideInfo || (hideInfo === 'ONLY_ERROR' && !!error);
      return (
        <div
          className={clsx(s.form_field, {
            [s[`inline_${inline}`]]: !!inline,
            [s.margin_bottom]: hideInfo === 'WITH_MARGIN'
          })}
        >
          <div>
            {fieldProps?.title && (
              <FormLabel
                title={fieldProps.title}
                disabled={fieldProps.disabled}
                error={error}
                data-testid={dataTestId ? `${dataTestId}_label` : undefined}
              />
            )}
            <AlloySwitch
              {...(fieldProps as SwitchProps)}
              {...input}
              checked={!!input.value}
              data-testid={dataTestId}
            />
          </div>
          {showInfoPart && (
            <FieldInfo
              error={error}
              info={countRule ? `${String(input.value).length}/${countRule}` : undefined}
              disabled={fieldProps?.disabled}
              data-testid={dataTestId ? `${dataTestId}_info` : undefined}
            />
          )}
        </div>
      );
    }}
  </Field>
);

const FormFieldTextArea = ({
  required,
  validate,
  inline,
  fieldProps,
  hideInfo,
  countRule,
  'data-testid': dataTestId,
  ...rest
}: Omit<TextAreaFormFieldProps, 'component'>) => (
  <Field<string>
    {...rest}
    validate={
      required
        ? validate
          ? composeValidators([validateRequired, validate])
          : validateRequired
        : validate
    }
  >
    {({ input, meta }) => {
      const error = meta.error && (meta.modified || meta.touched) ? meta.error : undefined;
      const showInfoPart = !hideInfo || (hideInfo === 'ONLY_ERROR' && !!error);
      return (
        <div
          className={clsx(s.form_field, {
            [s[`inline_${inline}`]]: !!inline,
            [s.margin_bottom]: hideInfo === 'WITH_MARGIN'
          })}
        >
          <div>
            {fieldProps?.title && (
              <FormLabel
                title={fieldProps.title}
                disabled={fieldProps.disabled}
                required={required}
                error={error}
                data-testid={dataTestId ? `${dataTestId}_label` : undefined}
              />
            )}
            <AlloyTextArea {...input} {...(fieldProps as TextAreaProps)} data-testid={dataTestId} />
          </div>
          {showInfoPart && (
            <FieldInfo
              error={error}
              info={countRule ? `${String(input.value).length}/${countRule}` : undefined}
              disabled={fieldProps?.disabled}
              data-testid={dataTestId ? `${dataTestId}_info` : undefined}
            />
          )}
        </div>
      );
    }}
  </Field>
);

const FormFieldDatePicker = ({
  required,
  validate,
  inline,
  fieldProps,
  hideInfo,
  countRule,
  'data-testid': dataTestId,
  ...rest
}: Omit<DatePickerFormFieldProps, 'component'>) => (
  <Field<Moment>
    {...rest}
    validate={
      required
        ? validate
          ? composeDateValidators([validateRequiredDate, validate])
          : validateRequiredDate
        : validate
    }
  >
    {({ input, meta }) => {
      const error = meta.error && (meta.modified || meta.touched) ? meta.error : undefined;
      const showInfoPart = !hideInfo || (hideInfo === 'ONLY_ERROR' && !!error);
      return (
        <div
          className={clsx(s.form_field, {
            [s[`inline_${inline}`]]: !!inline,
            [s.margin_bottom]: hideInfo === 'WITH_MARGIN'
          })}
        >
          <div>
            {fieldProps.title && (
              <FormLabel
                title={fieldProps.title}
                disabled={fieldProps.disabled}
                required={required}
                error={error}
                data-testid={dataTestId ? `${dataTestId}_label` : undefined}
              />
            )}
            <AlloyDatePicker
              {...input}
              {...(fieldProps as DatePickerProps)}
              data-testid={dataTestId}
              size={fieldProps.size ?? 'large'}
            />
          </div>
          {showInfoPart && (
            <FieldInfo
              error={error}
              info={countRule ? `${String(input.value).length}/${countRule}` : undefined}
              disabled={fieldProps.disabled}
              data-testid={dataTestId ? `${dataTestId}_info` : undefined}
            />
          )}
        </div>
      );
    }}
  </Field>
);

const FormFieldTimePicker = ({
  required,
  validate,
  inline,
  fieldProps,
  hideInfo,
  countRule,
  'data-testid': dataTestId,
  ...rest
}: Omit<TimePickerFormFieldProps, 'component'>) => (
  <Field<Moment>
    {...rest}
    validate={
      required
        ? validate
          ? composeDateValidators([validateRequiredDate, validate])
          : validateRequiredDate
        : validate
    }
  >
    {({ input, meta }) => {
      const error = meta.error && (meta.modified || meta.touched) ? meta.error : undefined;
      const showInfoPart = !hideInfo || (hideInfo === 'ONLY_ERROR' && !!error);
      return (
        <div
          className={clsx(s.form_field, {
            [s[`inline_${inline}`]]: !!inline,
            [s.margin_bottom]: hideInfo === 'WITH_MARGIN'
          })}
        >
          <div>
            {fieldProps.title && (
              <FormLabel
                title={fieldProps.title}
                disabled={fieldProps.disabled}
                required={required}
                error={error}
                data-testid={dataTestId ? `${dataTestId}_label` : undefined}
              />
            )}
            <AlloyTimePicker
              {...input}
              {...(fieldProps as TimePickerProps)}
              data-testid={dataTestId}
              size={fieldProps.size ?? 'large'}
            />
          </div>
          {showInfoPart && (
            <FieldInfo
              error={error}
              info={countRule ? `${String(input.value).length}/${countRule}` : undefined}
              disabled={fieldProps.disabled}
              data-testid={dataTestId ? `${dataTestId}_info` : undefined}
            />
          )}
        </div>
      );
    }}
  </Field>
);
