import * as Types from '../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ProductForecastNumberOfProductsQueryVariables = Types.Exact<{
  filters: Types.ProductForecastFilters;
}>;


export type ProductForecastNumberOfProductsQuery = { __typename?: 'RootQueryType', productForecastNumberOfProducts: { __typename?: 'ProductForecastNumberOfProducts', value: number } };


export const ProductForecastNumberOfProductsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"productForecastNumberOfProducts"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"filters"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ProductForecastFilters"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"productForecastNumberOfProducts"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"filters"},"value":{"kind":"Variable","name":{"kind":"Name","value":"filters"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"value"}}]}}]}}]} as unknown as DocumentNode<ProductForecastNumberOfProductsQuery, ProductForecastNumberOfProductsQueryVariables>;