import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { CSVLink } from 'react-csv';
import { dateFormat } from 'common/helpers/date';
import { getNodesFromEdges } from 'common/helpers/mappingHelper';
import { PurchaseOrderItemsBulkDownloadDocument } from './gql/__generated__/purchaseOrderItemsBulkDownload.query';
import { DeliveryType } from 'graphql/__generated__/types';
import { AlloyModal } from 'components/ui/AlloyModal/AlloyModal';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { AlloySpin } from 'components/ui/AlloySpin/AlloySpin';

interface BulkDownloadFileModalProps {
  visibility: boolean;
  toggleModal: () => void;
  selectedRowIds: string[];
}

interface DownloadItem {
  poNumber: string | null | undefined;
  gtin: string | null | undefined;
  quantityOrdered: number;
  mabd: string;
  shipTo: string | null | undefined;
  description: string | null | undefined;
  tradingPartner: {
    deliveryType: DeliveryType | null | undefined;
  };
}

const itemHeaders: { label: string; key: keyof DownloadItem }[] = [
  { label: 'PO #', key: 'poNumber' },
  { label: 'Ship-To', key: 'shipTo' },
  { label: 'MABD', key: 'mabd' },
  { label: 'GTIN', key: 'gtin' },
  { label: 'Quantity', key: 'quantityOrdered' },
  { label: 'Item Description', key: 'description' }
];

const BulkDownloadFileModal = ({
  visibility,
  toggleModal,
  selectedRowIds
}: BulkDownloadFileModalProps) => {
  const { data, loading, called } = useQuery(PurchaseOrderItemsBulkDownloadDocument, {
    variables: {
      first: selectedRowIds.length,
      ids: selectedRowIds
    },

    skip: !(selectedRowIds.length > 0 && visibility)
  });

  const csvData: DownloadItem[] = useMemo(
    () =>
      getNodesFromEdges(data?.purchaseOrders)
        .map((node) =>
          node?.purchaseOrderItems?.map((item) => {
            return {
              poNumber: node.customerPo,
              shipTo: node.retailerDeliveryDestination?.externalId,
              mabd: dateFormat(node.deliveryWindowEnd || ''),
              gtin: item.catalogProduct?.gtin12 ?? item.product?.upc ?? '',
              // Should it actually be 0 if it's not present?
              quantityOrdered: item.quantityOrdered ? item.quantityOrdered : 0,
              description: item.catalogProduct?.name ?? item.product?.name ?? '',
              tradingPartner: {
                deliveryType: node.tradingPartner?.deliveryType
              }
            };
          })
        )
        .flat(),
    [data]
  );

  return (
    <AlloyModal
      open={visibility}
      onCancel={toggleModal}
      footer={
        csvData ? (
          <>
            <AlloyButton key="cancel" onClick={toggleModal}>
              Cancel
            </AlloyButton>
            <AlloyButton type="primary">
              <CSVLink
                onClick={toggleModal}
                filename={'precision-orders.csv'}
                headers={itemHeaders}
                data={csvData}
              >
                Download
              </CSVLink>
            </AlloyButton>
          </>
        ) : null
      }
      destroyOnClose
    >
      <AlloySpin spinning={loading}>
        <div style={{ minHeight: '48px' }}>
          {called && !loading && (
            <>
              <div style={{ marginRight: '32px' }}>
                Are you sure you want to download list of precision orders item quantities?
              </div>
              {csvData.filter((row) => row.tradingPartner?.deliveryType === 'WHD').length > 0 ? (
                <div className="label">Only DSD orders will be processed</div>
              ) : (
                ''
              )}
            </>
          )}
        </div>
      </AlloySpin>
    </AlloyModal>
  );
};

export default BulkDownloadFileModal;
