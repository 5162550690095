import { ExecutiveReportingFilters } from 'graphql/__generated__/types';
import { FiscalCalendarWeekInputWithWeekOnly } from 'components/PeriodCalendar/helpers';
import { OtifPurchaseOrdersReportsByPeriodQuery } from './gql/__generated__/otifPurchaseOrdersReportsByPeriod.query';
import { OtifPurchaseOrdersReportsTableByPeriodQuery } from './gql/__generated__/otifPurchaseOrdersReportsTableByPeriod.query';

export type OTIFReportData =
  OtifPurchaseOrdersReportsByPeriodQuery['otifPurchaseOrdersReportsByPeriod'][number];
export type OTIFTableReportData =
  OtifPurchaseOrdersReportsTableByPeriodQuery['otifPurchaseOrdersReportsTableByPeriod'][number];

export type ExecutiveReportingFiltersOnlyWeekInFilter = Omit<
  ExecutiveReportingFilters,
  'fiscalCalendarWeeks'
> & {
  fiscalCalendarWeeks: FiscalCalendarWeekInputWithWeekOnly[];
};

export const CUT_CATEGORIES = [
  'customer',
  'planning',
  'warehouse',
  'transportation',
  'uncategorized'
] as const;
export type CutCategory = (typeof CUT_CATEGORIES)[number];

export const DEFAULT_CATEGORY_TAB: CutCategory = 'customer';

export const CUTS_BY_REASON_CATEGORY = 'category';
