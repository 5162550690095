import React, { useState, useContext } from 'react';
import s from './GeneralSettingsTab.module.scss';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { AlloyCol } from 'components/ui/AlloyCol/AlloyCol';
import { AlloyCollapse } from 'components/ui/AlloyCollapse/AlloyCollapse';
import { AlloyDivider } from 'components/ui/AlloyDivider/AlloyDivider';
import { AlloyRow } from 'components/ui/AlloyRow/AlloyRow';
import { AlloySwitch } from 'components/ui/AlloySwitch/AlloySwitch';
import { SourcingRuleInput, UpsertVendorMarketInput } from 'graphql/__generated__/types';
import { useEnumValues } from 'common/useEnumValue';
import { composeValidators } from 'common/helpers/validationHelper';
import { FormApi } from 'final-form';
import { AlloyFormField } from 'components/ui/formFields/AlloyFormField/AlloyFormField';
import { Titles } from 'pages/ShipToPage/helper';
import {
  acronymsToUpperCase,
  camelCaseToSpaces,
  fieldNameToCapitalize
} from 'common/helpers/stringsConverter';
import { OnChange } from 'react-final-form-listeners';
import { EditRetailerChannelModal } from 'pages/RetailerChannelsPage/components/EditRetailerChannel/EditRetailerChannelModal';
import { FieldArray } from 'react-final-form-arrays';
import { PrioritizedList } from 'components/PrioritizedList/PrioritizedList';
import { v4 as uuidv4 } from 'uuid';
import { UserContext } from 'context/userContext';
import {
  AllowedDistributionCenter,
  SourcingRule
} from '../../TradingPartnerViewDrawer/TradingPartnerViewDrawer';
import { useForm } from 'react-final-form';
import addIcon from 'assets/icons/editing/add_blue.svg';
import { get } from 'lodash-es';
import {
  BusinessUnit,
  VendorMarket
} from '../../EditTradingPartnerModal/components/AddTradingPartnerStepper/AddTradingPartnerStepper';
import { AlloyInput } from 'components/ui/AlloyInput/AlloyInput';
import { AlloySelect } from 'components/ui/AlloySelect/AlloySelect';
import { AlloyInputNumber } from 'components/ui/AlloyInputNumber/AlloyInputNumber';
import { FieldInfo } from 'components/ui/formFields/FieldInfo/FieldInfo';
import clsx from 'clsx';

interface GeneralSettingsTabProps {
  setVendorMarketId: (id: string, form: FormApi) => void;
  upserting: boolean;
  defaultSourcingRules: SourcingRule[];
  allowedDistributionCenters: AllowedDistributionCenter[];
  businessUnits: BusinessUnit[];
  initLoading: boolean;
  vendorMarkets: VendorMarket[];
}

const getOpenPanels = (form: FormApi<any, Partial<any>>) => {
  const keys: string[] = [];
  if (form.getState().values.showProcessingFlow) {
    keys.push('1');
  }
  if (form.getState().values.showStoreConfig) {
    keys.push('2');
  }
  if (form.getState().values.showPoValidationConfig) {
    keys.push('3');
  }
  if (form.getState().values.showSourcingRules) {
    keys.push('4');
  }
  if (form.getState().values.showAdditionalFields) {
    keys.push('5');
  }
  return keys;
};

export const GeneralSettingsTab = ({
  setVendorMarketId,
  upserting,
  defaultSourcingRules,
  allowedDistributionCenters,
  initLoading,
  vendorMarkets,
  businessUnits
}: GeneralSettingsTabProps) => {
  const { user } = useContext(UserContext);

  const form = useForm();

  const { enumValues, loading } = useEnumValues([
    'ReleaseMethod',
    'InternalProductIdQualifier',
    'ProductIdQualifier',
    'PurchaseOrderAcknowledgementTransport',
    'ShipToReleaseIdentifier',
    'UnitOfMeasure',
    'DeliveryType',
    'MeasurementSystem',
    'ProcessingFlowIngestPo',
    'ProcessingFlowPreparePo',
    'ProcessingFlowRouteToOptimalDc',
    'ProcessingFlowResetPo',
    'ProcessingFlowUpdatePo',
    'ProcessingFlowCancelPo',
    'OrderItemRounding',
    'ValidationLevel',
    'ProcessingFlowReleaseExternalAcknowledgementType',
    'ProcessingFlowReleaseExternalExchangeReturnType',
    'ProcessingFlowReleaseExternalShipmentType',
    'ProcessingFlowReleaseInventoryType',
    'ProcessingFlowReleaseInvoiceType',
    'ProcessingFlowFulfillmentSyncType',
    'OrderSplittingType'
  ]);

  const [showAddRetailerChannelModal, setShowAddRetailerChannelModal] = useState(false);

  const generalFields = (
    <>
      <AlloyRow className={s.title_row}>Configuration details</AlloyRow>
      <AlloyRow gutter={16}>
        <AlloyCol span={12}>
          <AlloyFormField
            component="input"
            data-testid="tp-external-id-input"
            name="externalId"
            fieldProps={{
              title: 'External ID'
            }}
            required
          />
          <AlloyFormField
            component="select"
            data-testid="tp-retailer-channel-select"
            name="vendorMarketId"
            required
            fieldProps={{
              loading: initLoading,
              title: 'Retailer Channel',
              options: vendorMarkets.map((vendorMarket) => ({
                value: vendorMarket.id,
                label: vendorMarket.name
              })),
              dropdownRender: (menu) => (
                <>
                  {menu}
                  <AlloyDivider style={{ margin: '8px 0 0' }} />
                  <AlloyButton
                    type="link"
                    onClick={() => setShowAddRetailerChannelModal(true)}
                    data-testid="add-new-retailer-channel"
                  >
                    + Add new Retailer Channel
                  </AlloyButton>
                </>
              )
            }}
          />
          <OnChange name="vendorMarketId">{() => form.mutators.setRdds([])}</OnChange>
          <AlloyFormField
            component="select"
            data-testid="tp-delivery-type-select"
            name="deliveryType"
            required
            fieldProps={{
              loading: loading,
              title: 'Delivery Type',
              options: (enumValues.DeliveryType || []).map((deliveryType) => ({
                value: deliveryType,
                label: deliveryType
              }))
            }}
          />
          <AlloyFormField
            component="select"
            data-testid="tp-ship-to-release-identifier-select"
            name="shipToReleaseIdentifier"
            required
            fieldProps={{
              loading: loading,
              title: 'Ship-To Release Identifier',
              options: (enumValues.ShipToReleaseIdentifier || []).map((identifier) => ({
                value: identifier,
                label: getReleaseIdentifierName(identifier)
              }))
            }}
          />
          <OnChange name="shipToReleaseIdentifier">
            {(value: string, previous: string) => {
              if (value) {
                const previousValue: string[] = (
                  form.getState().values.storeConfigRequiredIds || []
                ).filter((value: string | undefined) => !!value);
                const modified = form.getFieldState('shipToReleaseIdentifier')?.modified;
                if (modified) {
                  if (!previousValue.includes(value)) {
                    form.mutators.setStoreConfigRequiredIds([
                      value,
                      ...previousValue.filter((field) => field !== previous)
                    ]);
                  } else {
                    const newRequiredIds = previousValue
                      .filter((field) => field !== previous)
                      .sort((value1, value2) => (value1 === value ? -1 : value2 === value ? 1 : 0));
                    form.mutators.setStoreConfigRequiredIds(newRequiredIds);
                  }
                }
              }
            }}
          </OnChange>
        </AlloyCol>
        <AlloyCol span={12}>
          <AlloyFormField
            component="input"
            data-testid="tp-name-input"
            name="name"
            required
            fieldProps={{
              title: 'Name'
            }}
          />
          <AlloyFormField
            component="select"
            data-testid="tp-business-unit-id-select"
            name="businessUnitId"
            required
            fieldProps={{
              loading: initLoading,
              title: 'Business Unit',
              options: businessUnits.map((businessUnit) => ({
                value: businessUnit.id || '',
                label: businessUnit.name || ''
              }))
            }}
          />
          <AlloyFormField
            component="select"
            data-test-id="tp-purchase-order-ack-transport-select"
            name="purchaseOrderAcknowledgementTransport"
            required
            fieldProps={{
              loading: loading,
              title: 'Purchase Order Acknowledgement Transport',
              options: (enumValues.PurchaseOrderAcknowledgementTransport || []).map(
                (transport) => ({
                  value: transport,
                  label: transport
                })
              )
            }}
          />
          <AlloyFormField
            component="select"
            data-testid="tp-measurement-system-select"
            name="measurementSystem"
            required
            fieldProps={{
              loading: loading,
              title: 'Measurement System',
              options: (enumValues.MeasurementSystem || []).map((measurementSystem) => ({
                value: measurementSystem,
                label: measurementSystem
              }))
            }}
          />
        </AlloyCol>
      </AlloyRow>
      <AlloyRow className={s.title_row}>Add features</AlloyRow>
      <AlloyRow gutter={16} className={s.row}>
        <AlloyCol span={16}>
          <AlloyFormField
            component="checkbox"
            data-testid="tp-send-initial-purchase-order-ack-checkbox"
            name="sendInitialPurchaseOrderAcknowledgment"
            fieldProps={{
              title: 'Send Initial Purchase Order Acknowledgment'
            }}
            inline="after"
          />
        </AlloyCol>
        <AlloyCol span={24}>
          <AlloyFormField
            component="checkbox"
            data-testid="tp-send-purchase-order-processing-state-checkbox"
            name="sendPurchaseOrderProcessingState"
            fieldProps={{
              title: 'Send Purchase Order Processing State'
            }}
            inline="after"
            hideInfo="WITH_MARGIN"
          />
        </AlloyCol>
      </AlloyRow>
      <AlloyCollapse
        bordered={false}
        className={s.collapse}
        activeKey={getOpenPanels(form)}
        onChange={(keys) => {
          form.mutators.setShowAdditionalFields(
            (typeof keys === 'string' ? [keys] : keys).includes('5')
          );
        }}
      >
        <AlloyCollapse.Panel
          header={
            <div className={s.header}>
              Processing Flow
              <AlloyFormField
                component="switch"
                data-testid="tp-show-processing-flow-switch"
                name="showProcessingFlow"
                hideInfo="WITH_MARGIN"
              />
            </div>
          }
          key="1"
        >
          {form.getFieldState('showProcessingFlow')?.value ? (
            <>
              <AlloyRow>
                <AlloyCol span={12}>
                  <AlloyFormField
                    component="checkbox"
                    inline="after"
                    name="autoRelease"
                    fieldProps={{
                      title: 'Auto Release'
                    }}
                  />
                </AlloyCol>
                <AlloyCol span={12}>
                  <AlloyFormField
                    component="checkbox"
                    inline="after"
                    name="autoAcknowledge"
                    fieldProps={{
                      title: 'Auto Acknowledge'
                    }}
                  />
                </AlloyCol>
              </AlloyRow>
              <AlloyRow gutter={16}>
                <AlloyCol span={12}>
                  <AlloyFormField
                    component="select"
                    data-testid="tp-ingest-po-select"
                    name="ingestPo"
                    fieldProps={{
                      loading: loading,
                      title: 'Ingest PO',
                      options: (enumValues.ProcessingFlowIngestPo || []).map((ingestPo) => ({
                        value: ingestPo,
                        label: ingestPo
                      }))
                    }}
                    required
                  />
                  {user?.availableActions.includes('ROUTE_TO_OPTIMAL_DC') && (
                    <AlloyFormField
                      component="select"
                      name="routeToOptimalDc"
                      fieldProps={{
                        loading: loading,
                        title: 'Route To Optimal DC',
                        options: (enumValues.ProcessingFlowRouteToOptimalDc || []).map(
                          (routeToOptimalDc) => ({
                            label: routeToOptimalDc,
                            value: routeToOptimalDc
                          })
                        )
                      }}
                    />
                  )}
                  <AlloyFormField
                    component="select"
                    data-testid="tp-cancel-po-select"
                    name="cancelPo"
                    fieldProps={{
                      loading: loading,
                      title: 'Cancel PO',
                      options: (enumValues.ProcessingFlowCancelPo || []).map((cancelPo) => ({
                        value: cancelPo,
                        label: cancelPo
                      }))
                    }}
                  />
                  <AlloyFormField
                    component="select"
                    data-testid="tp-release-ack-select"
                    name="releaseAcknowledgement"
                    fieldProps={{
                      loading: loading,
                      title: 'Release Acknowledgement',
                      options: (
                        enumValues.ProcessingFlowReleaseExternalAcknowledgementType || []
                      ).map((releaseAcknowledgement) => ({
                        value: releaseAcknowledgement,
                        label: releaseAcknowledgement
                      }))
                    }}
                  />
                  <AlloyFormField
                    component="select"
                    data-testid="tp-release-shipment-select"
                    name="releaseShipment"
                    fieldProps={{
                      loading: loading,
                      title: 'Release Shipment',
                      options: (enumValues.ProcessingFlowReleaseExternalShipmentType || []).map(
                        (releaseShipment) => ({
                          value: releaseShipment,
                          label: releaseShipment
                        })
                      )
                    }}
                  />
                  <AlloyFormField
                    component="select"
                    data-testid="tp-release-invoice-select"
                    name="releaseInvoice"
                    fieldProps={{
                      loading: loading,
                      title: 'Release Invoice',
                      options: (enumValues.ProcessingFlowReleaseInvoiceType || []).map(
                        (releaseInvoice) => ({
                          value: releaseInvoice,
                          label: releaseInvoice
                        })
                      )
                    }}
                  />

                  <AlloyFormField
                    component="select"
                    data-testid="tp-order-splitting-select"
                    name="orderSplitting"
                    fieldProps={{
                      loading: loading,
                      title: 'Order Splitting',
                      options: (enumValues.OrderSplittingType || []).map((orderSplitting) => ({
                        value: orderSplitting,
                        label: orderSplitting
                      }))
                    }}
                  />
                </AlloyCol>
                <AlloyCol span={12}>
                  {user?.availableActions.includes('PREPARE_PO') && (
                    <AlloyFormField
                      component="select"
                      data-testid="tp-prepare-po-select"
                      name="preparePo"
                      fieldProps={{
                        loading: loading,
                        title: 'Prepare PO',
                        options: (enumValues.ProcessingFlowPreparePo || []).map((preparePo) => ({
                          value: preparePo,
                          label: preparePo
                        }))
                      }}
                    />
                  )}
                  <AlloyFormField
                    component="select"
                    data-testid="tp-update-po-select"
                    name="updatePo"
                    fieldProps={{
                      loading: loading,
                      title: 'Update PO',
                      options: (enumValues.ProcessingFlowUpdatePo || []).map((updatePo) => ({
                        value: updatePo,
                        label: updatePo
                      }))
                    }}
                  />
                  <AlloyFormField
                    component="select"
                    data-testid="tp-reset-po-select"
                    name="resetPo"
                    fieldProps={{
                      loading: loading,
                      title: 'Reset PO',
                      options: (enumValues.ProcessingFlowResetPo || []).map((resetPo) => ({
                        label: resetPo,
                        value: resetPo
                      }))
                    }}
                  />
                  <AlloyFormField
                    component="select"
                    data-testid="tp-release-exchange-return-select"
                    name="releaseExchangeReturn"
                    fieldProps={{
                      loading: loading,
                      title: 'Release Exchange Return',
                      options: (
                        enumValues.ProcessingFlowReleaseExternalExchangeReturnType || []
                      ).map((releaseExchangeReturn) => ({
                        value: releaseExchangeReturn,
                        label: releaseExchangeReturn
                      }))
                    }}
                  />
                  <AlloyFormField
                    component="select"
                    data-testid="tp-release-inventory-select"
                    name="releaseInventory"
                    fieldProps={{
                      loading: loading,
                      title: 'Release Inventory',
                      options: (enumValues.ProcessingFlowReleaseInventoryType || []).map(
                        (releaseInventory) => ({
                          value: releaseInventory,
                          label: releaseInventory
                        })
                      )
                    }}
                  />
                  <AlloyFormField
                    component="select"
                    data-testid="tp-fulfillment-sync-select"
                    name="fulfillmentSync"
                    fieldProps={{
                      loading: loading,
                      title: 'Fulfillment Sync',
                      options: (enumValues.ProcessingFlowFulfillmentSyncType || []).map(
                        (fulfillmentSync) => ({
                          value: fulfillmentSync,
                          label: fulfillmentSync
                        })
                      )
                    }}
                  />
                </AlloyCol>
              </AlloyRow>
            </>
          ) : (
            <div className={s.not_active}>Not active</div>
          )}
        </AlloyCollapse.Panel>
        <AlloyCollapse.Panel
          header={
            <div className={s.header}>
              Ship-To Required Fields
              <AlloySwitch
                checked={true}
                onClick={(checked, event) => {
                  event.stopPropagation();
                }}
              />
            </div>
          }
          key="2"
        >
          <AlloyRow>
            <AlloyCol span={24}>
              <AlloyFormField
                component="select"
                data-testid="storeConfigRequiredIds"
                name="storeConfigRequiredIds"
                fieldProps={{
                  loading: loading,
                  title: 'Required IDs',
                  options: (enumValues.ShipToReleaseIdentifier || []).map((identifier) => ({
                    value: identifier,
                    label: getReleaseIdentifierName(identifier),
                    disabled: (() => {
                      const storeConfigRequiredIds =
                        form.getFieldState('storeConfigRequiredIds')?.value || [];
                      const shipToReleaseIdentifier =
                        form.getFieldState('shipToReleaseIdentifier')?.value;
                      return (
                        identifier === shipToReleaseIdentifier &&
                        storeConfigRequiredIds.includes(shipToReleaseIdentifier)
                      );
                    })()
                  })),
                  mode: 'multiple',
                  maxTagCount: 'responsive'
                }}
                required
                validate={
                  !upserting
                    ? composeValidators(
                        Array.of<any>((value: string | string[]) => {
                          const shipToReleaseIdentifier =
                            form.getFieldState('shipToReleaseIdentifier')?.value;
                          const requiredIds: string[] = typeof value === 'string' ? [value] : value;
                          if (!requiredIds.includes(shipToReleaseIdentifier)) {
                            return 'Ship-To Release Identifier should be required';
                          }
                          return null;
                        })
                      )
                    : undefined
                }
              />
            </AlloyCol>
          </AlloyRow>
        </AlloyCollapse.Panel>
        <AlloyCollapse.Panel
          header={
            <div className={s.header}>
              PO Validation Config
              <AlloyFormField
                component="switch"
                data-testid="tp-po-validation-config-switch"
                name="showPoValidationConfig"
                hideInfo="WITH_MARGIN"
              />
            </div>
          }
          key="3"
        >
          {form.getFieldState('showPoValidationConfig')?.value ? (
            <AlloyRow gutter={8} className={s.margin_top}>
              <AlloyCol span={12}>
                <AlloyFormField
                  component="select"
                  data-testid="tp-po-validation-config-price-mismatch-select"
                  name="poValidationConfigPriceMismatch"
                  fieldProps={{
                    loading: loading,
                    title: 'Price Mismatch',
                    options: (enumValues.ValidationLevel || []).map((level) => ({
                      value: level,
                      label: level
                    }))
                  }}
                  required
                />
                <AlloyFormField
                  component="select"
                  data-testid="tp-po-validation-under-moq-select"
                  name="poValidationConfigUnderMoq"
                  fieldProps={{
                    loading: loading,
                    title: 'Under MOQ',
                    options: (enumValues.ValidationLevel || []).map((level) => ({
                      value: level,
                      label: level
                    }))
                  }}
                  required
                />
              </AlloyCol>
              <AlloyCol span={12}>
                <AlloyFormField
                  component="select"
                  data-testid="tp-po-validation-config-wrong-uom-select"
                  name="poValidationConfigWrongUom"
                  fieldProps={{
                    loading: loading,
                    title: 'Wrong UOM',
                    options: (enumValues.ValidationLevel || []).map((level) => ({
                      value: level,
                      label: level
                    }))
                  }}
                  required
                />
                <AlloyFormField
                  component="select"
                  data-testid="tp-po-validation-unknown-product-identifier"
                  name="poValidationConfigUnknownProductIdentifier"
                  fieldProps={{
                    loading: loading,
                    title: 'Unknown Product ID',
                    options: (enumValues.ValidationLevel || []).map((level) => ({
                      value: level,
                      label: level
                    }))
                  }}
                  required
                />
              </AlloyCol>
            </AlloyRow>
          ) : (
            <div className={s.not_active}>Not active</div>
          )}
        </AlloyCollapse.Panel>
        <AlloyCollapse.Panel
          header={
            <div className={s.header}>
              Release Rules
              <AlloyFormField
                component="switch"
                data-testid="tp-release-rules-switch"
                name="showSourcingRules"
                hideInfo="WITH_MARGIN"
              />
            </div>
          }
          key="4"
        >
          {form.getFieldState('showSourcingRules')?.value ? (
            <>
              <FieldArray
                name="sourcingRules"
                validate={(value) => {
                  const error = (value || []).some(
                    (item) => !item.description || !item.releaseMethod
                  );
                  return error ? 'Name and Release Method are required for the Release Rule' : null;
                }}
              >
                {({ fields, meta }) => {
                  const onChange = (newSelectedItems: (SourcingRuleInput & { key: string })[]) => {
                    form.mutators.setSourcingRules(
                      newSelectedItems
                        .filter(
                          (item) =>
                            !item.id || !defaultSourcingRules.map((dsr) => dsr.id).includes(item.id)
                        )
                        .map((item, index) => ({
                          ...item,
                          priority: index + 1
                        }))
                    );
                  };

                  const getItemBodyRender = (
                    item: SourcingRuleInput & { finalFormParentName?: string },
                    _index: number,
                    disabled: boolean
                  ) => (
                    <div>
                      <div className={s.sourcing_rule_row}>
                        {item.finalFormParentName ? (
                          <AlloyFormField
                            component="input"
                            data-testid="tp-release-rules-item-final-description-new-input"
                            name={`${item.finalFormParentName}.description`}
                            hideInfo="WITH_MARGIN"
                            fieldProps={{
                              className: s.name_field,
                              placeholder: 'Name',
                              disabled: disabled
                            }}
                            required
                          />
                        ) : (
                          <AlloyInput
                            value={item.description}
                            className={s.name_field}
                            placeholder="Name"
                            disabled={disabled}
                          />
                        )}
                        {item.finalFormParentName ? (
                          <AlloyFormField
                            component="select"
                            data-testid="tp-release-rules-item-dc-id-new-select"
                            name={`${item.finalFormParentName}.distributionCenterId`}
                            hideInfo="WITH_MARGIN"
                            fieldProps={{
                              className: s.dc_field,
                              disabled: disabled,
                              options: allowedDistributionCenters
                                ?.map((dc) => ({
                                  label: `${dc.name} (${dc.code})`,
                                  value: dc.id
                                }))
                                .sort((dc1, dc2) => dc1.label.localeCompare(dc2.label))
                            }}
                          />
                        ) : (
                          <AlloySelect
                            value={item.distributionCenterId ?? undefined}
                            className={s.dc_field}
                            disabled={disabled}
                            options={allowedDistributionCenters
                              ?.map((dc) => ({
                                label: `${dc.name} (${dc.code})`,
                                value: dc.id
                              }))
                              .sort((dc1, dc2) => dc1.label.localeCompare(dc2.label))}
                            allowClear
                          />
                        )}
                      </div>
                      <div className={s.sourcing_rule_row}>
                        <span className={s.weight_title}>Weight Limit</span>
                        {item.finalFormParentName ? (
                          <AlloyFormField
                            component="inputNumber"
                            data-testid="tp-release-rules-item-weight-limit-new-input"
                            name={`${item.finalFormParentName}.minWeightLbs`}
                            hideInfo="WITH_MARGIN"
                            fieldProps={{
                              className: s.weight_field,
                              disabled: disabled,
                              placeholder: '0',
                              suffix: 'Lb'
                            }}
                            validate={(value) => {
                              if (value && Number(value) < 0) return 'Should not be less than 0';
                              return null;
                            }}
                          />
                        ) : (
                          <AlloyInputNumber
                            value={item.minWeightLbs ? `${item.minWeightLbs}` : undefined}
                            className={s.weight_field}
                            disabled={disabled}
                            placeholder="0"
                            suffix="Lb"
                          />
                        )}
                        {item.finalFormParentName ? (
                          <AlloyFormField
                            component="inputNumber"
                            data-testid="tp-release-rules-item-max-weight-lbs-new-input"
                            name={`${item.finalFormParentName}.maxWeightLbs`}
                            hideInfo="WITH_MARGIN"
                            fieldProps={{
                              className: s.weight_field,
                              disabled: disabled,
                              placeholder: '0',
                              suffix: 'Lb'
                            }}
                            validate={(value, allValues) => {
                              if (!value) return null;
                              if (Number(value) < 0) return 'Should not be less than 0';
                              if (
                                get(allValues, `${item.finalFormParentName}.minWeightLbs`) &&
                                Number(get(allValues, `${item.finalFormParentName}.minWeightLbs`)) >
                                  Number(value)
                              )
                                return 'Should not be less than Min Weight';
                              return null;
                            }}
                          />
                        ) : (
                          <AlloyInputNumber
                            value={item.maxWeightLbs ? `${item.maxWeightLbs}` : undefined}
                            className={s.weight_field}
                            disabled={disabled}
                            placeholder="0"
                            suffix="Lb"
                          />
                        )}
                        {item.finalFormParentName ? (
                          <AlloyFormField
                            component="select"
                            data-testid="tp-release-rules-item-release-method-new-select"
                            name={`${item.finalFormParentName}.releaseMethod`}
                            hideInfo="WITH_MARGIN"
                            fieldProps={{
                              className: s.release_method_field,
                              disabled: disabled,
                              options: enumValues.ReleaseMethod?.map((value) => ({
                                label: value,
                                value: value
                              }))
                            }}
                            required
                          />
                        ) : (
                          <AlloySelect
                            value={item.releaseMethod}
                            className={s.release_method_field}
                            disabled={disabled}
                            options={enumValues.ReleaseMethod?.map((value) => ({
                              label: value,
                              value: value
                            }))}
                          />
                        )}
                      </div>
                    </div>
                  );

                  return (
                    <>
                      <div className={clsx(s.sourcing_rules_container, s.margin_top)}>
                        <PrioritizedList<
                          SourcingRuleInput & { key: string; finalFormParentName?: string }
                        >
                          data-testid="edit-trading-partner-sourcing-rules-list"
                          selected={[
                            ...(fields.value || [])
                              .sort((dc1, dc2) => dc1.priority - dc2.priority)
                              .map((item, i) => ({
                                ...item,
                                key: item.key || item.id,
                                finalFormParentName: `sourcingRules[${i}]`
                              })),
                            ...(defaultSourcingRules || []).map((item) => ({
                              ...item,
                              key: item.id
                            }))
                          ]}
                          onChange={onChange}
                          itemBodyRender={(item, index, disabled) =>
                            getItemBodyRender(item, index, disabled)
                          }
                          itemKey={(item) => item.key}
                          disabledItems={defaultSourcingRules.map((dsr) => dsr.id)}
                          overrideDisabledIndex={(item) => item.priority - 1}
                        />
                        <div className={s.add_sourcing_rule_button}>
                          <button
                            onClick={() => {
                              form.mutators.setSourcingRules([
                                ...(fields.value || []).sort(
                                  (dc1, dc2) => dc1.priority - dc2.priority
                                ),
                                {
                                  priority: fields.length,
                                  key: uuidv4()
                                }
                              ]);
                            }}
                          >
                            Add Rule
                          </button>
                          <img alt="" src={addIcon} />
                        </div>
                      </div>
                      <FieldInfo
                        error={meta.error && (meta.dirty || meta.touched) ? meta.error : undefined}
                      />
                    </>
                  );
                }}
              </FieldArray>
            </>
          ) : (
            <div className={s.not_active}>Not active</div>
          )}
        </AlloyCollapse.Panel>
        <AlloyCollapse.Panel
          header={<div className={s.header}>Additional Settings</div>}
          key="5"
          forceRender
        >
          <AlloyRow gutter={16}>
            <AlloyCol span={12}>
              <AlloyFormField
                component="select"
                data-testid="tp-additional-settings-incoming-uom-override-select"
                name="incomingUnitOfMeasurementOverride"
                fieldProps={{
                  loading: loading,
                  title: 'Incoming Unit Of Measurement Override',
                  options: (enumValues.UnitOfMeasure || []).map((unit) => ({
                    value: unit,
                    label: unit
                  }))
                }}
              />
              <AlloyFormField
                component="select"
                data-testid="tp-additional-settings-outgoing-uom-override-select"
                name="outgoingUnitOfMeasurementOverride"
                fieldProps={{
                  loading: loading,
                  title: 'Outgoing Unit Of Measurement Override',
                  options: (enumValues.UnitOfMeasure || []).map((unit) => ({
                    value: unit,
                    label: unit
                  }))
                }}
              />
              <AlloyFormField
                component="select"
                data-testid="tp-additional-settings-release-method-select"
                name="releaseMethod"
                fieldProps={{
                  loading: loading,
                  title: 'Release Method',
                  options: (enumValues.ReleaseMethod || []).map((method) => ({
                    value: method,
                    label: method
                  }))
                }}
              />
            </AlloyCol>
            <AlloyCol span={12}>
              <AlloyFormField
                component="select"
                data-testid="tp-additional-settings-internal-product-id-qualifier-select"
                name="internalProductIdQualifier"
                fieldProps={{
                  loading: loading,
                  title: 'Internal Product ID Qualifier',
                  options: (enumValues.InternalProductIdQualifier || []).map((qualifier) => ({
                    value: qualifier,
                    label: qualifier
                  }))
                }}
              />
              <AlloyFormField
                component="select"
                data-testid="tp-additional-settings-product-id-qualifier-select"
                name="productIdQualifier"
                fieldProps={{
                  loading: loading,
                  title: 'Product ID Qualifier',
                  options: (enumValues.ProductIdQualifier || []).map((qualifier) => ({
                    value: qualifier,
                    label: qualifier
                  }))
                }}
              />
              <AlloyFormField
                component="select"
                data-testid="tp-additional-settings-order-item-rounding-select"
                name="orderItemRounding"
                fieldProps={{
                  loading: loading,
                  title: 'Order Item Rounding',
                  options: (enumValues.OrderItemRounding || []).map((value) => ({
                    value: value,
                    label: value
                  }))
                }}
                required
              />
            </AlloyCol>
          </AlloyRow>
        </AlloyCollapse.Panel>
      </AlloyCollapse>
    </>
  );

  return (
    <>
      <div>{generalFields}</div>
      {showAddRetailerChannelModal && (
        <EditRetailerChannelModal
          vendorMarket={{} as UpsertVendorMarketInput}
          onClose={(id) => {
            if (id) {
              setVendorMarketId(id, form);
            }
            setShowAddRetailerChannelModal(false);
          }}
        />
      )}
    </>
  );
};

export const getReleaseIdentifierName = (identifier: string): string => {
  const id = Object.keys(Titles).find((titleId) => titleId === identifier);
  if (id) {
    return Titles[id as keyof typeof Titles];
  }
  return acronymsToUpperCase(camelCaseToSpaces(fieldNameToCapitalize(identifier)));
};
