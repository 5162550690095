import React, { useEffect, useMemo, useState } from 'react';
import s from './CompletedView.module.scss';
import ErrorDisplay from 'components/Common/ErrorDisplay';
import AlloyProgressBar from 'components/ui/AlloyProgressBar/AlloyProgressBar';
import { AlloySpin } from 'components/ui/AlloySpin/AlloySpin';
import { AlloyTable, ColumnsType, SorterResult } from 'components/ui/AlloyTable/AlloyTable';
import { AlloyInput } from 'components/ui/AlloyInput/AlloyInput';
import { SearchOutlined } from '@ant-design/icons';
import { AlloySelect } from 'components/ui/AlloySelect/AlloySelect';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { QuantityDisplay } from 'components/ui/QuantityDisplay/QuantityDisplay';
import { useMutation, useQuery } from '@apollo/client';
import {
  WarehouseCompletedTruckloadsDocument,
  WarehouseCompletedTruckloadsQuery
} from './gql/__generated__/warehouseCompletedTruckloads.query';
import { NumberParam, StringParam, useQueryParam, withDefault } from 'use-query-params';
import { DEFAULT_PAGE_SIZE_OPTIONS } from 'common/constants';
import { SortOrderDirection, TruckloadSortColumn } from 'graphql/__generated__/types';
import { getNodesFromEdges, InferNodeType } from 'common/helpers/mappingHelper';
import { App, Empty } from 'ant5';
import { ExpandButton } from 'components/ui/ExpandButton/ExpandButton';
import { notEmpty } from 'common/helpers/notEmpty';
import { getColumnSortOrder } from 'common/helpers/sorting';
import clsx from 'clsx';
import { formatNumber } from 'common/helpers/formatNumber';
import { calculatePercentageOfCapacity } from '../helper';
import { dateFormat } from 'common/helpers/date';
import { WarehouseTruckloadsDocument } from '../TruckloadsView/gql/__generated__/warehouseTruckloads.query';
import { RemoveCompletedWarehouseTruckloadsDocument } from './gql/__generated__/removeCompletedWarehouseTruckloads.query';
import { exportTruckloadsData } from '../utils/exportTruckloadsData';

type WarehouseCompletedTruckloads = InferNodeType<
  WarehouseCompletedTruckloadsQuery,
  'warehouseTruckloads'
>;

type CompletedOrders = NonNullable<NonNullable<WarehouseCompletedTruckloads['orders']>[number]>;

const getRowKey = (record: WarehouseCompletedTruckloads) => record.truck;
const getExpandedRowKey = (order: CompletedOrders) => order.orderNumber;

const COMPLETED_TRUCKLOADS_DEFAULT_PAGE_SIZE = DEFAULT_PAGE_SIZE_OPTIONS[3];
const TABLE_WIDTH = 1590;

export const CompletedView = () => {
  const { message } = App.useApp();
  const [selectedRows, setSelectedRows] = useState<WarehouseCompletedTruckloads[]>([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);

  const [pageSize, setPageSize] = useQueryParam(
    'limit',
    withDefault(NumberParam, COMPLETED_TRUCKLOADS_DEFAULT_PAGE_SIZE)
  );

  const [sortColumn, setSortColumn] = useQueryParam('completed_truckloads_column', StringParam);
  const [sortOrder, setSortOrder] = useQueryParam('completed_truckloads_order', StringParam);
  const [after, setAfter] = useQueryParam('completed_truckloads_after', StringParam);
  const [before, setBefore] = useQueryParam('completed_truckloads_before', StringParam);

  const { data, loading, error } = useQuery(WarehouseCompletedTruckloadsDocument, {
    variables: {
      first: after || !before ? pageSize : null,
      last: before ? pageSize : null,
      sort: {
        column: sortColumn as TruckloadSortColumn,
        direction: sortOrder as SortOrderDirection
      },
      filter: { statuses: ['PENDING_SCHEDULING'] },
      after,
      before
    }
  });

  const completedTruckloadsList = useMemo(
    () => getNodesFromEdges(data?.warehouseTruckloads),
    [data]
  );

  const [
    removeCompletedWarehouseTruckloads,
    { loading: removeCompletedWarehouseTruckloadsLoading }
  ] = useMutation(RemoveCompletedWarehouseTruckloadsDocument, {
    onCompleted: (data) => {
      message.success({
        content: data.removeCompletedWarehouseTruckloads?.message,
        duration: 4.5
      });
      setSelectedRows([]);
    },
    onError: (error) => {
      message.error(error.message);
      console.log(error.message);
    },
    refetchQueries: [WarehouseCompletedTruckloadsDocument, WarehouseTruckloadsDocument]
  });

  const handleRemoveCompletedWarehouseTruckloads = async () => {
    const trucks = selectedRows.map((row) => row.truck);
    await removeCompletedWarehouseTruckloads({
      variables: {
        input: {
          trucks
        }
      }
    });
  };

  useEffect(() => {
    if (!pageSize || !sortColumn || !sortOrder) {
      setPageSize(COMPLETED_TRUCKLOADS_DEFAULT_PAGE_SIZE);
      setSortColumn('SHIPMENT');
      setSortOrder('DESC');
    }
  }, [pageSize, setPageSize, setSortColumn, setSortOrder, sortColumn, sortOrder]);

  const handleTableSorting = (column: string, order: string) => {
    setSortColumn(column);
    setSortOrder(order);
    setAfter(undefined);
    setBefore(undefined);
  };

  const completedTruckloadsColumns: ColumnsType<WarehouseCompletedTruckloads> = [
    AlloyTable.SELECTION_COLUMN,
    {
      title: 'Shipments',
      key: 'SHIPMENT',
      width: 198,
      render: (_, record) => `Truck ${record.truck}`,
      sortOrder: getColumnSortOrder(sortColumn, sortOrder, 'SHIPMENT'),
      sorter: true
    },
    // {
    //   title: 'BOL',
    //   width: '198px',
    //   render: (_, record) => record.bolId //TODO: this column is waiting for BE and Design
    // },
    {
      title: 'Orders',
      key: 'ORDER_NUMBER',
      width: 198,
      render: (_, record) => {
        if (record.orders && record.orders.length > 1) {
          return `${record.orders.length} orders`;
        } else if (record.orders && record.orders.length) {
          return record.orders.map((order) => order?.orderNumber);
        }
      }
    },
    {
      title: 'Total Weights',
      key: 'TOTAL_WEIGHTS',
      width: 198,
      align: 'right',
      render: (_, record) => (
        <>
          <span className={clsx(s.total_weights, s.number_value)}>
            {formatNumber(record.totalWeights)}
          </span>
          <span>{`(${calculatePercentageOfCapacity(record.totalWeights || 0)}%)`}</span>
        </>
      ),
      sortOrder: getColumnSortOrder(sortColumn, sortOrder, 'TOTAL_WEIGHTS'),
      sorter: true
    },
    {
      title: '',
      key: 'LOAD_PROGRESS',
      width: 140,
      render: (_, record) => (
        <AlloyProgressBar
          style={{ width: 95 }}
          progress={calculatePercentageOfCapacity(record.totalWeights || 0)}
        />
      )
    },
    {
      title: 'Pallets',
      key: 'PALLETS',
      align: 'right',
      width: 198,
      render: (_, record) => (
        <span className={s.number_value}>{parseFloat(record.totalPallets || '0').toFixed(2)}</span>
      ),
      sortOrder: getColumnSortOrder(sortColumn, sortOrder, 'PALLETS'),
      sorter: true
    },
    {
      title: 'Ship Date',
      key: 'SHIP_DATE',
      minWidth: 82,
      render: (_, record) => dateFormat(record.shipDate),
      sortOrder: getColumnSortOrder(sortColumn, sortOrder, 'SHIP_DATE'),
      sorter: true
    },
    {
      title: 'Suggested RDD',
      key: 'SUGGESTED_RDD',
      minWidth: 82,
      render: (_, record) => dateFormat(record.suggestedRdd),
      sortOrder: getColumnSortOrder(sortColumn, sortOrder, 'SUGGESTED_RDD'),
      sorter: true
    }
  ];

  const EmptyCell = () => {};

  const FAKE_SELECT_AND_TRUCKLOADS_COLUMN = {
    title: '',
    key: 'expanderSelectAndTruckloads',
    width: 230,
    render: EmptyCell
  };

  const completedOrderColumns: ColumnsType<CompletedOrders> = [
    FAKE_SELECT_AND_TRUCKLOADS_COLUMN,
    {
      key: 'ORDER_NUMBER',
      width: 198,
      render: (_, record) => record.orderNumber
    },
    {
      key: 'TOTAL_WEIGHTS',
      width: 198,
      align: 'right',
      render: (_, record) => (
        <>
          <span className={clsx(s.total_weights, s.number_value)}>
            {formatNumber(record.totalWeights)}
          </span>
          <span>{`(${calculatePercentageOfCapacity(record.totalWeights || 0)}%)`}</span>
        </>
      ),
      sortOrder: getColumnSortOrder(sortColumn, sortOrder, 'TOTAL_WEIGHTS'),
      sorter: true
    },
    {
      key: 'LOAD_PROGRESS',
      width: 140,
      render: (_, record) => (
        <AlloyProgressBar
          style={{ width: 95 }}
          progress={calculatePercentageOfCapacity(record.totalWeights || 0)}
        />
      )
    },
    {
      key: 'PALLETS',
      align: 'right',
      width: 198,
      render: (_, record) => (
        <span className={s.number_value}>{parseFloat(record.totalPallets || '0').toFixed(2)}</span>
      ),
      sortOrder: getColumnSortOrder(sortColumn, sortOrder, 'PALLETS'),
      sorter: true
    },
    {
      key: 'SHIP_DATE',
      minWidth: 82,
      render: (_, record) => dateFormat(record.shipDate),
      sortOrder: getColumnSortOrder(sortColumn, sortOrder, 'SHIP_DATE'),
      sorter: true
    },
    {
      key: 'SUGGESTED_RDD',
      minWidth: 82,
      render: (_, record) => dateFormat(record.suggestedRdd),
      sortOrder: getColumnSortOrder(sortColumn, sortOrder, 'SUGGESTED_RDD'),
      sorter: true
    }
  ];

  const currentPageCompletedTruckloadsExpandableKeys = useMemo(() => {
    return completedTruckloadsList.map(getRowKey);
  }, [completedTruckloadsList]);

  const areAllKeysExpandedOnThePage = useMemo(
    () =>
      currentPageCompletedTruckloadsExpandableKeys.every((truckload) =>
        expandedRowKeys.includes(truckload)
      ),
    [expandedRowKeys, currentPageCompletedTruckloadsExpandableKeys]
  );

  const expandableCompletedTruckloadRows = useMemo(() => {
    return completedTruckloadsList.filter((truckload) => truckload.orders.length > 1);
  }, [completedTruckloadsList]);

  const CompletedTruckloadsTableEmptyText = (
    <div className={s.table_empty_state_title}>No items found</div>
  );

  const onResendPLCE = () => {
    const truckloadsNumbers = selectedRows.map((row) => row.truck);
    const truckloads = completedTruckloadsList.filter((truck) =>
      truckloadsNumbers.includes(truck.truck)
    );

    try {
      exportTruckloadsData(truckloads);
    } catch (e) {
      message.error(e.message);
      console.error(e.message);
      return;
    }

    message.success(
      `${truckloadsNumbers.length} shipment${truckloadsNumbers.length === 1 ? '' : 's'} moved to PLCE`
    );
  };

  if (error) return <ErrorDisplay error={error} />;

  return (
    <>
      <AlloySpin spinning={loading}>
        <div className={s.table_tools_container}>
          <div
            data-testid="completed-truckloads-search-and-filters-container"
            className={s.search_and_filters_container}
          >
            <div className={s.search_bar}>
              <AlloyInput
                placeholder="Search"
                allowClear={true}
                prefix={<SearchOutlined width="14px" height="14px" />}
                disabled={true}
              />
            </div>

            <AlloySelect
              className={s.select}
              placeholder="Filter"
              allowClear
              options={[
                {
                  key: 'all',
                  value: 'all',
                  label: 'All'
                }
              ]}
              disabled={true}
            />
          </div>

          <div className={s.table_actions_container}>
            <AlloyButton
              data-testid="completed-truckloads-remove-button"
              loading={removeCompletedWarehouseTruckloadsLoading}
              disabled={selectedRows.length === 0}
              onClick={handleRemoveCompletedWarehouseTruckloads}
              type="secondary"
            >
              Remove
            </AlloyButton>

            <AlloyButton
              data-testid="completed-truckloads-resend-plce-button"
              disabled={selectedRows.length === 0}
              onClick={onResendPLCE}
              type="primary"
            >
              Resend PLCE
            </AlloyButton>
          </div>
        </div>

        <div className={s.quantity_display_container}>
          <QuantityDisplay
            count={
              selectedRows?.length > 0 ? selectedRows.length : data?.warehouseTruckloads?.totalCount
            }
            titleSingular={`${selectedRows?.length > 0 ? 'Selected ' : ''} Shipment`}
            titleMultiple={`${selectedRows?.length > 0 ? 'Selected ' : ''} Shipments`}
          />
        </div>
        <AlloyTable
          data-test="completed-truckloads-table"
          scroll={{ x: TABLE_WIDTH }}
          sticky
          pagination={false}
          columns={completedTruckloadsColumns.filter(notEmpty)}
          dataSource={completedTruckloadsList}
          onChange={(_pagination, _filters, sorter) => {
            const column =
              (sorter as SorterResult<WarehouseCompletedTruckloads>).columnKey || 'SHIPMENT';
            const order =
              (sorter as SorterResult<WarehouseCompletedTruckloads>).order === 'ascend' ||
              !(sorter as SorterResult<WarehouseCompletedTruckloads>).order
                ? 'ASC'
                : 'DESC';
            handleTableSorting(column.toString(), order);
          }}
          rowKey={getRowKey}
          rowSelection={{
            preserveSelectedRowKeys: true,
            onChange: (_keys, rows) => {
              setSelectedRows(rows);
            },
            type: 'checkbox',
            selectedRowKeys: selectedRows?.map((row) => row.truck),
            // @ts-expect-error: CheckboxProps partial type has no properties in common with { data-testid: string } type
            getCheckboxProps(record) {
              return { 'data-testid': record.truck };
            }
          }}
          locale={{
            emptyText: (
              <Empty
                data-testid="completed-truckloads-table-empty-state"
                className={s.table_empty_state}
                description={CompletedTruckloadsTableEmptyText}
              />
            )
          }}
          expandable={{
            columnTitle: currentPageCompletedTruckloadsExpandableKeys.length > 0 &&
              expandableCompletedTruckloadRows.length > 0 && (
                <ExpandButton
                  expanded={areAllKeysExpandedOnThePage}
                  onClick={(expanded) => {
                    return expanded
                      ? setExpandedRowKeys(
                          expandedRowKeys.filter(
                            (x) => !currentPageCompletedTruckloadsExpandableKeys.includes(x)
                          )
                        )
                      : setExpandedRowKeys([
                          ...new Set([
                            ...currentPageCompletedTruckloadsExpandableKeys,
                            ...expandedRowKeys
                          ])
                        ]);
                  }}
                  collapseMessage="Collapse all"
                  expandMessage="Expand all"
                />
              ),
            expandedRowKeys: expandedRowKeys,
            expandedRowRender: ({ orders }) => (
              <AlloyTable
                data-testid="expanded-row-completed-truckloads-table"
                columns={completedOrderColumns.filter(notEmpty)}
                dataSource={orders?.map((order) => order).filter(notEmpty)}
                pagination={false}
                rowKey={getExpandedRowKey}
                showHeader={false}
              />
            ),
            onExpandedRowsChange: (rows) => setExpandedRowKeys(rows as number[]),
            rowExpandable: (record) => !!record.orders && record.orders.length > 1
          }}
        />
      </AlloySpin>
    </>
  );
};
