import * as Types from '../../../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type SpecialEventDetailsForEditQueryVariables = Types.Exact<{
  ids?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
}>;


export type SpecialEventDetailsForEditQuery = { __typename?: 'RootQueryType', specialEvents: Array<{ __typename?: 'SpecialEvent', id: string, name: string, private: boolean, reportingEndDate?: any | null, reportingStartDate?: any | null, eventDateStart?: any | null, eventDateEnd?: any | null, businessUnits: Array<string>, watchlistFile?: { __typename?: 'WatchlistFile', contents: string, filename: string, lastUpdated: any } | null }> };


export const SpecialEventDetailsForEditDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"SpecialEventDetailsForEdit"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"ids"}},"type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"specialEvents"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"ids"},"value":{"kind":"Variable","name":{"kind":"Name","value":"ids"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"private"}},{"kind":"Field","name":{"kind":"Name","value":"reportingEndDate"}},{"kind":"Field","name":{"kind":"Name","value":"reportingStartDate"}},{"kind":"Field","name":{"kind":"Name","value":"eventDateStart"}},{"kind":"Field","name":{"kind":"Name","value":"eventDateEnd"}},{"kind":"Field","name":{"kind":"Name","value":"businessUnits"}},{"kind":"Field","name":{"kind":"Name","value":"watchlistFile"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"contents"}},{"kind":"Field","name":{"kind":"Name","value":"filename"}},{"kind":"Field","name":{"kind":"Name","value":"lastUpdated"}}]}}]}}]}}]} as unknown as DocumentNode<SpecialEventDetailsForEditQuery, SpecialEventDetailsForEditQueryVariables>;