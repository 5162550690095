import * as Types from '../../../../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type UpdatePurchaseOrderItemsMutationVariables = Types.Exact<{
  input: Types.UpdatePurchaseOrderItemsInput;
}>;


export type UpdatePurchaseOrderItemsMutation = { __typename?: 'RootMutationType', updatePurchaseOrderItems?: { __typename?: 'UpdatePurchaseOrderItemsPayload', updated: Array<{ __typename?: 'PurchaseOrderItem', id: string }> } | null };


export const UpdatePurchaseOrderItemsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"updatePurchaseOrderItems"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdatePurchaseOrderItemsInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updatePurchaseOrderItems"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updated"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]} as unknown as DocumentNode<UpdatePurchaseOrderItemsMutation, UpdatePurchaseOrderItemsMutationVariables>;