import * as Types from '../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ProductForecastExportQueryVariables = Types.Exact<{
  filters: Types.ProductForecastExportFilters;
}>;


export type ProductForecastExportQuery = { __typename?: 'RootQueryType', productForecastExport: Array<{ __typename?: 'ProductForecastExportEntry', sapMaterialId: string, asin: Array<string>, productDescription?: string | null, brand: string, vendorCode: string, forecastValues: Array<{ __typename?: 'ProductForecastTableEntry', forecastName: Types.ProductForecastName, values: Array<{ __typename?: 'ProductForecastSingleWeekValue', value?: any | null, fiscalCalendarWeek: { __typename?: 'FiscalCalendarWeek', year: number, period: number, week: number } }> } | null> }> };


export const ProductForecastExportDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"productForecastExport"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"filters"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ProductForecastExportFilters"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"productForecastExport"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"filters"},"value":{"kind":"Variable","name":{"kind":"Name","value":"filters"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"sapMaterialId"}},{"kind":"Field","name":{"kind":"Name","value":"asin"}},{"kind":"Field","name":{"kind":"Name","value":"productDescription"}},{"kind":"Field","name":{"kind":"Name","value":"brand"}},{"kind":"Field","name":{"kind":"Name","value":"vendorCode"}},{"kind":"Field","name":{"kind":"Name","value":"forecastValues"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"forecastName"}},{"kind":"Field","name":{"kind":"Name","value":"values"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"fiscalCalendarWeek"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"year"}},{"kind":"Field","name":{"kind":"Name","value":"period"}},{"kind":"Field","name":{"kind":"Name","value":"week"}}]}},{"kind":"Field","name":{"kind":"Name","value":"value"}}]}}]}}]}}]}}]} as unknown as DocumentNode<ProductForecastExportQuery, ProductForecastExportQueryVariables>;