import React from 'react';
import s from './InvalidItemsTableECOMM.module.scss';
import { MoreOutlined } from '@ant-design/icons';
import errorIcon from '../../../../assets/icons/common/error_icon.svg';
import { InvalidPurchaseOrderItem, TradingPartnerActiveAssortmentItem } from 'common/interfaces';
import { POI_ERROR_MAP, PO_STATUS_SUBMITTED } from 'common/constants';
import { PurchaseOrderWithOverdue } from 'pages/OrderDetailsPage/types';
import { AlloyTable, ColumnsType } from 'components/ui/AlloyTable/AlloyTable';
import { AlloyDropdown } from 'components/ui/AlloyDropdown/AlloyDropdown';
import { AlloyTooltip } from 'components/ui/AlloyTooltip/AlloyTooltip';

interface InvalidItemsTableECOMMProps {
  items: InvalidPurchaseOrderItem[];
  openNewItemModal: (
    item?: Partial<TradingPartnerActiveAssortmentItem>,
    predefinedValues?: Partial<TradingPartnerActiveAssortmentItem>
  ) => void;
  purchaseOrder: PurchaseOrderWithOverdue;
  loading: boolean;
}

export const InvalidItemsTableECOMM = ({
  items,
  purchaseOrder,
  loading,
  openNewItemModal
}: InvalidItemsTableECOMMProps) => {
  const columns: ColumnsType<InvalidPurchaseOrderItem> = [
    {
      key: 'productError',
      width: '44px',
      render: (_, item) => (
        <>
          {!!item?.purchaseOrderItemErrors.length && (
            <div data-testid="error-display">
              <AlloyTooltip
                data-testid="error-display-tooltip"
                placement="right"
                title={item.purchaseOrderItemErrors
                  .map((error) => POI_ERROR_MAP[error] || error)
                  .join(', ')}
              >
                <img data-testid="error-icon" src={errorIcon} alt="" />
              </AlloyTooltip>
            </div>
          )}
        </>
      )
    },
    {
      title: 'External ID',
      dataIndex: 'externalId',
      width: '280px',
      render: (_, item) => {
        const poItem = purchaseOrder.purchaseOrderItems.find(
          (i) => i.externalId === item.purchaseOrderItemExternalId
        );
        const hasErrors =
          poItem?.purchaseOrderItemErrors && poItem.purchaseOrderItemErrors.length > 0;

        return (
          <div data-testid="external-id-container">
            <span
              data-testid="external-id-cell"
              className={!hasErrors ? s.externalId : s.externalId_with_error}
            >
              {item.purchaseOrderItemExternalId}
            </span>
          </div>
        );
      }
    },
    {
      title: 'Item Description',
      key: 'name',
      render: (_, item) => {
        const poItem = purchaseOrder.purchaseOrderItems.find(
          (i) => i.externalId === item.purchaseOrderItemExternalId
        );

        return poItem?.catalogProduct?.name ?? poItem?.product?.name;
      }
    },
    {
      title: 'Order qty',
      dataIndex: 'quantityOrdered',
      width: '120px',
      render: (_, item) => item.quantity
    },
    {
      width: '44px',
      render: (_, item) => {
        const poItem = purchaseOrder.purchaseOrderItems.find(
          (i) => i.externalId === item.purchaseOrderItemExternalId
        );
        const itemErrors = poItem?.purchaseOrderItemErrors || [];
        const inCatalog = !itemErrors.includes('PRODUCT_NOT_FOUND');

        const showAddMenu =
          (itemErrors.includes('PRODUCT_NOT_FOUND') ||
            itemErrors.includes('PRODUCT_NOT_AVAILABLE_FOR_SALE')) &&
          (purchaseOrder.statuses?.primary || '').toUpperCase() !== PO_STATUS_SUBMITTED;

        const addMenu = [
          {
            disabled: !purchaseOrder.tradingPartner?.assortmentConfig,
            // @ts-ignore
            'data-testid': 'add-item-menu-item',
            key: '0',
            onClick: () => {
              if (inCatalog) {
                openNewItemModal({
                  active: true,
                  retailerDeliveryDestinations:
                    purchaseOrder.tradingPartner?.retailerDeliveryDestinations,
                  vendorProduct: poItem?.vendorProduct
                } as Partial<TradingPartnerActiveAssortmentItem>);
              } else {
                openNewItemModal(undefined, {
                  retailerDeliveryDestinations:
                    purchaseOrder.tradingPartner?.retailerDeliveryDestinations,
                  vendorProduct: {
                    externalId: item.purchaseOrderItemExternalId,
                    substitutionProducts: [
                      {
                        product: {
                          name: item.description,
                          // item doesn't have sapMaterialId. I'll leave it here for now.
                          // sapMaterialId: item.sapMaterialId,
                          upc:
                            purchaseOrder.tradingPartner?.deliveryType === 'ECOMM'
                              ? item.purchaseOrderItemExternalId
                              : undefined
                        }
                      }
                    ]
                  }
                } as Partial<TradingPartnerActiveAssortmentItem>);
              }
            },
            label: inCatalog ? 'Add to assortment' : 'Add new product to assortment'
          }
        ];

        return showAddMenu ? (
          <AlloyDropdown
            data-testid="add-item-dropdown"
            placement="bottomRight"
            trigger={['click']}
            menu={{ items: addMenu }}
          >
            <MoreOutlined data-testid="more-icon" />
          </AlloyDropdown>
        ) : null;
      }
    }
  ];

  return (
    <AlloyTable
      data-testid="invalid-items-table-ecomm"
      className={s.table}
      loading={loading}
      dataSource={items}
      columns={columns}
      rowKey={(item) => item?.purchaseOrderItemExternalId || ''}
      sticky
      tableLayout="auto"
      pagination={false}
    />
  );
};
