import { yearPeriodWeekSorter } from 'common/helpers/periodHelper';
import { YearPeriodWeek } from 'graphql/__generated__/types';
import { OTIFReportData } from './types';
import { convertWeekToPeriodWeek } from 'common/helpers/fiscalCalendar';

const safeParsePercentage = (number: string) => {
  const value = parseFloat(number);
  if (Number.isNaN(value) || value === undefined) return undefined;
  return value * 100;
};

// Filter + add empty periods
export const mapDataWithPeriods = <T extends Omit<OTIFReportData, '__typename'>>(
  data: T[] | undefined,
  periods: YearPeriodWeek[]
) => {
  const areMultipleYearsSelected = (periods || []).some((x) => x.year !== periods?.[0]?.year);

  const sortedMetrics = [...(data || [])].sort((a, b) =>
    yearPeriodWeekSorter(a.fiscalCalendarWeek, b.fiscalCalendarWeek)
  );

  const mappedMetrics = (sortedMetrics || []).map((x) => {
    const { period, periodWeek } = convertWeekToPeriodWeek(x.fiscalCalendarWeek.week);
    return {
      ...x,
      label: `${
        areMultipleYearsSelected ? x.fiscalCalendarWeek.year : ''
      } P${period}W${periodWeek}`.trim(),
      otif: safeParsePercentage(x.otif),
      onTime: safeParsePercentage(x.onTime),
      inFull: safeParsePercentage(x.inFull),
      acceptedFillRate: safeParsePercentage(x.acceptedFillRate),
      submittedFillRate: safeParsePercentage(x.submittedFillRate)
    };
  });

  return { metrics: mappedMetrics, areMultipleYearsSelected };
};

export type MappedResponseOtif<T extends Omit<OTIFReportData, '__typename'>> = ReturnType<
  typeof mapDataWithPeriods<T>
>['metrics'][number];
