import { InferNodeType } from 'common/helpers/mappingHelper';
import { WarehouseTruckloadsQuery } from '../TruckloadsView/gql/__generated__/warehouseTruckloads.query';
import moment from 'moment';
import { exportDataToXlsx } from 'common/helpers/exportToXlsx';
import { dateFormat } from 'common/helpers/date';

type WarehouseTruckloads = InferNodeType<WarehouseTruckloadsQuery, 'warehouseTruckloads'>;

type Orders = NonNullable<NonNullable<WarehouseTruckloads['orders']>[number]>;

type PlceDocRow = {
  truck: WarehouseTruckloads['truck'];
  plant: WarehouseTruckloads['plant'];
  orderNumber: Orders['orderNumber'];
  shipToName: WarehouseTruckloads['shipToName'];
  shipToCity: WarehouseTruckloads['shipToCity'];
  suggestedRdd: WarehouseTruckloads['suggestedRdd'];
  totalWeights: WarehouseTruckloads['totalWeights'];
  totalUnits: WarehouseTruckloads['totalUnits'];
  totalPallets: WarehouseTruckloads['totalPallets'];
  customerPos: WarehouseTruckloads['customerPos'];
};

export const exportTruckloadsData = (trucks: WarehouseTruckloads[]) => {
  const fileName = `Joilet_${moment().format('MM.DD.YY')}.xlsx`;

  const flatOrders: PlceDocRow[] = trucks.reduce((acc: PlceDocRow[], truck) => {
    const orders = truck.orders.reduce((orderAcc: Orders[], order) => {
      if (!order) return orderAcc;
      return [...orderAcc, order];
    }, []);

    const rowItems = orders.map((order) => ({
      truck: truck.truck,
      plant: truck.plant,
      orderNumber: order?.orderNumber,
      customerPos: truck.customerPos,
      shipToName: truck.shipToName,
      shipToCity: truck.shipToCity,
      suggestedRdd: truck.suggestedRdd,
      totalWeights: truck.totalWeights,
      totalUnits: truck.totalUnits,
      totalPallets: truck.totalPallets
    }));

    return [...acc, ...rowItems];
  }, []);

  exportDataToXlsx(fileName, [
    {
      tabName: 'Truckloads',
      itemsToExport: flatOrders.toReversed().map((item) => ({
        Truck: item.truck,
        'Origin ID': item.plant,
        'SAP #': item.orderNumber,
        'Customer PO#': item.customerPos,
        'Ship-to-Loc': item.shipToName,
        'Ship-to-City': item.shipToCity,
        'Original RDD': dateFormat(item.suggestedRdd),
        'Total Weight': item.totalWeights,
        Units: item.totalUnits,
        Pallets: Math.floor(parseInt(item.totalPallets)),
        'Pickup Requests': '',
        'Updated RDD': ''
      })),
      headers: []
    }
  ]);
};
