import React from 'react';
import s from './PurchaseOrderItemsTableWHD.module.scss';
import errorIcon from '../../../../assets/icons/common/error_icon.svg';
import { PurchaseOrderItem } from 'pages/OrderDetailsPage/types';
import { POI_WARNING_MAP } from 'common/constants';
import { AlloyTable, ColumnsType } from 'components/ui/AlloyTable/AlloyTable';
import { AlloyTooltip } from 'components/ui/AlloyTooltip/AlloyTooltip';

interface PurchaseOrderItemsTableWHDProps {
  loading: boolean;
  validItems: PurchaseOrderItem[];
}

const { Summary } = AlloyTable;

export const PurchaseOrderItemsTableWHD = ({
  loading,
  validItems
}: PurchaseOrderItemsTableWHDProps) => {
  const columns: ColumnsType<PurchaseOrderItem> = [
    {
      dataIndex: 'productWarning',
      width: '44px',
      render: (_, item) => (
        <>
          {!!item.purchaseOrderItemWarnings.length && (
            <div data-testid="warning-display">
              <AlloyTooltip
                data-testid="warning-display-tooltip"
                placement="right"
                title={item.purchaseOrderItemWarnings
                  .map((warning) => POI_WARNING_MAP[warning] || warning)
                  .join(', ')}
              >
                <img data-testid="warning-icon" src={errorIcon} alt="" />
              </AlloyTooltip>
            </div>
          )}
        </>
      )
    },
    {
      title: 'UPC',
      key: 'upc',
      render: (_, item) => item.catalogProduct?.gtin12 ?? item.product?.upc,
      width: '160px'
    },
    {
      title: 'External ID',
      dataIndex: 'externalId',
      width: '124px'
    },
    {
      title: 'Item Description',
      key: 'name',
      render: (_, item) => item.catalogProduct?.name ?? item.product?.name
      //TODO: create a min-width here?
    },
    {
      title: 'Order Qty',
      dataIndex: 'quantityOrdered',
      width: '103px'
    },
    {
      width: '46px',
      render: () => <div>{''}</div>
    }
  ];

  return (
    <>
      <div data-testid="po-items-count-whd" className={s.quantity_display}>
        PO ITEMS ({validItems.length})
      </div>
      <AlloyTable
        data-testid="po-items-table-whd"
        className={s.table}
        loading={loading}
        dataSource={validItems}
        columns={columns}
        rowKey={(item) => item.id}
        sticky
        tableLayout="auto"
        pagination={false}
        summary={() => {
          return (
            <Summary.Row>
              <Summary.Cell className={s.bold} index={0} colSpan={4} align="right">
                <b>Total</b>
              </Summary.Cell>
              <Summary.Cell className={s.bold} index={1} colSpan={5} align="left">
                {validItems.reduce((total, item) => (total += item.quantityOrdered || 0), 0)}
              </Summary.Cell>
            </Summary.Row>
          );
        }}
      />
    </>
  );
};
